import httpService from "./httpService";

export const DashboardService = {
  getDashboardData: ({
    userId,
    startDate,
    endDate,
    todayStartDate,
    todayEndDate,
  }) =>
    new Promise(async (resolve, reject) => {
      console.log(startDate, userId, endDate);
      try {
        const response = await httpService.post(
          userId
            ? `vendorDashboard?vendorId=${userId}&monthStartDate=${startDate}&monthEndDate=${endDate}`
            : `dashBoardDetail?monthStartDate=${startDate}&monthEndDate=${endDate}&todayStartDate=${todayStartDate}&todayEndDate=${todayEndDate}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getVendorDetails: ({ userId, startDate, endDate }) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post(
          `vendorOrdersBetweenTwoDates?vendorId=${userId}&startDate=${startDate}&endDate=${endDate}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getMachineDetails: ({ vendorId, machineIMEI, startDate, endDate }) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post(
          `machineCollection?machineImei=${machineIMEI}&vendorId=${vendorId}&monthStartDate=${startDate}&monthEndDate=${endDate}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
};
