import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar, { SidebarItem } from "./Sidebar";
import "./CommonDashboard.css";
import { CircularProgress, Grid, Paper } from "@mui/material";
import { useToggleContext } from "../../context/ToggleContext";
import { Icon } from "@iconify/react";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  Title,
  LinearScale,
} from "chart.js";
import { useDashboardContext } from "../../context/DashboardContext";
import {
  customStylesSelect,
  filterMonthsOptions,
  firstDayOfMonth,
  formatDate,
  getMonthStartAndEndDates,
  lastDayOfMonth,
  role,
  userDetails,
} from "../../utils/utils";
import DatePickerCustom from "./DatePicker";
import { useMachineContext } from "../../context/MachineContext";
import { DashboardOutlined } from "@material-ui/icons";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const CommonDashboard = () => {
  const { show } = useToggleContext();
  const userRole = JSON.parse(localStorage.getItem(role));
  const userDataDetails = JSON.parse(localStorage.getItem(userDetails));

  const currentMonth = new Date().toLocaleString("default", { month: "long" });

  // State to hold the selected month
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // Find the index of the current month in the options array
  const defaultMonthIndex = filterMonthsOptions.findIndex(
    (option) => option.label === currentMonth
  );

  // Disable options after the current month
  for (let i = defaultMonthIndex + 1; i < filterMonthsOptions.length; i++) {
    filterMonthsOptions[i].isDisabled = true;
  }

  const {
    dashboardData,
    machineCount,
    getDashboardData,
    loading,
    payment,
    setStartDate,
    startDate,
    endDate,
    setEndDate,
    todayFailedPay,
    dailyRefundedPayments,
    monthlyFailedPayments,
    monthlyRefundedPayments,
  } = useDashboardContext();
  // machine context here
  const { orderBetweenDates, orderDetails, ordersLoading } =
    useMachineContext();

  const navigate = useNavigate();

  // useEffect(() => {
  //   const today = new Date();
  //   const sevenDaysAgo = new Date(today);
  //   sevenDaysAgo.setDate(sevenDaysAgo.getMonth() - 0);
  //   setStartDate(sevenDaysAgo);
  //   setEndDate(today);
  //   console.log("first", startDate, endDate);
  //   userRole === "vendor"
  //     ? getDashboardData(userDataDetails.id, startDate, endDate)
  //     : getDashboardData(null, startDate, endDate);
  // }, []);
  useEffect(() => {
    // Set start and end dates
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);

    // Use the updated state values when calling getDashboardData
    const start = firstDayOfMonth;
    const end = lastDayOfMonth;
    if (userRole === "vendor") {
      getDashboardData(userDataDetails.id, start, end);
    } else {
      getDashboardData(null, start, end);
    }
  }, [setStartDate, setEndDate, userDataDetails.id, userRole]);

  // calling order between dates api on page load:
  useEffect(() => {
    userRole != "vendor"
      ? orderBetweenDates(null, null, firstDayOfMonth, lastDayOfMonth)
      : orderBetweenDates(
          userDetails?.id,
          null,
          firstDayOfMonth,
          lastDayOfMonth
        );
  }, []);

  // monthly bar chart functionality here:

  // Prepare total amount for each date and each status
  const totalsByDateAndStatus = {};
  orderDetails?.forEach((order) => {
    const date = order.date;
    totalsByDateAndStatus[date] = totalsByDateAndStatus[date] || {
      initiate: 0,
      failed: 0,
      captured: 0,
      refund: 0,
    };
    order?.data?.forEach((payment) => {
      totalsByDateAndStatus[date][payment.status] += payment.totalAmount / 100; // Total amount for each status on each date
    });
  });

  // Extract unique dates
  const uniqueDates = Object.keys(totalsByDateAndStatus);

  // Prepare labels for the chart (dates)
  // const labels = uniqueDates;
  // Prepare labels for the chart (day part of dates)
  const labels = uniqueDates.map((date) => {
    return date.split("-")[0]; // Extract the day part from the date string
  });

  // Prepare datasets for the chart (total amount for each date and each status)
  const datasets = ["captured", "failed", "initiate", "refund"].map(
    (status, index) => ({
      label: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize first letter
      backgroundColor: [
        "#00c04b", // Green
        "#FF474C", // Red
        "#FFE338", // Yellow
        "rgba(54, 162, 235, 0.5)", // Blue
      ][index],
      data: uniqueDates.map((date) => totalsByDateAndStatus[date][status] || 0), // Amount for each status on each date
    })
  );

  // Bar graph data for total amount
  const chartDataStatus = {
    labels, // Dates on x-axis
    datasets, // Total amount on y-axis
  };

  // today's bar chart functionality here:
  // Extract today's payment data
  const todayPaymentData = dashboardData?.todayPayment || [];

  // Initialize an object to keep track of unique dates
  const uniqueDate = {};

  // Initialize variables to store data for the chart
  const datasetsToday = [
    {
      label: "Initiate",
      backgroundColor: "#FFE338", // Yellow
      data: [],
    },
    {
      label: "Failed",
      backgroundColor: "#FF474C", // Red
      data: [],
    },
    {
      label: "Captured",
      backgroundColor: "#00c04b", // Green
      data: [],
    },
    {
      label: "Refund",
      backgroundColor: "rgba(54, 162, 235, 0.5)", // Blue
      data: [],
    },
  ];

  // Populate today's data for the chart
  todayPaymentData.forEach((payment) => {
    // Add the date to the uniqueDates object
    uniqueDate[payment.date] = true;

    // Find the index of the dataset corresponding to the payment status
    const datasetIndex = datasetsToday.findIndex(
      (dataset) => dataset.label.toLowerCase() === payment.status.toLowerCase()
    );

    // If the dataset for the status is found, push the total amount to its data array
    if (datasetIndex !== -1) {
      datasetsToday[datasetIndex].data.push(payment.totalAmount / 100);
    }
  });

  // Extract unique dates from the uniqueDate object
  const labelsToday = Object.keys(uniqueDate).map((date) => date.split("-")[2]);

  // Bar graph data for today's payment
  const chartDataTodayPayment = {
    labels: labelsToday, // Dates on x-axis
    datasets: datasetsToday, // Total amount on y-axis
  };

  // reset button functionality:
  const handleReset = () => {
    // Set start and end dates
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);
    // Pass the updated start and end dates directly to getDashboardData
    const start = firstDayOfMonth;
    const end = lastDayOfMonth;
    if (userRole === "vendor") {
      getDashboardData(userDataDetails.id, start, end);
      orderBetweenDates(
        userDataDetails.id,
        null,
        firstDayOfMonth,
        lastDayOfMonth
      );
    } else {
      getDashboardData(null, start, end);
      orderBetweenDates(null, null, firstDayOfMonth, lastDayOfMonth);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-between w-full">
        <Sidebar>
          <SidebarItem
            icon={<LayoutDashboard size={20} />}
            text="Dashboard"
            active
          />
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole === "viewer" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          <SidebarItem
            icon={<Armchair size={20} />}
            text="Machines"
            id={userDataDetails?.id}
          />
          {userRole === "admin" && (
            <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
          )}
          {userRole === "viewer" && (
            <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
          )}
          {userRole === "vendor" && (
            <SidebarItem
              icon={<IndianRupee size={20} />}
              text="Orders"
              id={userDataDetails?.id}
            />
          )}
        </Sidebar>
        <div
          className={`transition-all h-[calc(100vh-5px)] overflow-x-hidden overflow-y-auto duration-300 ease-in-out pt-[120px] bg-[#f2f2f2b5] px-10 !ml-0 w-full ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          {/* <Grid container marginBottom={3}>
            <div className="text-xl lg:text-3xl flex gap-2 font-medium !capitalize">
              Hi, Welcome{" "}
              <h4 className="text-[#8E5EF9] font-bold">
                {userDataDetails?.name}
              </h4>
            </div>
          </Grid> */}
          {/* <Grid container marginBottom={1}>
            <div className="flex items-start lg:!items-center flex-col lg:flex-row gap-4">
              Start Date
              <div className="date_filter flex-col flex items-start gap-1">
                <label
                  htmlFor="startDate"
                  className="!text-[#444] !text-base font-medium"
                >
                  Start Date <span className="text-red-500">*</span>
                </label>
                <DatePickerCustom
                  selectedDate={startDate}
                  setSelectedDate={setStartDate}
                  isStartDate={true}
                />
              </div>
              End Date
              <div className="date_filter flex-col flex items-start gap-1">
                <label
                  htmlFor="endDate"
                  className="!text-[#444] !text-base font-medium"
                >
                  End Date <span className="text-red-500">*</span>
                </label>
                <DatePickerCustom
                  selectedDate={endDate}
                  setSelectedDate={setEndDate}
                  isStartDate={false}
                />
              </div>
              <div className="apply_reset_div mt-0 lg:!mt-[28px] flex gap-4 items-center">
                <button
                  type="submit"
                  onClick={() => {
                    // calling the api on the basis of dates
                    userRole === "vendor"
                      ? getDashboardData(userDataDetails.id, startDate, endDate)
                      : getDashboardData(null, startDate, endDate) &&
                        orderBetweenDates(startDate, endDate);
                  }}
                  tabIndex="0"
                  disabled={loading || !startDate || !endDate}
                  className={`flex loginButton gap-2 !select-none justify-center items-center border border-solid border-green-500 transition-all duration-300 ease-in-out rounded-md text-base text-white font-bold h-[45px] min-w-[140px] ${
                    loading || !startDate || !endDate
                      ? "bg-green-400 border-green-400"
                      : "bg-green-500 rounded-[4px] border border-green-500 shadow-sm hover:!bg-green-400 hover:!border-green-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                  }`}
                >
                  Apply
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    handleReset();
                  }}
                  tabIndex="0"
                  disabled={!startDate || !endDate || loading}
                  className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-red-500 transition-all duration-300 ease-in-out rounded-md text-base text-white font-bold h-[45px] min-w-[140px] ${
                    !startDate || !endDate || loading
                      ? "bg-red-400 border-red-400"
                      : "bg-red-500 rounded-[4px] border border-red-500 shadow-sm hover:!bg-red-400 hover:!border-red-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                  }`}
                >
                  Reset
                </button>
              </div>
            </div>
          </Grid> */}

          {/* filter by selecting month */}
          <Grid>
            <div className="flex gap-2 items-start flex-col">
              <div className="text-[#444] font-semibold">
                Select Month <span className="text-red-500">*</span>
              </div>
              <div className="flex gap-2 w-full items-center">
                <div className="!w-[200px]">
                  <Select
                    styles={customStylesSelect}
                    className="customSelect"
                    placeholder="Select month"
                    value={selectedMonth}
                    options={filterMonthsOptions}
                    defaultValue={filterMonthsOptions[defaultMonthIndex]}
                    onChange={(selectedOption) => {
                      setSelectedMonth(selectedOption); // Update selected month state
                      const { start, end } = getMonthStartAndEndDates(
                        selectedOption.value,
                        2024
                      );
                      if (userRole === "vendor") {
                        setStartDate(start);
                        setEndDate(end);
                        getDashboardData(userDataDetails.id, start, end);
                        orderBetweenDates(userDetails?.id, null, start, end);
                      } else {
                        setStartDate(start);
                        setEndDate(end);
                        getDashboardData(null, start, end);
                        orderBetweenDates(null, null, start, end);
                      }
                    }}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                </div>
                {/* <button
                  type="submit"
                  onClick={() => {
                    handleReset();
                  }}
                  tabIndex="0"
                  disabled={!startDate || !endDate || loading}
                  className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-red-500 transition-all duration-300 ease-in-out rounded-md text-base text-white font-bold h-[45px] max-w-[140px] min-w-[140px] ${
                    !startDate || !endDate || loading
                      ? "bg-red-400 border-red-400"
                      : "bg-red-500 rounded-[4px] border border-red-500 shadow-sm hover:!bg-red-400 hover:!border-red-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                  }`}
                >
                  Reset
                </button> */}
              </div>
            </div>
          </Grid>

          {/* bar graph */}
          <div className="grid grid-cols-1 mt-7">
            {/* Monthly data */}
            <div className="montlyDataBarChart flex flex-col items-start gap-2 !w-full">
              <div className="text-2xl mb-2 font-semibold text-[#444]">
                Monthly Order Payments
              </div>
              <div className="!w-full rounded-md !bg-white px-4 py-4">
                {ordersLoading ? (
                  <div className="h-[350px] flex justify-center items-center">
                    <CircularProgress size={28} className="!text-[#000000]" />
                  </div>
                ) : orderDetails?.length > 0 ? (
                  <Bar
                    className="h-auto w-[400px] overflow-auto lg:!h-[350px]"
                    data={chartDataStatus}
                    options={{
                      indexAxis: "x", // Keep x-axis for controlling bar thickness
                      elements: {
                        bar: {
                          borderWidth: 2, // Adjust border width if needed
                          borderRadius: 0, // Make bars rounded (adjust radius as needed)
                        },
                      },
                      datasets: {
                        bar: {
                          barThickness: 14,
                          borderRadius: 0,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                          },
                          title: {
                            text: "Date",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          stacked: true,
                          title: {
                            text: "Amount (₹)",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="h-[350px] flex justify-center items-center">
                    No order found
                  </div>
                )}
              </div>
            </div>
            {/* Today's data */}
            {/* <div className="montlyDataBarChart flex flex-col items-start gap-2 !w-full">
              <div className="text-2xl mb-2 font-semibold text-[#444]">
                Today's Order Payments
              </div>
              <div className="!w-full rounded-md !bg-white px-4 py-4">
                {ordersLoading ? ( // Check if loading state is true
                  <div className="h-[350px] flex justify-center items-center">
                    <CircularProgress size={28} className="!text-[#000000]" />
                  </div>
                ) : // Check if todayPayment data is available and not empty
                dashboardData?.todayPayment?.length > 0 &&
                  dashboardData?.todayPayment != [] ? (
                  <Bar
                    className="!h-[350px]"
                    data={chartDataTodayPayment}
                    options={{
                      indexAxis: "x", // Keep x-axis for controlling bar thickness
                      elements: {
                        bar: {
                          borderWidth: 2, // Adjust border width if needed
                          borderRadius: 0, // Make bars rounded (adjust radius as needed)
                        },
                      },
                      datasets: {
                        bar: {
                          barThickness: 7,
                          borderRadius: 0,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                          },
                          title: {
                            text: "Date",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          stacked: true,
                          title: {
                            text: "Amount (₹)",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  // If todayPayment data is not available or empty
                  <div className="h-[350px] flex justify-center items-center">
                    No order found
                  </div>
                )}
              </div>
            </div> */}
          </div>

          {/* card for orders and payments */}
          <div className="grid grid-cols-1 md:grid-cols-2 cardsMain lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-6 1xl:gap-y-[33px] items-center !my-16">
            {/* today's orders */}
            <div
              className="singleCard p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#2471A3] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(`/orders-by-collection?day=todayOrders`);
              }}
            >
              {/* icon */}
              <div className="icon bg-[#2471A3] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="fluent-mdl2:activate-orders"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Today's Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : dashboardData?.todayOrders ? (
                    dashboardData?.todayOrders
                  ) : dashboardData?.order ? (
                    dashboardData?.order
                  ) : (
                    0
                  )}{" "}
                </div>
              </div>
            </div>

            {/* today's payment */}
            <div
              className="singleCard p-4 mt-4 lg:!mt-0 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#D35400] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(
                  `/orders-by-collection?day=todayOrders&todayPaymentStatus=captured`
                );
              }}
            >
              {/* icon */}
              <div className="icon bg-[#D35400] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Today's Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : payment.todayPayment ? (
                    payment.todayPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* today's refunded */}
            <div
              className="singleCard p-4 mt-4 lg:!mt-0 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#F28C28] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(
                  `/orders-by-collection?day=todayOrders&todayPaymentStatus=refund`
                );
              }}
            >
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Today's Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : (
                    dailyRefundedPayments && dailyRefundedPayments
                  )}
                </div>
              </div>
            </div>

            {/* total orders */}
            <div
              className="singleCard mt-6 p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#8E44AD] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(
                  `/orders-by-collection?day=monthlyOrders&startDate=${formatDate(
                    startDate
                  )}&endDate=${formatDate(endDate)}`
                );
              }}
            >
              {/* icon */}
              <div className="icon bg-[#8E44AD] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="fluent-mdl2:activate-orders"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Total Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : (
                    dashboardData != [] &&
                    dashboardData?.monthlyPaymentAndOrderCount?.totalOrder
                  )}
                </div>
              </div>
            </div>

            {/* total collection */}
            <div
              className="singleCard p-4 mt-6 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#D68910] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(
                  `/orders-by-collection?day=monthlyOrders&startDate=${formatDate(
                    startDate
                  )}&endDate=${formatDate(
                    endDate
                  )}&monthlyPaymentStatus=captured`
                );
              }}
            >
              {/* icon */}
              <div className="icon bg-[#D68910] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Total Collection
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : (
                    (dashboardData &&
                      dashboardData.monthlyPaymentAndOrderCount &&
                      dashboardData.monthlyPaymentAndOrderCount.result &&
                      dashboardData.monthlyPaymentAndOrderCount.result.find(
                        (payment) => payment.status === "captured"
                      )?.totalAmount / 100) ||
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total refunded */}
            <div
              className="singleCard mt-6 p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#F28C28] transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                navigate(
                  `/orders-by-collection?day=monthlyOrders&startDate=${formatDate(
                    startDate
                  )}&endDate=${formatDate(endDate)}&monthlyPaymentStatus=refund`
                );
              }}
            >
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                  Total Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : (
                    monthlyRefundedPayments && monthlyRefundedPayments
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* cards for machine */}
          {userRole == "admin" && (
            <div className="grid grid-cols-1 pb-7 -mt-4 1xl:mt-0 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-6 1xl:gap-y-[33px] items-center">
              {/* total machines */}
              <div
                className="singleCard p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#F1C40F] transition-all duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  navigate(`/orders-by-collection?&machineStatus=totalMachine`);
                }}
              >
                {/* icon */}
                <div className="icon bg-[#F1C40F] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                  <Icon
                    icon="icon-park-outline:massage-chair-one"
                    className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                  />
                </div>
                {/* content area */}
                <div className="flex justify-end items-end flex-col gap-1">
                  {/* title */}
                  <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                    Total Machines
                  </div>
                  <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                    {loading ? (
                      <CircularProgress size={28} className="!text-black" />
                    ) : (
                      machineCount.totalMachine
                    )}{" "}
                  </div>
                </div>
              </div>

              {/* total enabled machines */}
              <div
                className="singleCard mt-4 lg:!mt-0 p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#229954] transition-all duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  navigate(`/orders-by-collection?&machineStatus=true`);
                }}
              >
                {/* icon */}
                <div className="icon bg-[#229954] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                  <Icon
                    icon="clarity:success-line"
                    className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                  />
                </div>
                {/* content area */}
                <div className="flex justify-end items-end flex-col gap-1">
                  {/* title */}
                  <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                    Total Enabled Machines
                  </div>
                  <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                    {loading ? (
                      <CircularProgress size={28} className="!text-black" />
                    ) : (
                      machineCount.enabledMachine
                    )}{" "}
                  </div>
                </div>
              </div>
              {/* total machines */}
              <div
                className="singleCard p-4 mt-4 lg:!mt-0 relative bg-white rounded-md border border-gray-300 h-auto 1xl:h-[132px] shadow-sm hover:!shadow-lg hover:!bg-[#C0392B] transition-all duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  navigate(`/orders-by-collection?&machineStatus=false`);
                }}
              >
                {/* icon */}
                <div className="icon bg-[#C0392B] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                  <Icon
                    icon="raphael:cross"
                    className="text-[28px] 1xl:!text-[40px] text-[#fff] totalMachine"
                  />
                </div>
                {/* content area */}
                <div className="flex justify-end items-end flex-col gap-1">
                  {/* title */}
                  <div className="title text-[rgb(123,128,154)] text-xs 1xl:text-base">
                    Total Disabled Machines
                  </div>
                  <div className="title text-[rgb(52,71,103)] font-bold text-lg 1xl:text-3xl">
                    {loading ? (
                      <CircularProgress size={28} className="!text-black" />
                    ) : (
                      machineCount.disabledMachine
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* bar chart */}
          {/* <Grid container marginBottom={3} spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <h4 className="text-[24px] leading-[36px] font-medium text-[#242526] my-4">
                Collection{" "}
                <span className="text-lg">
                  (
                  {`${startDate && formatDate(startDate, "yyyy-mm-dd")} to ${
                    endDate && formatDate(endDate, "yyyy-mm-dd")
                  }`}
                  )
                </span>
              </h4>
              <Paper className="flex justify-center items-center shadow-lg !bg-transparent">
                {loading ? (
                  <div className="flex gap-4 justify-center items-center h-[600px]">
                    <h4 className="text-lg font-medium">Loading</h4>
                    <CircularProgress className="!text-[#8e5ef9]" size={30} />
                  </div>
                ) : doughnutChartCustomMonthly ? (
                  doughnutChartCustomMonthly
                ) : (
                  <div className="h-[400px] flex items-center justify-center w-full text-lg font-medium">
                    No payment found
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <h4 className="text-[24px] leading-[36px] font-medium text-[#242526] my-4">
                Today's Payments
              </h4>
              <Paper className="flex justify-center items-center shadow-lg !bg-transparent">
                {loading ? (
                  <div className="flex gap-4 justify-center items-center h-[600px]">
                    <h4 className="text-lg font-medium">Loading</h4>
                    <CircularProgress className="!text-[#8e5ef9]" size={30} />
                  </div>
                ) : count.initiate > 0 || count.captured > 0 || count.failed ? (
                  doughnutChartCustomToday
                ) : (
                  <div className="h-[400px] flex items-center justify-center w-full text-lg font-medium">
                    No payment found
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid> */}
          {/* <div className="w-[400px]">
            <Bar
              className="!w-full !h-full"
              data={data}
              options={{
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                },
              }}
            />{" "}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CommonDashboard;
