import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import { useMachineContext } from "../../../context/MachineContext";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { Link, useParams, useNavigate } from "react-router-dom";
import Modal from "../../shared/Modal";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import {
  allotMachineSchema,
  updateMachineSchema,
} from "../../../schema/Schema";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import { useToggleContext } from "../../../context/ToggleContext";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { initialValuesAllotMachine } from "../../../utils/formikInitialValues";
import {
  UTCtoIST,
  customStyles,
  customStylesSelect,
  customWidth,
  imeiMachine,
  machineLocation,
  role,
  userDetails,
  vendorName,
} from "../../../utils/utils";
import Select from "react-select";
const MachineList = () => {
  const {
    getMachineList,
    machines,
    loading,
    setLoading,
    pageDetails,
    setUpdateMachineModal,
    setMachineId,
    availableMachinesAll,
    setAllotMachineModal,
    allotMachineModal,
    availableMachinesList,
    allotMachine,
    reAllotMachine,
    reAllotMachineModal,
    setReAllotMachineModal,
    allotMachineLoading,
    updateMachineDetails,
    updateMachineModal,
  } = useMachineContext();
  const { show } = useToggleContext();
  const navigate = useNavigate();
  // getting the stored limit from the localStorage:
  const storedLimit = localStorage.getItem("limit");
  // use states defined here
  const [limit, setLimit] = useState(storedLimit || 40); // if storedlimit then it will set otherwise default 10
  const [page, setPage] = useState(1);
  const [existingData, setExistingData] = useState([]);
  const initialValues = {
    location: existingData?.location || "",
    rateOfMachine: existingData?.rateOfMachine / 100 || "",
    machineImei: existingData?.machineImei || "",
    // commission: existingData?.commission || "",
    runDuration: existingData?.runDuration ? existingData?.runDuration : "-",
    status: existingData?.status,
  };
  // getting userId from the params:
  const { id: userId } = useParams();
  // getting userId from localStorage:
  const userDetail = JSON.parse(localStorage.getItem(userDetails));
  // getting the user type from localstorage :
  const userRole = JSON.parse(localStorage.getItem(role));

  useEffect(() => {
    localStorage.setItem("limit", 40);
  }, []);
  // calling the machine list api:
  useEffect(() => {
    setLoading(true);
    userRole == "admin"
      ? getMachineList(userId, limit, page).finally(() => setLoading(false))
      : getMachineList(userId, limit, page).finally(() => setLoading(false));
  }, [limit, page]);
  useEffect(() => {
    userRole == "admin" && availableMachinesAll();
  }, []);
  // all admins list mapping through api :
  const machinesList = machines && machines?.map((item) => item);
  const columns = [
    {
      name: "Location",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-base">{row?.location}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.location
              ? row?.location.length > 10
                ? row?.location.substring(0, 10) + "..."
                : row?.location
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Charge",
      selector: (row, index) => (
        <p
          className="text-xs 2xl:text-base"
          onClick={() => {
            navigate(`/machine-details/${row.machineImei}`);
          }}
        >
          {"₹ " + row?.rateOfMachine / 100}
        </p>
      ),
    },
    {
      name: "IMEI",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.machineImei}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.machineImei
              ? row?.machineImei.length > 12
                ? row?.machineImei.substring(0, 12) + "..."
                : row?.machineImei
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Update Machine",
      selector: (row, index) => (
        <div
          className="bg-[#8E5EF9] text-white hover:bg-[#8E5EF9] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdateMachineModal(true);
            setExistingData(row);
            setMachineId(row?._id);
          }}
        >
          <Tooltip
            placement="left"
            title={<span className="text-base">Edit</span>}
          >
            <Icon icon="mdi:pencil" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "View Orders",
      selector: (row, index) => (
        <Link
          to={`/${row?.machineImei}/orders`}
          onClick={() => {
            localStorage.setItem(machineLocation, row?.location);
            localStorage.setItem(imeiMachine, JSON.stringify(row?.machineImei));
            // localStorage.removeItem("vendor_name");
          }}
          className="bg-[#4992FF] text-white hover:bg-[#4992FF] transition-all duration-300 ease-in-out flex justify-center items-center text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
        >
          <Tooltip
            placement="bottom"
            title={
              <span className="text-base">
                Click here to see {vendor_name}'s all orders for this machine(
                {row?.machineImei})
              </span>
            }
          >
            <Icon icon="mdi:eye" fontSize={18} />
          </Tooltip>
        </Link>
      ),
    },
    {
      name: "De Allot",
      width: customWidth,
      selector: (row, index) => (
        <div
          onClick={() => {
            setMachineId(row?._id);
            setReAllotMachineModal(true);
          }}
          className="bg-red-500 text-white cursor-pointer hover:bg-red-400 transition-all duration-300 ease-in-out flex justify-center items-center text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
        >
          <Tooltip
            placement="bottom"
            title={
              <span className="text-base">
                Click here to remove this machine from {vendor_name}
              </span>
            }
          >
            <Icon icon="mdi:trash" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "Last Active",
      selector: (row) => (
        <Tooltip
          title={
            <p className="text-base">{UTCtoIST(row?.machineActiveStatus)}</p>
          }
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.machineActiveStatus
              ? UTCtoIST(row?.machineActiveStatus).length > 10
                ? UTCtoIST(row?.machineActiveStatus).substring(0, 10) + "..."
                : UTCtoIST(row?.machineActiveStatus)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
  ];

  const filteredColumns =
    userRole === "admin"
      ? columns // Include all columns for admin
      : columns.filter(
          (column) =>
            column.name != "Update Machine" && column.name != "De Allot"
        ); // Exclude 'Update Machine' and 'De Allot' for non-admin

  const vendor_name = localStorage.getItem(vendorName);

  const availableMachinesOptions = availableMachinesList
    .filter((item) => item?.machineImei && item?.machineImei != "undefined")
    .map((item) => ({
      label: `${item?.location} (${item?.machineImei}) - ${item?.status}`,
      value: item?._id,
    }));

  return (
    <>
      <Navbar />
      <div className="flex w-full">
        <Sidebar>
          {
            <SidebarItem
              icon={<LayoutDashboard size={20} />}
              text="Dashboard"
            />
          }{" "}
          {userRole == "viewer" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole == "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          {userRole === "vendor" && (
            <SidebarItem
              icon={<Armchair size={20} />}
              id={userId}
              text="Machines"
              active
            />
          )}
          {userRole === "admin" && (
            <SidebarItem
              icon={<Armchair size={20} />}
              id={userId}
              text="Machines"
            />
          )}
          {userRole === "viewer" && (
            <SidebarItem
              icon={<Armchair size={20} />}
              id={userId}
              text="Machines"
            />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
          )}
          {userRole === "viewer" && (
            <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
          )}
          {userRole === "vendor" && (
            <SidebarItem
              icon={<IndianRupee size={20} />}
              text="Orders"
              id={userDetail?.id}
            />
          )}
        </Sidebar>
        <div
          className={`dashboard-main w-full admin-dashboard-main h-[calc(100vh-15px)] overflow-auto transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            <h4 className="text-center text-2xl font-bold">
              {userRole === "admin" && `${vendor_name}'s `}All Machines
            </h4>
            <div className="top-section mt-4 flex flex-col lg:!flex-row w-full gap-2 lg:items-center">
              <div className="!text-[#242426] !text-[30px] flex-col leading-10 font-[600] flex items-start gap-1">
                <h4 className="flex items-center gap-1 !m-0 text-xl">
                  Machine
                </h4>
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <div
                        onClick={() => {
                          userRole === "admin"
                            ? navigate("/vendor-list")
                            : navigate("/dashboard");
                        }}
                        className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400"
                      >
                        Vendor
                      </div>
                    </li>
                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <span className="ms-1 text-sm capitalize font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                          {userRole === "admin"
                            ? vendor_name
                            : userDetail?.name}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              {userRole === "admin" && (
                <div
                  className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-max px-7 text-[#fff] py-[17px] cursor-pointer"
                  onClick={() => {
                    setAllotMachineModal(true);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={
                      <span className="text-base">
                        Click here to allot new machine to {vendor_name}
                      </span>
                    }
                  >
                    <p className="text-sm 2xl:text-base">Allot Machine</p>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="admin-lists orderListing">
              <DataTable
                className="events-lists-table"
                data={machinesList}
                columns={filteredColumns}
                striped={true}
                fixedHeader
                pagination
                paginationServer
                responsive
                highlightOnHover
                onRowClicked={(row) => {
                  navigate(`/machine-details/${row.machineImei}`);
                }}
                noDataComponent={<h4>No Machine found</h4>}
                paginationRowsPerPageOptions={[10, 20, 40, 80, 100]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                onChangeRowsPerPage={(perPage) => {
                  localStorage.setItem("limit", perPage);
                  setLimit(perPage);
                }}
                customStyles={customStyles}
                onChangePage={(page) => {
                  setPage(page);
                }}
                progressPending={loading}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "#45267e",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* allot machine modal */}
      {allotMachineModal && (
        <Modal
          title="Allot Machine"
          allotMachineModal={allotMachineModal}
          closeIcon={() => setAllotMachineModal(false)}
          descriptionText={
            <div className="w-full">
              <Formik
                initialValues={initialValuesAllotMachine}
                validationSchema={allotMachineSchema}
                onSubmit={(values) => {
                  allotMachine(userId, values?.allotMachine?.value);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik?.handleSubmit} className="my-4">
                    <div className="flex flex-col items-start gap-2 w-full">
                      <label htmlFor="allotMachine">Available Machine</label>
                      <Select
                        className="!w-full text-left"
                        placeholder="Select Machine"
                        options={availableMachinesOptions}
                        name="allotMachine"
                        value={formik?.values?.allotMachine}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("allotMachine", selectedOption);
                        }}
                        styles={customStylesSelect}
                      />
                      {formik?.errors?.allotMachine &&
                        formik?.touched?.allotMachine && (
                          <p className="text-[#d50000]">
                            {formik?.errors?.allotMachine}
                          </p>
                        )}
                    </div>
                    <button
                      type="submit"
                      tabIndex="0"
                      disabled={allotMachineLoading}
                      className={`flex mt-3 justify-center items-center border border-solid border-[#45267e] transition-all duration-300 ease-in-out rounded-md text-sm 2xl:text-base text-white h-[40px] 2xl:h-[50px] w-full ${
                        allotMachineLoading
                          ? "!bg-[rgba(142,94,249,0.7)]"
                          : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[17px]"
                      }`}
                    >
                      {!allotMachineLoading ? (
                        "Allot Machine"
                      ) : (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={30}
                        />
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          }
        />
      )}
      {/* de allot machine modal */}
      {reAllotMachineModal && (
        <Modal
          reAllotMachineModal={reAllotMachineModal}
          title="Are you sure, you want to de-allot machine?"
          closeIcon={() => setReAllotMachineModal(false)}
          button={
            <div className="flex my-10 !mb-0 items-center gap-[10px]">
              <button
                type="button"
                onClick={() => setReAllotMachineModal(false)}
                className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[17px]"
              >
                No
              </button>
              <button
                // type="submit"
                onClick={() => {
                  reAllotMachine();
                }}
                disabled={allotMachineLoading}
                className={`${
                  allotMachineLoading
                    ? "bg-[rgba(142,94,249,0.7)]"
                    : "bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[17px]"
                }`}
              >
                {!allotMachineLoading ? (
                  "Yes"
                ) : (
                  <CircularProgress
                    style={{
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    size={30}
                  />
                )}
              </button>
            </div>
          }
        />
      )}
      {/* Update Machine */}
      {updateMachineModal && (
        <Modal
          updateMachineModal={updateMachineModal}
          title="Update Machine Details"
          closeIcon={() => {
            setUpdateMachineModal(false);
          }}
          descriptionText={
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={updateMachineSchema}
                onSubmit={(values) => {
                  const changedValues = {};

                  // Iterate through each form field
                  Object.keys(values).forEach((field) => {
                    const currentValue = values[field];
                    const initialValue = initialValues?.[field];

                    // Check if the current field has changed
                    if (currentValue !== initialValue) {
                      // Apply special handling for the 'commission' and 'rateOfMachine' fields
                      if (field === "commission" || field === "rateOfMachine") {
                        // Multiply 'commission' and 'rateOfMachine' by 100 before adding to changedValues
                        changedValues[field] = currentValue * 100;
                      } else {
                        // For other fields, add them directly to changedValues
                        changedValues[field] = currentValue;
                      }
                    }
                  });

                  // Call your update function with the changed values
                  updateMachineDetails(changedValues, userId, limit, page);
                }}
              >
                {(formik) => (
                  <form
                    onSubmit={formik?.handleSubmit}
                    className="mainDiv my-10 !mb-0"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                      <div className="email form-group flex items-start flex-col gap-2">
                        <label
                          htmlFor="name"
                          className="!text-[#333] !font-normal mb-0 text-sm 2xl:!text-base"
                        >
                          Location
                        </label>
                        <Field
                          name="location"
                          onBlur={formik.handleBlur}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault(); // Prevent entering space if the input only contains spaces
                            }
                          }}
                          onChange={(e) => {
                            let newValue = e.target.value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            ); // Allow alphanumeric characters and spaces
                            formik.setFieldValue("location", newValue);
                          }}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none  text-sm 2xl:!text-base placeholder:!text-sm 2xl:placeholder:!text-base !pl-2.5"
                          type="text"
                        />
                        {formik?.errors?.location &&
                        formik?.touched?.location ? (
                          <p className="text-[#d50000] text-left">
                            {formik?.errors?.location}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-2">
                        <label
                          htmlFor="price"
                          className="!text-[#333] !font-normal mb-0 text-sm 2xl:!text-base"
                        >
                          Price<span>*</span>
                        </label>
                        <Field
                          name="rateOfMachine"
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none  text-sm 2xl:!text-base placeholder:!text-sm 2xl:placeholder:!text-base !pl-2.5"
                          type="number"
                        />
                        {formik?.errors?.rateOfMachine &&
                        formik?.touched?.rateOfMachine ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.rateOfMachine}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-2">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal mb-0 text-sm 2xl:!text-base"
                        >
                          IMEI<span>*</span>
                        </label>
                        <Field
                          name="machineImei"
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none  text-sm 2xl:!text-base placeholder:!text-sm 2xl:placeholder:!text-base !pl-2.5"
                          type="text"
                        />
                        {formik?.errors?.machineImei &&
                        formik?.touched?.machineImei ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.machineImei}
                          </p>
                        ) : null}
                      </div>
                      {/* <div className="email form-group flex items-start flex-col gap-2">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal mb-0 text-sm 2xl:!text-base"
                        >
                          Commission<span>*</span>
                        </label>
                        <Field
                          name="commission"
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none text-sm 2xl:!text-base placeholder:!text-sm 2xl:placeholder:!text-base !pl-2.5"
                          type="text"
                        />
                        {formik?.errors?.commission &&
                        formik?.touched?.commission ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.commission}
                          </p>
                        ) : null}
                      </div> */}
                      <div className="email form-group flex items-start flex-col gap-1 -mt-1">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Run Duration<span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="runDuration"
                          placeholder={`(in minutes)`}
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="number"
                          onChange={(e) => {
                            const durationTime = e.target.value;

                            // Allow updating the field only if the entered value is greater than or equal to 0
                            if (durationTime > 0) {
                              formik.setFieldValue("runDuration", durationTime);
                            } else {
                              // If the value is less than 0, set the field value to an empty string
                              formik.setFieldValue("runDuration", "");
                            }
                          }}
                        />
                        {formik?.errors?.runDuration &&
                        formik?.touched?.runDuration ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.runDuration}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group">
                        <label className="!text-[#333] mb-0 text-sm 2xl:!text-base !font-normal">
                          Status<span>*</span>
                        </label>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    formik?.setFieldValue("status", true);
                                  } else {
                                    formik?.setFieldValue("status", false);
                                  }
                                }}
                                checked={formik?.values?.status === true}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="flex items-center 2xl:mt-2 gap-[10px]">
                      <button
                        type="button"
                        onClick={() => setUpdateMachineModal(false)}
                        className="bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm !text-xs 2xl:!text-base hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[10px] 2xl:py-[17px]"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm !text-xs 2xl:!text-base hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[10px] 2xl:py-[17px]"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
        />
      )}
    </>
  );
};

export default MachineList;
