import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { useToggleContext } from "../../../context/ToggleContext";
import {
  customStylesSelect,
  filterMonthsOptions,
  firstDayOfMonth,
  getMonthStartAndEndDates,
  lastDayOfMonth,
  role,
  userDetails,
  vendorName,
} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  Title,
  LinearScale,
} from "chart.js";
import Select from "react-select";
import { useVendorContext } from "../../../context/VendorDetailsContext";
import { useMachineContext } from "../../../context/MachineContext";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const VendorDetails = () => {
  // use of useNavigate hook:
  const navigate = useNavigate();
  // use of toggle context here:
  const { show } = useToggleContext();
  // getting localStorage items:
  const { id: isUserId } = useParams();
  const userRole = JSON.parse(localStorage.getItem(role));
  const userDetail = JSON.parse(localStorage.getItem(userDetails));
  const vendor_name = localStorage.getItem(vendorName);

  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  // State to hold the selected month
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // Find the index of the current month in the options array
  const defaultMonthIndex = filterMonthsOptions.findIndex(
    (option) => option.label === currentMonth
  );

  // Disable options after the current month
  for (let i = defaultMonthIndex + 1; i < filterMonthsOptions.length; i++) {
    filterMonthsOptions[i].isDisabled = true;
  }

  const {
    getVendorDetails,
    loading,
    dailyFailedPayments,
    dailyCapturedPayment,
    monthlyFailedPayments,
    monthlyCapturedPayment,
    todayOrders,
    monthlyOrders,
    setStartDate,
    startDate,
    endDate,
    setEndDate,
    dailyRefunedPayment,
    monthlyRefundedPayment,
    todayData,
  } = useVendorContext();

  // calling useMachineContext here to fetch orderBetweenDates function and other:
  const { orderBetweenDates, orderDetails, ordersLoading } =
    useMachineContext();

  useEffect(() => {
    // Set start and end dates
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);

    // Use the updated state values when calling getDashboardData
    const start = firstDayOfMonth;
    const end = lastDayOfMonth;
    console.log(isUserId, "isUserId");
    getVendorDetails(isUserId, start, end);
    orderBetweenDates(isUserId, null, firstDayOfMonth, lastDayOfMonth);
  }, [setStartDate, setEndDate, isUserId]);

  // reset button
  const handleReset = () => {
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    getVendorDetails(isUserId, startDate, endDate);
    orderBetweenDates(isUserId, null, startDate, endDate);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);
  };

  // monthly bar chart functionality here:
  // Prepare total amount for each date and each status
  const totalsByDateAndStatus = {};
  orderDetails?.forEach((order) => {
    const date = order.date;
    totalsByDateAndStatus[date] = totalsByDateAndStatus[date] || {
      initiate: 0,
      failed: 0,
      captured: 0,
      refund: 0,
    };
    order?.data?.forEach((payment) => {
      totalsByDateAndStatus[date][payment.status] += payment.totalAmount / 100; // Total amount for each status on each date
    });
  });

  // Extract unique dates
  const uniqueDates = Object.keys(totalsByDateAndStatus);

  // Prepare labels for the chart (day part of dates)
  const labels = uniqueDates.map((date) => {
    return date.split("-")[0]; // Extract the day part from the date string
  });

  // Prepare datasets for the chart (total amount for each date and each status)
  const datasets = ["captured", "failed", "initiate", "refund"].map(
    (status, index) => ({
      label: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize first letter
      backgroundColor: [
        "#00c04b", // Green
        "#FF474C", // Red
        "#FFE338", // Yellow
        "rgba(54, 162, 235, 0.5)", // Blue
      ][index],
      data: uniqueDates.map((date) => totalsByDateAndStatus[date][status] || 0), // Amount for each status on each date
    })
  );

  // Bar graph data for total amount
  const chartDataStatus = {
    labels, // Dates on x-axis
    datasets, // Total amount on y-axis
  };

  // Extract today's payment data
  const todayPaymentData = todayData || [];

  // Initialize an object to keep track of unique dates
  const uniqueDate = {};

  // Initialize variables to store data for the chart
  const datasetsToday = [
    {
      label: "Initiate",
      backgroundColor: "#FFE338", // Yellow
      data: [],
    },
    {
      label: "Failed",
      backgroundColor: "#FF474C", // Red
      data: [],
    },
    {
      label: "Captured",
      backgroundColor: "#00c04b", // Green
      data: [],
    },
    {
      label: "Refund",
      backgroundColor: "rgba(54, 162, 235, 0.5)", // Blue
      data: [],
    },
  ];

  // Populate today's data for the chart
  todayPaymentData.forEach((payment) => {
    // Add the date to the uniqueDates object
    uniqueDate[payment.date] = true;

    // Find the index of the dataset corresponding to the payment status
    const datasetIndex = datasetsToday.findIndex(
      (dataset) => dataset.label.toLowerCase() === payment.status.toLowerCase()
    );

    // If the dataset for the status is found, push the total amount to its data array
    if (datasetIndex !== -1) {
      datasetsToday[datasetIndex].data.push(payment.totalAmount / 100);
    }
  });

  // Extract unique dates from the uniqueDate object and format them to display only the day part
  const labelsToday = Object.keys(uniqueDate).map((date) => date.split("-")[2]); // Extract day part

  // Bar graph data for today's payment
  const chartDataTodayPayment = {
    labels: labelsToday, // Dates on x-axis
    datasets: datasetsToday, // Total amount on y-axis
  };

  return (
    <>
      <Navbar />
      <Sidebar>
        <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
        <SidebarItem icon={<Users size={20} />} active text="Vendors" />
        {userRole === "admin" && (
          <SidebarItem icon={<Users size={20} />} text="Viewers" />
        )}
        <SidebarItem icon={<Armchair size={20} />} text="Machines" />
        <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
      </Sidebar>
      <div
        className={`dashboard-main h-[calc(100vh-15px)] overflow-auto w-full admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
          show
            ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
            : "pl-[25px] pr-[25px] lg:!pl-[130px]"
        }`}
      >
        <div className="top-section flex items-start flex-col lg:flex-row lg:items-center justify-between">
          <div className="flex-col leading-10 font-[600] flex items-start gap-1">
            <h4 className="!text-[#242426] !text-[30px] flex items-start gap-1 !m-0">
              Vendor Details
            </h4>
            <nav className="flex mt-2" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li
                  className="inline-flex !cursor-pointer items-center"
                  onClick={() => navigate(-1)}
                >
                  <div className="inline-flex items-center !cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400">
                    Vendor
                  </div>
                </li>
                {isUserId !== null && (
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="ms-1 cursor-pointer text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400"
                      >
                        {userRole === "vendor" && userDetail?.name}
                        {userRole === "admin" && vendor_name}
                        {userRole === "viewer" && vendor_name}
                      </span>
                    </div>
                  </li>
                )}
              </ol>
            </nav>
          </div>
          <div className="machines-navigation flex items-center justify-end w-max mt-2 mb-3 gap-4">
            <div
              className="send-images !cursor-pointer rounded-[7px] px-[15px] 2xl:!px-[30px] py-[10px] 2xl:!py-[14px] !bg-[#8E5EF9] !border-[#8E5EF9] hover:!text-white hover:border-[#8E5EF9] text-white hover:!bg-[rgba(142,94,249,.7)] uppercase qr-code flex items-center gap-[10px]"
              onClick={() => {
                navigate(`/machine-list/${isUserId}`);
              }}
            >
              <p className="text-sm 2xl:text-base cursor-pointer font-medium">
                View Machines
              </p>
            </div>
          </div>
        </div>
        {/* filter by selecting month */}
        <Grid>
          <div className="flex gap-2 items-start my-4 flex-col">
            <div className="text-[#444] !text-base font-semibold">
              Select Month <span className="text-red-500">*</span>
            </div>
            <div className="flex gap-2 w-full items-center">
              <div className="!w-[200px]">
                <Select
                  styles={customStylesSelect}
                  className="customSelect"
                  placeholder="Select month"
                  options={filterMonthsOptions}
                  defaultValue={filterMonthsOptions[defaultMonthIndex]}
                  value={selectedMonth}
                  onChange={(selectedOption) => {
                    setSelectedMonth(selectedOption); // Update selected month state
                    const { start, end } = getMonthStartAndEndDates(
                      selectedOption.value,
                      2024
                    );
                    getVendorDetails(isUserId, start, end);
                    orderBetweenDates(isUserId, null, start, end);
                  }}
                  isOptionDisabled={(option) => option.isDisabled}
                />
              </div>
              {/* <button
                type="submit"
                onClick={() => {
                  handleReset();
                }}
                tabIndex="0"
                disabled={!startDate || !endDate || loading}
                className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-red-500 transition-all duration-300 ease-in-out rounded-md text-xs 1xl:!text-base text-white font-bold h-[45px] max-w-[140px] min-w-[140px] ${
                  !startDate || !endDate || loading
                    ? "bg-red-400 border-red-400"
                    : "bg-red-500 rounded-[4px] border border-red-500 shadow-sm hover:!bg-red-400 hover:!border-red-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                }`}
              >
                Reset
              </button> */}
            </div>
          </div>
        </Grid>
        {/* Details inner */}
        <div className="vendorDetails-inner">
          {/* bar graph */}
          <div className="grid grid-cols-1 gap-x-4 mt-7">
            {/* Monthly data */}
            <div className="montlyDataBarChart flex flex-col items-start gap-2 !w-full">
              <div className="text-2xl mb-2 font-semibold text-[#444]">
                Monthly Order Payments
              </div>
              <div className="!w-full rounded-md !bg-white px-4 py-4">
                {ordersLoading ? (
                  <div className="h-[350px] flex justify-center items-center">
                    <CircularProgress size={28} className="!text-[#000000]" />
                  </div>
                ) : orderDetails?.length > 0 ? (
                  <Bar
                    className="!h-[350px]"
                    data={chartDataStatus}
                    options={{
                      indexAxis: "x", // Keep x-axis for controlling bar thickness
                      elements: {
                        bar: {
                          borderWidth: 2, // Adjust border width if needed
                          borderRadius: 0, // Make bars rounded (adjust radius as needed)
                        },
                      },
                      datasets: {
                        bar: {
                          barThickness: 14,
                          borderRadius: 0,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                          },
                          title: {
                            text: "Date",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          stacked: true,
                          title: {
                            text: "Amount (₹)",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="h-[350px] flex justify-center items-center">
                    No order found
                  </div>
                )}
              </div>
            </div>
            {/* Today's data */}
            {/* <div className="montlyDataBarChart mt-4 lg:!mt-0 flex flex-col items-start gap-2 !w-full">
              <div className="text-2xl mb-2 font-semibold text-[#444]">
                Today's Order Payments
              </div>
              <div className="!w-full rounded-md !bg-white px-4 py-4">
                {ordersLoading ? ( // Check if orders are loading
                  <div className="h-[350px] flex justify-center items-center">
                    <CircularProgress size={28} className="!text-[#000000]" />
                  </div>
                ) : // Check if todayData is available and not empty
                todayData?.length > 0 ? (
                  <Bar
                    className="!h-[350px]"
                    data={chartDataTodayPayment}
                    options={{
                      indexAxis: "x", // Keep x-axis for controlling bar thickness
                      elements: {
                        bar: {
                          borderWidth: 2, // Adjust border width if needed
                          borderRadius: 0, // Make bars rounded (adjust radius as needed)
                        },
                      },
                      datasets: {
                        bar: {
                          barThickness: 7,
                          borderRadius: 0,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                          },
                          title: {
                            text: "Date",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          stacked: true,
                          title: {
                            text: "Amount (₹)",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  // If todayData is not available or empty
                  <div className="h-[350px] flex justify-center items-center">
                    No order found
                  </div>
                )}
              </div>
            </div> */}
          </div>

          {/* card for orders and payments */}
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-6 1xl:gap-y-[33px] items-center !my-16">
            {/* today's orders */}
            <div className="singleCard p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#2471A3] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="mdi:users"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Today's Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : todayOrders ? (
                    todayOrders
                  ) : (
                    0
                  )}{" "}
                </div>
              </div>
            </div>

            {/* today's payment */}
            <div className="singleCard mt-4 lg:!mt-0 p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#D35400] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Today's Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : dailyCapturedPayment ? (
                    dailyCapturedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* today's refunded */}
            <div className="singleCard p-4 mt-4 lg:!mt-0 relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Today's Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : dailyRefunedPayment ? (
                    dailyRefunedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total orders */}
            <div className="singleCard mt-[16px] p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#8E44AD] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="mdi:users"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Total Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyOrders ? (
                    monthlyOrders
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total collection */}
            <div className="singleCard p-4 mt-[16px] relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#D68910] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Total Collection
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyCapturedPayment ? (
                    monthlyCapturedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total refunded */}
            <div className="singleCard mt-3 lg:mt-6 p-4 relative bg-white rounded-md border border-gray-300 h-auto 1xl:!h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-xs 1xl:!text-base">
                  Total Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyRefundedPayment ? (
                    monthlyRefundedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  background="8E44AD"
                  title="Orders"
                  subTitle={`${
                    startDate && formatDate(startDate, "yyyy-mm-dd")
                  } to ${endDate && formatDate(endDate, "yyyy-mm-dd")}`}
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : monthlyOrders ? (
                      monthlyOrders
                    ) : (
                      0
                    )
                  }
                  color="primary"
                  icon={
                    <Icon
                      icon="mdi:users"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} paddingTop={0}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  background="D68910"
                  title="Collection"
                  subTitle={`${
                    startDate && formatDate(startDate, "yyyy-mm-dd")
                  } to ${endDate && formatDate(endDate, "yyyy-mm-dd")}`}
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : monthlyCapturedPayment ? (
                      monthlyCapturedPayment
                    ) : (
                      0
                    )
                  }
                  color="primary"
                  icon={
                    <Icon
                      icon="lucide:indian-rupee"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} paddingTop={0}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  background="2471A3"
                  title="Today's Orders"
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : todayOrders ? (
                      todayOrders
                    ) : (
                      0
                    )
                  }
                  color="primary"
                  icon={
                    <Icon
                      icon="lucide:indian-rupee"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} paddingTop={0}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  background="D35400"
                  title="Today's Payments"
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : dailyCapturedPayment ? (
                      dailyCapturedPayment
                    ) : (
                      0
                    )
                  }
                  color="primary"
                  icon={
                    <Icon
                      icon="lucide:indian-rupee"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} paddingTop={0}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  title="Today's Refunded"
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : (
                      dailyRefunedPayment && dailyRefunedPayment
                    )
                  }
                  color="primary"
                  background="F28C28"
                  icon={
                    <Icon
                      icon="lucide:indian-rupee"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} paddingTop={0}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px 0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <CommonCard
                  title="Monthly Refunded"
                  total={
                    loading ? (
                      <CircularProgress className="text-white" />
                    ) : (
                      monthlyRefundedPayment && monthlyRefundedPayment
                    )
                  }
                  color="primary"
                  background="F28C28"
                  icon={
                    <Icon
                      icon="lucide:indian-rupee"
                      className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                    />
                  }
                />
              </Paper>
            </Grid>
          </Grid> */}
          {/* <Grid container marginBottom={3} spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <h4 className="text-[24px] leading-[36px] font-medium text-[#242526] my-4">
                Collection{" "}
                <span className="text-lg">
                  ({startDate && formatDate(startDate, "yyyy-mm-dd")} to
                  {endDate && formatDate(endDate, "yyyy-mm-dd")})
                </span>
              </h4>
              <Paper className="flex justify-center items-center shadow-lg !bg-transparent">
                {loading ? (
                  <div className="flex gap-4 justify-center items-center h-[600px]">
                    <h4 className="text-lg font-medium">Loading</h4>
                    <CircularProgress className="!text-[#8e5ef9]" size={30} />
                  </div>
                ) : (
                  <div>
                    {monthlyInitiateCount === 0 &&
                    monthlyCapturedCount === 0 &&
                    monthlyFailedCount === 0 &&
                    monthlyRefundedCount === 0 ? (
                      <div className="h-[400px] flex items-center justify-center w-full text-lg font-medium">
                        No payment found
                      </div>
                    ) : (
                      doughnutChartMonthly
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <h4 className="text-[24px] leading-[36px] font-medium text-[#242526] my-4">
                Today's Payments
              </h4>
              <Paper className="flex justify-center items-center shadow-lg !bg-transparent">
                {loading ? (
                  <div className="flex gap-4 justify-center items-center h-[600px]">
                    <h4 className="text-lg font-medium">Loading</h4>
                    <CircularProgress className="!text-[#8e5ef9]" size={30} />
                  </div>
                ) : (
                  <div>
                    {dailyCapturedCount === 0 &&
                    dailyInitiateCount === 0 &&
                    dailyFailedCount === 0 ? (
                      <div className="h-[400px] flex items-center justify-center w-full text-lg font-medium">
                        No payment found
                      </div>
                    ) : (
                      doughnutChartToday
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid> */}
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
