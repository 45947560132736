import React, { useEffect, useCallback, useState } from "react";
import Navbar from "../../../Navbar/Navbar";
import Sidebar, { SidebarItem } from "../../../shared/Sidebar";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { CircularProgress, Grid, TextField } from "@mui/material";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useToggleContext } from "../../../../context/ToggleContext";
import { useMachineContext } from "../../../../context/MachineContext";
import {
  UTCtoIST,
  customStylesSelect,
  customStylesViewer,
  filterMonthsOptions,
  firstDayOfMonth,
  formatDate,
  formattedEndDate,
  formattedStartDate,
  getMonthStartAndEndDates,
  handleClose,
  handleCountClick,
  lastDayOfMonth,
  role,
  searchTermOrders,
  userDetails,
} from "../../../../utils/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import Modal from "../../../shared/Modal";
import { Bar } from "react-chartjs-2";

const OrdersByCollection = () => {
  // getting the stored limit from the localStorage:
  const storedLimit = localStorage.getItem("limit");

  // navigate hook
  const navigate = useNavigate();

  // states define here
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const { show } = useToggleContext();
  const [limit, setLimit] = useState(storedLimit || 40); // if there is stored limit in storage otherwise default 40
  const [page, setPage] = useState(1);
  const [clickCount, setClickCount] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [startedTime, setStartedTime] = useState(null);
  const [runningTime, setRunningTime] = useState(null);
  const [endedTime, setEndedTime] = useState(null);
  const [statusToast, setStatusToast] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [errorLogs, setErrorLogs] = useState("");

  // using machine context to get the props
  const {
    getAllOrdersByCollections,
    setLoading,
    allOrders,
    pageDetails,
    loading,
  } = useMachineContext();

  // const { id } = useParams();
  const location = useLocation();
  const today = new URLSearchParams(location.search).get("day");
  const todayPaymentStatus = new URLSearchParams(location.search).get(
    "todayPaymentStatus"
  );
  const monthlyPaymentStatus = new URLSearchParams(location.search).get(
    "monthlyPaymentStatus"
  );
  const machineStatus = new URLSearchParams(location.search).get(
    "machineStatus"
  );
  const monthStartDate = new URLSearchParams(location.search).get("startDate");
  const monthEndDate = new URLSearchParams(location.search).get("endDate");

  // const currentMonth = new Date().toLocaleString("default", { month: "long" });

  // State to hold the selected month
  // const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // const defaultMonthIndex = filterMonthsOptions.findIndex(
  //   (option) => option.label === currentMonth
  // );

  // // Disable options after the current month
  // for (let i = defaultMonthIndex + 1; i < filterMonthsOptions.length; i++) {
  //   filterMonthsOptions[i].isDisabled = true;
  // }

  // getting userId from localStorage:
  const userDetail = JSON.parse(localStorage.getItem(userDetails));

  // getting the user type from localstorage :
  const userRole = JSON.parse(localStorage.getItem(role));

  // all admins list mapping through api :
  const allOrdersData = allOrders && allOrders?.map((item) => item);

  // setting default 20 limit when page refresh
  useEffect(() => {
    localStorage.setItem("limit", 40);
  });

  // calling the all orders api
  useEffect(() => {
    setLoading(true);
    userDetail &&
      setTimeout(() => {
        getAllOrdersByCollections(
          today == "todayOrders" ? formatDate(new Date(), "yyyy-mm-dd") : "",
          today == "todayOrders" ? formatDate(new Date(), "yyyy-mm-dd") : "",
          today == "monthlyOrders" ? monthStartDate : "",
          today == "monthlyOrders" ? monthEndDate : "",
          machineStatus ? machineStatus : null,
          todayPaymentStatus ? todayPaymentStatus : null,
          monthlyPaymentStatus ? monthlyPaymentStatus : null,
          limit,
          page,
          searchKey,
          searchTerm
        ).finally(() => setLoading(false));
      });
  }, [limit, page, searchTerm, searchKey]);

  // all orders table columns
  const columns = [
    {
      name: "Order Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.order_id}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.order_id
              ? row?.order_id.length > 10
                ? row?.order_id.substring(0, 10) + "..."
                : row?.order_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Payment Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.payment_id}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={async () => {
              if (row?.status === "failed") {
                setStatusToast(true);
              }
              if (row?.startedDateTime !== null) {
                row?.startedDateTime && setStartedTime(row?.startedDateTime);
              }
              if (row?.runningDateTime !== null) {
                row?.runningDateTime && setRunningTime(row?.runningDateTime);
              }
              if (row?.endedDateTime !== null) {
                row?.endedDateTime && setEndedTime(row?.endedDateTime);
              }
              if (
                row?.startedDateTime !== null ||
                row?.runningDateTime !== null ||
                row?.endedDateTime !== null
              ) {
                handleCountClick(clickCount, setClickCount, setShowDetails);
              }
            }}
          >
            {row?.payment_id
              ? row?.payment_id.length > 7
                ? row?.payment_id.substring(0, 7) + "..."
                : row?.payment_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   name: "Name",
    //   selector: (row) => (
    //     <Tooltip
    //       title={<p className="text-base">{row?.userEnteredName}</p>}
    //       placement="bottom"
    //     >
    //       <p className="text-xs 2xl:!text-base">
    //         {row?.userEnteredName
    //           ? row?.userEnteredName.length > 10
    //             ? row?.userEnteredName.substring(0, 10) + "..."
    //             : row?.userEnteredName
    //           : "-"}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    // {
    //   name: "Phone",
    //   width: "150px",
    //   selector: (row) => (
    //     <p className="text-xs 2xl:!text-base">{row?.userEnteredPhone}</p>
    //   ),
    // },
    {
      name: "Location",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-base">{row?.machineData?.location}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.machineData?.location
              ? row?.machineData?.location.length > 10
                ? row?.machineData?.location.substring(0, 10) + "..."
                : row?.machineData?.location
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "IMEI",
      selector: (row) => (
        <Tooltip
          title={<p className="text-xs 2xl:text-base">{row?.machineImei}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.machineImei
              ? row?.machineImei.length > 9
                ? row?.machineImei.substring(0, 9) + "..."
                : row?.machineImei
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => (
        <Tooltip
          title={
            <p className="text-xs 2xl:text-base">{UTCtoIST(row?.createdAt)}</p>
          }
        >
          <p className="text-xs 2xl:text-base">
            {row?.createdAt
              ? UTCtoIST(row?.createdAt).length > 10
                ? UTCtoIST(row?.createdAt).substring(0, 10) + "..."
                : UTCtoIST(row?.createdAt)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Amount",
      selector: (row) => (
        <p className="text-xs 2xl:text-base">{"₹ " + row?.amount / 100}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => {
        return row?.status !== "failed" ? (
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              if (row.status === "failed") {
                setShowLogsModal(!showLogsModal);
                setErrorLogs(row);
              }
            }}
          >
            {row?.status}
          </p>
        ) : (
          <button
            onClick={() => {
              setShowLogsModal(!showLogsModal);
              setErrorLogs(row);
            }}
            className="border-[#6739b7] border hover:bg-gray-300 transition-all duration-300 ease-in-out hover:text-white hover:font-medium px-4 py-2 rounded-md text-black"
          >
            {row?.status}
          </button>
        );
      },
    },
    {
      name: (
        <div>
          <Tooltip
            placement="bottom"
            title={<span className="text-base">Mode of Payment</span>}
          >
            <p className="text-center">MOP</p>
          </Tooltip>
        </div>
      ),
      // width: "200px",
      selector: (row) => (
        <p
          className="text-xs 2xl:text-base"
          onClick={async () => {
            if (row?.startedDateTime !== null) {
              row?.startedDateTime && setStartedTime(row?.startedDateTime);
            }
            if (row?.runningDateTime !== null) {
              row?.runningDateTime && setRunningTime(row?.runningDateTime);
            }
            if (row?.endedDateTime !== null) {
              row?.endedDateTime && setEndedTime(row?.endedDateTime);
            }
            if (
              row?.startedDateTime !== null ||
              row?.runningDateTime !== null ||
              row?.endedDateTime !== null
            ) {
              handleCountClick(clickCount, setClickCount, setShowDetails);
            }
          }}
        >
          {row?.method ? row?.method : "-"}
        </p>
      ),
    },
  ];

  const columnsMachine = [
    {
      name: "Location",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-base">{row?.location}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.location
              ? row?.location.length > 10
                ? row?.location.substring(0, 10) + "..."
                : row?.location
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Charge",
      selector: (row, index) => (
        <p className="text-xs 2xl:text-base">
          {"₹ " + row?.rateOfMachine / 100}
        </p>
      ),
    },
    {
      name: "IMEI",
      width: "150px",
      selector: (row) => (
        <Tooltip
          placement="bottom"
          title={<p className="text-base">{row?.machineImei}</p>}
        >
          <p className="text-xs 2xl:text-base">
            {row?.machineImei
              ? row?.machineImei.length > 10
                ? row?.machineImei.substring(0, 10) + "..."
                : row?.machineImei
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Created At",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{UTCtoIST(row?.createdAt)}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.createdAt
              ? UTCtoIST(row?.createdAt).length > 10
                ? UTCtoIST(row?.createdAt).substring(0, 10) + "..."
                : UTCtoIST(row?.createdAt)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <p className="text-xs 2xl:text-base">
          {row?.status === true ? "Enabled" : "Disabled"}
        </p>
      ),
    },
    {
      name: "Last Active",
      selector: (row) => (
        <Tooltip
          title={
            <p className="text-base">{UTCtoIST(row?.machineActiveStatus)}</p>
          }
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.machineActiveStatus
              ? UTCtoIST(row?.machineActiveStatus).length > 10
                ? UTCtoIST(row?.machineActiveStatus).substring(0, 10) + "..."
                : UTCtoIST(row?.machineActiveStatus)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Run Duration",
      selector: (row) => (
        <p className="text-xs 2xl:text-base">
          {row?.runDuration ? row?.runDuration + " minutes" : "-"}
        </p>
      ),
    },
  ];
  // calling debounce function to delay the search
  const debounceFn = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    []
  );

  // handling search input
  function handleChange(event) {
    debounceFn(event.target.value);
  }

  // // bar graph data orders:
  // const chartData = {
  //   labels: orderDetails?.map((entry) => entry.date.split("-")[0]), // Extract only the day part
  //   datasets: [
  //     {
  //       label: "Orders",
  //       backgroundColor: "rgba(55,48,163,.3)",
  //       width: "100px",
  //       data: orderDetails?.map((entry) => entry.count),
  //     },
  //   ],
  // };

  // // payments status:
  // const statuses = ["captured", "failed", "refund", "initiate"];
  // const countsByStatus = {};

  // // Initialize countsByStatus for each status for each date to 0
  // orderDetails?.forEach((order) => {
  //   const date = order.date;
  //   countsByStatus[date] = {};
  //   statuses.forEach((status) => {
  //     countsByStatus[date][status] = 0;
  //   });
  // });

  // // Calculate total amount for each status for each date
  // orderDetails?.forEach((order) => {
  //   const date = order.date;
  //   order.data.forEach((payment) => {
  //     countsByStatus[date][payment.status] += payment.totalAmount / 100; // Sum up total amount for each status
  //   });
  // });
  // // Extracting only the day part from the date strings
  // const labels = Object.keys(countsByStatus).map((date) => date.split("-")[0]); // Assuming the date format is "DD-MM-YYYY"

  // // Bar graph data for order status
  // const chartDataStatus = {
  //   labels: labels, // Dates on x-axis (only day part)
  //   datasets: statuses.map((status, index) => ({
  //     label: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize first letter
  //     backgroundColor: [
  //       "#00c04b", // Green
  //       "#FF474C", // Red
  //       "rgba(54, 162, 235, 0.5)", // Blue
  //       "#FFE338", // Yellow
  //     ][index],
  //     data: Object.values(countsByStatus).map((counts) => counts[status]),
  //   })),
  // };

  // // reset button
  // const handleReset = () => {
  //   // today date:
  //   // const today = new Date();
  //   // getting the seven days before date here
  //   // const sevenDaysAgo = new Date(today);
  //   // sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  //   // setting the start and end date here
  //   setStartDate(firstDayOfMonth);
  //   setEndDate(lastDayOfMonth);
  //   setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);

  //   orderBetweenDates(null, null, firstDayOfMonth, lastDayOfMonth);
  // };

  return (
    <>
      <Navbar />
      <div className="flex w-full">
        <Sidebar>
          <SidebarItem
            icon={<LayoutDashboard size={20} />}
            text="Dashboard"
            active
          />
          {userRole != "vendor" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          <SidebarItem
            icon={<Armchair size={20} />}
            text="Machines"
            id={userDetail?.id}
          />
          <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
        </Sidebar>
        <div
          className={`dashboard-main w-full h-[calc(100vh-15px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            {/* top section */}
            <div className="top-section flex items-start lg:!items-center flex-col lg:!flex-row justify-between w-full">
              <div className="!text-[#242426] !text-[30px] flex-col leading-10 font-[600] flex items-start gap-1">
                <h4 className="flex items-start gap-1 !m-0">Orders</h4>
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <div
                        onClick={() => navigate(-1)}
                        className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400"
                      >
                        {machineStatus ? "Machines" : "Orders"}
                      </div>
                    </li>
                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                          {machineStatus == "totalMachine"
                            ? "All"
                            : machineStatus == "true"
                            ? "Active"
                            : machineStatus == "false"
                            ? "InActive"
                            : "All"}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="w-full z-[5] mt-4 lg:mt-0 gap-2 flex items-start flex-col lg:!flex-row lg:!items-center justify-end">
                <div className="w-full md:!w-1/4 lg:!w-[25%] 2xl:!w-1/6 z-50">
                  <Select
                    className="!w-full text-left"
                    placeholder="Select Search Key"
                    options={searchTermOrders}
                    name="search"
                    value={searchKey}
                    onChange={(e) => {
                      setSearchKey(e);
                    }}
                  />
                </div>
                <div className="w-full lg:w-auto">
                  <TextField
                    className="!py-0 !w-full lg:w-auto inputSearch bg-white border border-white"
                    type="search"
                    id="outlined-basic"
                    placeholder="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: null,
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* all orders */}
            <div className="admin-lists orderListing">
              <DataTable
                className="events-lists-table"
                data={allOrdersData}
                columns={machineStatus ? columnsMachine : columns}
                striped={true}
                pagination
                responsive
                fixedHeader
                paginationServer
                noDataComponent={<h4>No order found</h4>}
                paginationRowsPerPageOptions={[20, 40, 80, 100]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                onChangeRowsPerPage={(perPage) => {
                  setLimit(perPage);
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                progressPending={loading}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "#45267e",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
                customStyles={customStylesViewer}
              />
            </div>
          </div>
        </div>
      </div>
      {/* show machine run time modal  */}
      {showDetails ? (
        <Modal
          title="Machine Timing Details"
          closeIcon={() => {
            handleClose(
              setClickCount,
              setShowDetails,
              setStartedTime,
              setEndedTime,
              setRunningTime
            );
          }}
          descriptionText={
            <div className="orders_details my-4">
              <div className="flex flex-col items-start gap-2">
                <label className="!text-[#333] !text-base !font-normal">
                  Started Time
                </label>
                <input
                  placeholder="Started time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="startedTime"
                  value={startedTime ? UTCtoIST(startedTime) : "-"}
                  disabled
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Running Time
                </label>
                <input
                  placeholder="Running time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="runningTime"
                  disabled
                  value={runningTime ? UTCtoIST(runningTime) : "-"}
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Ended Time
                </label>
                <input
                  placeholder="Ended time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="endedTime"
                  disabled
                  value={endedTime ? UTCtoIST(endedTime) : "-"}
                />
              </div>
            </div>
          }
        />
      ) : null}
      {/* in case of failed payments */}
      {statusToast ? (
        <Modal
          closeIcon={() => {
            setStatusToast(false);
          }}
          descriptionText={
            <div className="text-center flex items-center justify-center">
              <h4 className="text-lg text-black">
                The payment is failed for this machine and machine not started
              </h4>
            </div>
          }
        />
      ) : null}
      {/* show the logs modal here */}
      {showLogsModal ? (
        <Modal
          showLogsModal={true}
          closeIcon={() => {
            setShowLogsModal(false);
          }}
          title="Payment logs"
          descriptionText={
            <div className="errors_display_div flex flex-col gap-2 items-start">
              {/* Error Source */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-4 w-full">
                <label className="!text-[#333] !text-base w-full text-left !font-normal">
                  Error Source:
                </label>
                <div className="text-left h-auto !text-base capitalize font-medium">
                  {errorLogs?.errorSource ? errorLogs?.errorSource : "-"}
                </div>
              </div>
              {/* Error Description */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-2 w-full">
                <label className="!text-[#333] w-full text-left !text-base !font-normal">
                  Error Description:
                </label>
                <div className="text-left h-auto break-words w-[100%] !text-base font-medium">
                  {errorLogs?.errorDescription
                    ? errorLogs?.errorDescription
                    : "-"}
                </div>
              </div>
              {/* Error Step */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Error Step:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorStep ? errorLogs?.errorStep : "-"}
                </div>
              </div>
              {/* Reason */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Reason:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorReason ? errorLogs?.errorReason : "-"}
                </div>
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default OrdersByCollection;
