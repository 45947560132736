import httpService from "./httpService";

export const AuthService = {
  login: (loginData) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("login", loginData);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  createVendor: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("user", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  updateVendor: (updatedFields, userId) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.patch("user", updatedFields, {
          params: {
            id: userId,
          },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  deleteVendor: (userId, status) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.patch(`user/${userId}`, { status });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getVendors: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("user", params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getViewers: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("getViewer", params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
};
