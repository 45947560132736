import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { useToggleContext } from "../../../context/ToggleContext";
import Select from "react-select";
import {
  customStylesSelect,
  filterMonthsOptions,
  firstDayOfMonth,
  getMonthStartAndEndDates,
  imeiMachine,
  lastDayOfMonth,
  role,
  userDetails,
  vendorId,
} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  Title,
  LinearScale,
} from "chart.js";
import { useMachineDetailsContext } from "../../../context/MachineDetailsContext";
import { useMachineContext } from "../../../context/MachineContext";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
const MachineDetails = () => {
  // use of useNavigate hook:
  const navigate = useNavigate();
  // use of toggle context here:
  const { show } = useToggleContext();
  // getting localStorage items:
  const { id: machineImei } = useParams();
  const userRole = JSON.parse(localStorage.getItem(role));
  const userDataDetails = JSON.parse(localStorage.getItem(userDetails));
  const vendor_id = JSON.parse(localStorage.getItem(vendorId));
  const { orderBetweenDates, orderDetails, ordersLoading } =
    useMachineContext();

  const {
    getMachineDetails,
    loading,
    todayData,
    dailyCapturedPayment,
    monthlyCapturedPayment,
    todayOrders,
    monthlyOrders,
    setStartDate,
    startDate,
    endDate,
    setEndDate,
    monthlyRefundedPayment,
    monthlyFailedPayments,
    dailyFailedPayments,
    dailyRefunedPayment,
  } = useMachineDetailsContext();

  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  // State to hold the selected month
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // Find the index of the current month in the options array
  const defaultMonthIndex = filterMonthsOptions.findIndex(
    (option) => option.label === currentMonth
  );

  // Disable options after the current month
  for (let i = defaultMonthIndex + 1; i < filterMonthsOptions.length; i++) {
    filterMonthsOptions[i].isDisabled = true;
  }

  useEffect(() => {
    // Set start and end dates
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);

    // Use the updated state values when calling getDashboardData
    const start = firstDayOfMonth;
    const end = lastDayOfMonth;
    getMachineDetails(vendor_id, machineImei, start, end);
    orderBetweenDates(vendor_id, machineImei, start, end);
    localStorage.removeItem(vendorId);
  }, [setStartDate, setEndDate, machineImei]);

  // monthly bar chart functionality here:
  // Prepare total amount for each date and each status
  const totalsByDateAndStatus = {};
  orderDetails?.forEach((order) => {
    const date = order.date;
    totalsByDateAndStatus[date] = totalsByDateAndStatus[date] || {
      initiate: 0,
      failed: 0,
      captured: 0,
      refund: 0,
    };
    order?.data?.forEach((payment) => {
      totalsByDateAndStatus[date][payment.status] += payment.totalAmount / 100; // Total amount for each status on each date
    });
  });

  // Extract unique dates
  const uniqueDates = Object.keys(totalsByDateAndStatus);

  // Prepare labels for the chart (day part of dates)
  const labels = uniqueDates.map((date) => {
    return date.split("-")[0]; // Extract the day part from the date string
  });

  // Prepare datasets for the chart (total amount for each date and each status)
  const datasets = ["captured", "failed", "initiate", "refund"].map(
    (status, index) => ({
      label: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize first letter
      backgroundColor: [
        "#00c04b", // Green
        "#FF474C", // Red
        "#FFE338", // Yellow
        "rgba(54, 162, 235, 0.5)", // Blue
      ][index],
      data: uniqueDates.map((date) => totalsByDateAndStatus[date][status] || 0), // Amount for each status on each date
    })
  );

  // Bar graph data for total amount
  const chartDataStatus = {
    labels, // Dates on x-axis
    datasets, // Total amount on y-axis
  };

  // reset button
  const handleReset = () => {
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);
    getMachineDetails(vendor_id, machineImei, startDate, endDate);
    orderBetweenDates(vendor_id, machineImei, startDate, endDate);
  };
  return (
    <>
      <Navbar />
      <Sidebar>
        <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
        {userRole === "admin" && (
          <SidebarItem icon={<Users size={20} />} text="Vendors" />
        )}
        {userRole === "viewer" && (
          <SidebarItem icon={<Users size={20} />} text="Vendors" />
        )}
        {userRole === "admin" && (
          <SidebarItem icon={<Users size={20} />} text="Viewers" />
        )}
        <SidebarItem icon={<Armchair size={20} />} active text="Machines" />
        {userRole === "admin" && (
          <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
        )}
        {userRole === "viewer" && (
          <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
        )}
        {userRole === "vendor" && (
          <SidebarItem
            icon={<IndianRupee size={20} />}
            text="Orders"
            id={userDataDetails?.id}
          />
        )}
      </Sidebar>
      <div
        className={`dashboard-main w-full h-[calc(100vh-15px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
          show
            ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
            : "pl-[25px] pr-[25px] lg:!pl-[130px]"
        }`}
      >
        <div className="top-section flex-col lg:flex-row flex items-start gap-3 lg:items-center justify-between">
          <div className="!text-[#242426] !text-[30px] flex-col leading-10 font-[600] flex items-start gap-1">
            <h4 className="flex items-start gap-1 !m-0">Machine Details</h4>
            <nav className="flex mt-2" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li
                  className="inline-flex !cursor-pointer items-center"
                  onClick={() => navigate(-1)}
                >
                  <div className="inline-flex items-center !cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400">
                    Machine
                  </div>
                </li>
                {machineImei !== null && (
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="ms-1 cursor-pointer text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400"
                      >
                        {userRole === "admin" ? machineImei : machineImei}
                      </span>
                    </div>
                  </li>
                )}
              </ol>
            </nav>
            {/* Filter by date div */}
          </div>
          <div className="machines-navigation flex items-center gap-4">
            <div
              className="send-images !cursor-pointer rounded-[7px] px-[15px] 2xl:!px-[30px] py-[10px] 2xl:!py-[14px] !bg-[#8E5EF9] !border-[#8E5EF9] hover:!text-white hover:border-[#8E5EF9] text-white hover:!bg-[rgba(142,94,249,.7)] uppercase qr-code flex items-center gap-[10px]"
              onClick={() => {
                localStorage.setItem(imeiMachine, machineImei);
                localStorage.removeItem(vendorId);
                navigate(`/${machineImei}/orders`);
              }}
            >
              <p className="text-sm 2xl:text-base cursor-pointer font-medium">
                View Orders
              </p>
            </div>
          </div>
        </div>
        {/* filter by selecting month */}
        <Grid>
          <div className="flex gap-2 items-start my-4 flex-col">
            <div className="text-[#444] !text-base font-semibold">
              Select Month <span className="text-red-500">*</span>
            </div>
            <div className="flex gap-2 w-full items-center">
              <div className="!w-[200px]">
                <Select
                  styles={customStylesSelect}
                  className="customSelect"
                  placeholder="Select month"
                  options={filterMonthsOptions}
                  defaultValue={filterMonthsOptions[defaultMonthIndex]}
                  value={selectedMonth}
                  onChange={(selectedOption) => {
                    setSelectedMonth(selectedOption); // Update selected month state
                    const { start, end } = getMonthStartAndEndDates(
                      selectedOption.value,
                      2024
                    );
                    getMachineDetails(vendor_id, machineImei, start, end);
                    orderBetweenDates(vendor_id, machineImei, start, end);
                  }}
                  isOptionDisabled={(option) => option.isDisabled}
                />
              </div>
              {/* <button
                type="submit"
                onClick={() => {
                  handleReset();
                }}
                tabIndex="0"
                disabled={!startDate || !endDate || loading}
                className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-red-500 transition-all duration-300 ease-in-out rounded-md text-sm 1xl:!text-base text-white font-bold h-[45px] max-w-[140px] min-w-[140px] ${
                  !startDate || !endDate || loading
                    ? "bg-red-400 border-red-400"
                    : "bg-red-500 rounded-[4px] border border-red-500 shadow-sm hover:!bg-red-400 hover:!border-red-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                }`}
              >
                Reset
              </button> */}
            </div>
          </div>
        </Grid>

        {/* Details inner */}
        <div className="vendorDetails-inner">
          {/* bar graph */}
          <div className="grid grid-cols-1 gap-x-4 mt-7">
            {/* Monthly data */}
            <div className="montlyDataBarChart flex flex-col items-start gap-2 !w-full">
              <div className="text-2xl mb-2 font-semibold text-[#444]">
                Monthly Order Payments
              </div>
              <div className="!w-full rounded-md !bg-white px-4 py-4">
                {loading ? ( // Check if loading state is true
                  <div className="h-[350px] flex justify-center items-center">
                    <CircularProgress size={28} className="!text-[#000000]" />
                  </div>
                ) : orderDetails?.length > 0 ? ( // Check if orderDetails is available and not empty
                  <Bar
                    className="!h-[350px]"
                    data={chartDataStatus}
                    options={{
                      indexAxis: "x", // Keep x-axis for controlling bar thickness
                      elements: {
                        bar: {
                          borderWidth: 2, // Adjust border width if needed
                          borderRadius: 0, // Make bars rounded (adjust radius as needed)
                        },
                      },
                      datasets: {
                        bar: {
                          barThickness: 14,
                          borderRadius: 0,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                          },
                          title: {
                            text: "Date",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                          stacked: true,
                          title: {
                            text: "Amount (₹)",
                            font: {
                              weight: 600,
                              size: 18, // Adjust the font size as needed
                            },
                            display: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="h-[350px] flex justify-center items-center">
                    No order found
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* card for orders and payments */}
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-6 1xl:gap-y-[33px] items-center !my-16">
            {/* today's orders */}
            <div className="singleCard p-4 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#2471A3] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="mdi:users"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Today's Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : todayOrders ? (
                    todayOrders
                  ) : (
                    0
                  )}{" "}
                </div>
              </div>
            </div>

            {/* today's payment */}
            <div className="singleCard p-4 mt-4 lg:!mt-0 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#D35400] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Today's Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : dailyCapturedPayment ? (
                    dailyCapturedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* today's refunded */}
            <div className="singleCard p-4 mt-4 lg:!mt-[18px] xl:!mt-0 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Today's Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : dailyRefunedPayment ? (
                    dailyRefunedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total orders */}
            <div className="singleCard mt-3 p-4 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#8E44AD] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="mdi:users"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Total Orders
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyOrders ? (
                    monthlyOrders
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total collection */}
            <div className="singleCard p-4 mt-3 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#D68910] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Total Collection
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyCapturedPayment ? (
                    monthlyCapturedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>

            {/* total refunded */}
            <div className="singleCard mt-3 xl:mt-6 p-4 relative bg-white rounded-md border border-gray-300 h-[132px] shadow-sm">
              {/* icon */}
              <div className="icon bg-[#F28C28] !text-white rounded-md p-2 absolute left-7 top-[-24px]">
                <Icon
                  icon="lucide:indian-rupee"
                  className="text-[28px] 2xl:!text-[40px] text-[#fff] totalMachine"
                />
              </div>
              {/* content area */}
              <div className="flex justify-end items-end flex-col gap-1">
                {/* title */}
                <div className="title text-[rgb(123,128,154)] text-sm 1xl:!text-base">
                  Total Refunded Payments
                </div>
                <div className="title text-[rgb(52,71,103)] font-bold text-3xl">
                  {loading ? (
                    <CircularProgress size={28} className="!text-black" />
                  ) : monthlyRefundedPayment ? (
                    monthlyRefundedPayment
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MachineDetails;
