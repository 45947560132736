import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import DataTable from "react-data-table-component";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useMachineContext } from "../../../context/MachineContext";
import { Icon } from "@iconify/react";
import { useToggleContext } from "../../../context/ToggleContext";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import {
  UTCtoIST,
  customStyles,
  imeiMachine,
  machineLocation,
  role,
  vendorId,
  vendorName,
} from "../../../utils/utils";
import { Field, Form, Formik } from "formik";
import { addMachineSchema, updateMachineSchema } from "../../../schema/Schema";
import _ from "lodash";
import Modal from "../../shared/Modal";
import { initialValuesMachine } from "../../../utils/formikInitialValues";
const AllMachines = () => {
  const navigate = useNavigate();
  const { show } = useToggleContext();
  const {
    allMachines,
    getAllMachines,
    loading,
    setLoading,
    pageDetails,
    updateMachineDetails,
    setUpdateMachineModal,
    updateMachineModal,
    setMachineId,
    addMachine,
    setAddMachineModal,
    addMachineModal,
  } = useMachineContext();
  const storedLimit = localStorage.getItem("limit");
  const [limit, setLimit] = useState(storedLimit || 40);
  const [page, setPage] = useState(1);
  const [existingData, setExistingData] = useState([]);
  const initialValues = {
    location: existingData?.location || "",
    rateOfMachine: existingData?.rateOfMachine / 100 || "",
    machineImei: existingData?.machineImei || "",
    status: existingData?.status ? existingData?.status : false,
    runDuration: existingData?.runDuration ? existingData?.runDuration : "-",
    cfTermial_id: existingData?.cfTermial_id ? existingData?.cfTermial_id:" ",
    epos: existingData?.epos ? existingData?.epos:" ",
  };

  // getting the user type from localstorage :
  const userRole = JSON.parse(localStorage.getItem(role));
  // all admins list mapping through api :
  const allMachinesList = allMachines && allMachines?.map((item) => item);

  useEffect(() => {
    localStorage.setItem("limit", 40);
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllMachines(limit, page).finally(() => setLoading(false));
  }, [limit, page, userRole]);

  const columns = [
    {
      name: "Location",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-base">{row?.location}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.location
              ? row?.location.length > 10
                ? row?.location.substring(0, 10) + "..."
                : row?.location
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Charge",
      selector: (row, index) => (
        <p
          className="text-xs 2xl:text-base"
          onClick={() => {
            navigate(`/machine-details/${row.machineImei}`);
          }}
        >
          {"₹ " + row?.rateOfMachine / 100}
        </p>
      ),
    },
    {
      name: "IMEI",
      width: "150px",
      selector: (row) => (
        <Tooltip
          placement="bottom"
          title={<p className="text-base">{row?.machineImei}</p>}
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.machineImei
              ? row?.machineImei.length > 10
                ? row?.machineImei.substring(0, 10) + "..."
                : row?.machineImei
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Created At",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{UTCtoIST(row?.createdAt)}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.createdAt
              ? UTCtoIST(row?.createdAt).length > 10
                ? UTCtoIST(row?.createdAt).substring(0, 10) + "..."
                : UTCtoIST(row?.createdAt)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Update",
      selector: (row, index) => (
        <div
          className="bg-[#8E5EF9] text-white hover:bg-[#8E5EF9] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdateMachineModal(true);
            setExistingData(row);
            setMachineId(row?._id);
          }}
        >
          <Tooltip
            placement="left"
            title={<span className="text-base">Edit Machine</span>}
          >
            <Icon icon="mdi:pencil" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "View Orders",
      selector: (row, index) => (
        <Link
          to={`/${row?.machineImei}/orders`}
          onClick={() => {
            localStorage.removeItem(vendorId);
            localStorage.removeItem(vendorName);
            localStorage.setItem(imeiMachine, JSON.stringify(row?.machineImei));
            localStorage.setItem(machineLocation, row?.location);
          }}
          className="bg-[#4992FF] text-white hover:bg-[#4992FF] transition-all duration-300 ease-in-out flex justify-center items-center text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
        >
          <Tooltip
            placement="right"
            title={
              <span className="text-base">
                Click here to view all orders of this machine (
                {row?.machineImei}, {row?.location})
              </span>
            }
          >
            <p>
              <Icon icon="mdi:eye" fontSize={18} />
            </p>
          </Tooltip>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className="text-xs 2xl:text-base"
          onClick={() => {
            navigate(`/machine-details/${row.machineImei}`);
          }}
        >
          {row?.status === true ? "Enabled" : "Disabled"}
        </p>
      ),
    },
    {
      name: "Last Active",
      selector: (row) => (
        <Tooltip
          title={
            <p className="text-base">{UTCtoIST(row?.machineActiveStatus)}</p>
          }
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              navigate(`/machine-details/${row.machineImei}`);
            }}
          >
            {row?.machineActiveStatus
              ? UTCtoIST(row?.machineActiveStatus).length > 10
                ? UTCtoIST(row?.machineActiveStatus).substring(0, 10) + "..."
                : UTCtoIST(row?.machineActiveStatus)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Run Duration",
      selector: (row) => (
        <p
          className="text-xs 2xl:text-base"
          onClick={() => {
            navigate(`/machine-details/${row.machineImei}`);
          }}
        >
          {row?.runDuration ? row?.runDuration + " minutes" : "-"}
        </p>
      ),
    },
  ];
  const filteredColumns =
    userRole === "admin"
      ? columns // Include all columns for admin
      : columns.filter((column) => column.name !== "Update"); // Exclude 'Update Machine' for non-admin
  return (
    <>
      <Navbar />
      <div className="flex w-full">
        <Sidebar>
          <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
          {userRole != "vendor" && (
            <SidebarItem icon={<Users size={20} />} text="Vendors" />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          <SidebarItem icon={<Armchair size={20} />} active text="Machines" />
          <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
        </Sidebar>

        <div
          className={`dashboard-main w-full h-[calc(100vh-5px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            <div className="top-section flex items-center">
              <div className="!text-[#242426] !text-[30px] leading-10 font-[600] flex items-center gap-1">
                <h4 className="flex items-center gap-1 !m-0 text-xl">
                  {" "}
                  <div className="backBtn cursor-pointer"></div> Machines
                </h4>
              </div>
              {userRole == "admin" && (
                <div
                  className="send-images !cursor-pointer rounded-[7px] px-[15px] 2xl:!px-[30px] py-[10px] 2xl:!py-[14px] !bg-[#8E5EF9] !border-[#8E5EF9] hover:!text-white hover:border-[#8E5EF9] text-white hover:!bg-[rgba(142,94,249,.7)] uppercase qr-code flex items-center gap-[10px]"
                  onClick={() => {
                    setAddMachineModal(true);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={
                      <span className="text-base">
                        Click here to add new machine in this application
                      </span>
                    }
                  >
                    <p className="text-sm 2xl:text-base">Add Machine</p>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="admin-lists orderListing">
              <DataTable
                className="events-lists-table"
                data={allMachinesList}
                columns={filteredColumns}
                fixedHeader
                striped={true}
                pagination
                paginationServer
                noDataComponent={<h4>No Machine found</h4>}
                paginationRowsPerPageOptions={[10, 20, 40, 80, 100]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                responsive
                highlightOnHover
                onChangeRowsPerPage={(perPage) => {
                  localStorage.setItem("limit", perPage);
                  setLimit(perPage);
                }}
                onRowClicked={(row) => {
                  // if (userRole !== "viewer") {
                  navigate(`/machine-details/${row.machineImei}`);
                  // }
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                customStyles={customStyles}
                progressPending={loading}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "#45267e",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* add machine modal */}
      {addMachineModal && (
        <Modal
          addMachineModal={addMachineModal}
          title="ADD MACHINE"
          closeIcon={() => setAddMachineModal(false)}
          descriptionText={
            <Formik
              initialValues={initialValuesMachine}
              validationSchema={addMachineSchema}
              onSubmit={(values) => {
                addMachine(values);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik?.handleSubmit}>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:!gap-4 mt-6 lg:mt-12 !justify-start !text-left">
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Location<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        name="location"
                        placeholder="Location of Machine"
                        onBlur={formik?.handleBlur}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.location && formik?.touched?.location
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          } else if (
                            e.key === " " &&
                            e.target.value.trim() === ""
                          ) {
                            e.preventDefault(); // Prevent entering space if the input only contains spaces
                          }
                        }}
                        onChange={(e) => {
                          let newValue = e.target.value.replace(
                            /[^a-zA-Z0-9\s]/g,
                            ""
                          ); // Allow alphanumeric characters and spaces
                          formik.setFieldValue("location", newValue);
                        }}
                      />
                      {formik?.errors?.location && formik?.touched?.location ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.location}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Charge(per usage)
                        <span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        name="price"
                        type="number"
                        placeholder={`₹`}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.price && formik?.touched?.price
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        onBlur={formik?.handleBlur}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                      />
                      {formik?.errors?.price && formik?.touched?.price ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.price}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Commission<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        name="commission"
                        type="number"
                        placeholder={`₹`}
                        onBlur={formik?.handleBlur}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.commission &&
                          formik?.touched?.commission
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                      />
                      {formik?.errors?.commission &&
                      formik?.touched?.commission ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.commission}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        IMEI Number<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        name="imeiNumber"
                        onBlur={formik?.handleBlur}
                        placeholder="IMEI"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                        onChange={(e) => {
                          let newValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                          if (newValue.length > 15) {
                            newValue = newValue.slice(0, 15); // Truncate to 15 digits
                          }
                          formik.setFieldValue("imeiNumber", newValue);
                        }}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.imeiNumber &&
                          formik?.touched?.imeiNumber
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        type="text"
                      />
                      {formik?.errors?.imeiNumber &&
                      formik?.touched?.imeiNumber ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.imeiNumber}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Duration(in minutes)
                        <span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        name="runDuration"
                        type="number"
                        onBlur={formik?.handleBlur}
                        placeholder="Duration"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.runDuration &&
                          formik?.touched?.runDuration
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                      />
                      {formik?.errors?.runDuration &&
                      formik?.touched?.runDuration ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.runDuration}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Cf Terminal
                      </label>
                      <Field
                        name="cfTermial_id"
                        type="number"
                        onBlur={formik?.handleBlur}
                        placeholder="Cf Termial"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.cfTermial_id &&
                          formik?.touched?.cfTermial_id
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                      />
                      {formik?.errors?.cfTermial_id &&
                      formik?.touched?.cfTermial_id ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.cfTermial_id}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Epos
                      </label>
                      <Field
                        name="epos"
                        type="string"
                        onBlur={formik?.handleBlur}
                        placeholder="Epos"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            addMachine();
                          }
                        }}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.epos &&
                          formik?.touched?.epos
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                      />
                      {formik?.errors?.epos &&
                      formik?.touched?.epos ? (
                        <p className="text-[#d50000] mt-2 font-medium">
                          {formik?.errors?.epos }
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Status(Enable/Disable)
                      </label>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => {
                                if (e.target.checked) {
                                  formik?.setFieldValue("status", true);
                                } else {
                                  formik?.setFieldValue("status", false);
                                }
                              }}
                              defaultChecked
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="flex items-center flex-row gap-[10px]">
                    <button
                      type="button"
                      onClick={() => setAddMachineModal(false)}
                      className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                    >
                      Add
                    </button>
                  </div>
                  {/* <button
                    tabIndex="0"
                    type="submit"
                    className={`flex justify-center mx-auto mt-2 w-full items-center border border-solid border-[#45267e] transition-all duration-300 ease-in-out rounded-md text-base text-white font-medium h-[50px] py-2  ${
                      loading
                        ? "!bg-[rgba(142,94,249,.6)]"
                        : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white focus:!bg-[rgba(142,94,249,0.6)] focus:!border-[#8E5EF9] focus:!outline-none focus:!text-[#fff] py-[10px]"
                    }`}
                  >
                    {!loading ? (
                      "Add Machine"
                    ) : (
                      <CircularProgress
                        style={{
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size={30}
                      />
                    )}
                  </button> */}
                </Form>
              )}
            </Formik>
          }
        />
      )}
      {/* Update Machine */}
      {updateMachineModal && (
        <Modal
          updateMachineModal={updateMachineModal}
          title="Update Machine Details"
          closeIcon={() => {
            setUpdateMachineModal(false);
          }}
          descriptionText={
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={updateMachineSchema}
                onSubmit={(values) => {
                  let changed = {};
                  _.map(values, (i, ind) => {
                    if (i !== initialValues?.[ind]) {
                      changed = {
                        ...changed,
                        [ind]: i,
                      };
                    }
                  });
                  if (changed.hasOwnProperty("commission")) {
                    changed.commission *= 100;
                  }
                  if (changed.hasOwnProperty("rateOfMachine")) {
                    changed.rateOfMachine *= 100;
                  }
                  updateMachineDetails(changed, null, limit, page);
                }}
              >
                {(formik) => (
                  <form
                    onSubmit={formik?.handleSubmit}
                    className="mainDiv my-10"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="name"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Location <span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="location"
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="text"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              addMachine();
                            } else if (
                              e.key === " " &&
                              e.target.value.trim() === ""
                            ) {
                              e.preventDefault(); // Prevent entering space if the input only contains spaces
                            }
                          }}
                          onChange={(e) => {
                            let newValue = e.target.value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            ); // Allow alphanumeric characters and spaces
                            formik.setFieldValue("location", newValue);
                          }}
                        />
                        {formik?.errors?.location &&
                        formik?.touched?.location ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.location}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="price"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Charge(per usage)
                          <span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="rateOfMachine"
                          placeholder={`₹`}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="number"
                        />
                        {formik?.errors?.rateOfMachine &&
                        formik?.touched?.rateOfMachine ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.rateOfMachine}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          IMEI<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="machineImei"
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="text"
                          onChange={(e) => {
                            let newValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                            if (newValue.length > 15) {
                              newValue = newValue.slice(0, 15); // Truncate to 15 digits
                            }
                            formik.setFieldValue("machineImei", newValue); // Corrected field name
                          }}
                        />
                        {formik?.errors?.machineImei &&
                        formik?.touched?.machineImei ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.machineImei}
                          </p>
                        ) : null}
                      </div>
                      {/* <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Commission<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="commission"
                          placeholder={`₹`}
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="number"
                        />
                        {formik?.errors?.commission &&
                        formik?.touched?.commission ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.commission}
                          </p>
                        ) : null}
                      </div> */}
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="imei"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Run Duration<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="runDuration"
                          placeholder={`(in minutes)`}
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="number"
                          min={0}
                        />
                        {formik?.errors?.runDuration &&
                        formik?.touched?.runDuration ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.runDuration}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="Cf Terminal"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Cf Terminal<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="cfTermial_id"
                          placeholder={`Cf Terminal`}
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          type="number"
                          min={0}
                        />
                        {formik?.errors?.CfTerminal_id &&
                        formik?.touched?.CfTerminal_id ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.CfTerminal_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group flex items-start flex-col gap-1 mt-1">
                        <label
                          htmlFor="epos"
                          className="!text-[#333] !font-normal !text-base"
                        >
                          Epos <span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          name="epos"
                          placeholder={`epos`}
                          onBlur={formik.handleBlur}
                          className="border border-[#DCDCDC] rounded-[4px] !shadow-none !filter-none !text-base"
                          min={0}
                        />
                        {formik?.errors?.epos &&
                        formik?.touched?.epos ? (
                          <p className="text-[#d50000]">
                            {formik?.errors?.epos}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Status
                        </label>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    formik?.setFieldValue("status", true);
                                  } else {
                                    formik?.setFieldValue("status", false);
                                  }
                                }}
                                checked={formik?.values?.status === true}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="flex items-center flex-row mt-2 gap-[10px]">
                      <button
                        type="button"
                        onClick={() => setUpdateMachineModal(false)}
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
        />
      )}
    </>
  );
};

export default AllMachines;
