import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Lock } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Modal from "../shared/Modal";
import { Icon } from "@iconify/react";
import { useToggleContext } from "../../context/ToggleContext";
function Navbar() {
  const [openLogoutModal, setOpenModal] = useState(false);
  const { show, setShow } = useToggleContext();
  // getting user details from localstorage:
  const userDetail = JSON.parse(localStorage.getItem("user"));
  const initials =
    userDetail && userDetail.name
      ? userDetail.name.charAt(0).toUpperCase()
      : null;

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  // Modal buttons
  const ModalButtons = [
    {
      label: "Cancel",
      Click: () => {
        setOpenModal(false);
      },
    },
    {
      label: "Logout",
      Click: () => {
        handleLogout();
      },
    },
  ];
  const handleLogout = (e) => {
    window.localStorage.clear();
    navigate("/login");
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProfileOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="navbar-main h-[75px] pr-0 lg:pr-7 !w-full lg:w-[92%] z-[11111111111]">
        <div className="container-fluid">
          <div className="navbar-inner">
            <div
              className="ml-4 block lg:hidden"
              onClick={() => setShow(!show)}
            >
              <Icon icon="pepicons-print:menu" fontSize={36} />
            </div>
            <div className="profileMain ml-auto">
              <div className="profilePop">
                <button onClick={toggleProfile}>
                  <p className="mb-0 text-lg">
                    {userDetail && userDetail?.name}
                  </p>
                  <ArrowDropDownIcon />{" "}
                </button>
                {isProfileOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-5 mt-12 w-auto bg-[#394253] p-4 rounded-lg shadow-lg"
                  >
                    <div className="absolute border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-[#394253] right-[33px] -top-2"></div>
                    <div className="profileContainer font-medium">
                      <h2>{userDetail && initials}</h2>
                      <h3 className="text-white my-2">
                        {userDetail && `${userDetail.name}`}
                      </h3>
                      <p className="text-white my-2 email border-b border-b-white pb-3 w-full text-center">
                        {userDetail && userDetail.email}
                      </p>
                    </div>
                    <ul>
                      {/* <li
                        className="flex items-center cursor-pointer gap-2 mb-2 mt-2"
                        onClick={() => {
                          toggleProfile();
                          navigate("/change-password");
                        }}
                      >
                        <Lock className="text-white" />
                        <h3 className="!font-medium text-white !m-0">
                          Change Password
                        </h3>
                      </li> */}
                      <li
                        onClick={() => {
                          toggleProfile();
                          setOpenModal(true);
                        }}
                        className="logoutButton cursor-pointer flex items-center gap-2"
                      >
                        <LogoutIcon className="text-white" />
                        <h3 className="!font-medium text-white">Logout</h3>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openLogoutModal && (
        <Modal
          openLogoutModal={openLogoutModal}
          closeIcon={() => {
            setOpenModal(false);
          }}
          title="Do you want to logout?"
          button={
            <div className="flex mt-12 items-center gap-[10px] !w-full">
              {ModalButtons.map((d, index) => (
                <button
                  onClick={d.Click}
                  className="bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[#fff] hover:!border-[#8E5EF9] transition-all hover:!text-[#8E5EF9] duration-300 ease-in-out w-full text-white py-[10px]"
                  key={index}
                >
                  {d.label}
                </button>
              ))}
            </div>
          }
        />
      )}
    </>
  );
}

export default Navbar;
