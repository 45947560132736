import { ChevronLeft, ChevronRight } from "lucide-react";
import { useToggleContext } from "../../context/ToggleContext";
import { Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { role } from "../../utils/utils";

export default function Sidebar({ children }) {
  const { show, setShow } = useToggleContext();
  const userDetail = JSON.parse(localStorage.getItem("user"));
  const initials =
    userDetail && userDetail.name
      ? userDetail.name.charAt(0).toUpperCase()
      : null;
  return (
    <>
      <aside className="h-auto z-[100] relative">
        {show && (
          <div
            onClick={() => setShow(!show)}
            className={`fixed w-screen h-screen bg-[#000015] opacity-50 block lg:hidden`}
          ></div>
        )}
        <nav
          className={`flex flex-col px-1 fixed transition-all duration-300 ease-in-out h-screen top-0 left-0 bg-white border-r shadow-sm ${
            show ? "ml-0 w-[270px]" : "-ml-[16rem] lg:ml-0 w-0 lg:w-auto"
          }`}
        >
          <div className="p-4 pb-2 flex justify-between items-center">
            <div className="flex items-center gap-3">
              <p
                className={`bg-indigo-500 p-2 rounded-[50%] w-10 flex items-center justify-center text-white ${
                  show ? "block" : "hidden"
                }`}
              >
                {initials}
              </p>
              <h4 className={show ? "w-0 block" : "w-full hidden"}>
                {userDetail?.name}
              </h4>
            </div>
            <button
              onClick={() => setShow(!show)}
              className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
            >
              {show ? <ChevronLeft /> : <ChevronRight />}
            </button>
          </div>
          <Divider
            sx={{
              backgroundColor: "lightgrey",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <ul className="flex-1 px-3 mt-6">{children}</ul>
          <div className="border-t flex p-3">
            <div
              className={`flex justify-between items-center overflow-hidden transition-all ${
                show ? "w-52 ml-3" : "w-0"
              } `}
            ></div>
          </div>
        </nav>
      </aside>
    </>
  );
}

export function SidebarItem({ icon, text, active, id }) {
  const { show } = useToggleContext();
  const userRole = JSON.parse(localStorage.getItem(role));
  let toValue;

  switch (text) {
    case "Dashboard":
      toValue = "/dashboard";
      break;
    case "Vendors":
      toValue = "/vendor-list";
      break;
    case "Machines":
      toValue =
        userRole === "admin" || userRole === "viewer"
          ? "/machine-list/all"
          : `/machine-list/${id}`;
      break;
    case "Orders":
      toValue =
        userRole === "admin" || userRole === "viewer"
          ? "/orders/all"
          : `/orders/all/${id}`;
      break;
    case "Viewers":
      toValue = userRole === "admin" ? "/viewers" : "";
      break;
    default:
      toValue = "";
  }

  return (
    <NavLink
      to={toValue}
      className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${
        active
          ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 !text-indigo-800"
          : "hover:bg-indigo-50 !text-gray-600"
      }`}
    >
      {icon}
      <div
        className={`overflow-hidden transition-all ${
          show ? "w-52 ml-3" : "w-0"
        }`}
      >
        {text}
      </div>
      {!show && (
        <div
          className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:!visible group-hover:!opacity-100 group-hover:!translate-x-0`}
        >
          {text}
        </div>
      )}
    </NavLink>
  );
}
