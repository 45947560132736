import { createContext, useContext, useState } from "react";
import { MachineService } from "../services/machineService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { formatDate, role, userDetails } from "../utils/utils";
import { differenceInDays } from "date-fns";
// creating machine context using createContext hook:
const MachineContext = createContext();

export const MachineProvider = ({ children }) => {
  // creating different useStates as per the requirements:
  const [location, setlocation] = useState("");
  const [price, setPrice] = useState("");
  const [userId, setUserId] = useState(null);
  const [commission, setCommission] = useState("");
  const [machines, setMachines] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [machineId, setMachineId] = useState(null);
  const [updateMachineModal, setUpdateMachineModal] = useState(false);
  const [addMachineModal, setAddMachineModal] = useState(false);
  const [machineOrders, setMachineOrders] = useState([]);
  const [allMachines, setAllMachines] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allotMachineModal, setAllotMachineModal] = useState(false);
  const [availableMachinesList, setAvailableMachines] = useState([]);
  const [reAllotMachineModal, setReAllotMachineModal] = useState(false);
  const [allotMachineLoading, setAllotMachineLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [details, setDetails] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const isRole = JSON.parse(localStorage.getItem(role));
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem(userDetails));

  // Add a machine
  const addMachine = async (values) => {
    setLoading(true);
    try {
      const data = {
        location: values.location,
        rateOfMachine: values.price * 100,
        commission: values.commission * 100,
        machineImei: values?.imeiNumber,
        status: values?.status,
        runDuration: values?.runDuration,
        cfTermial_id:values?.cfTermial_id,
        epos:values?.epos,
      };

      const createMachine = await MachineService.createMachine(data);
      if (createMachine.data.success === true) {
        setAddMachineModal(false);
        setLoading(false);
        toast.success(createMachine.data.message);
        navigate("/machine-list/all");
        getAllMachines(5, 1);
      } 
       else {
        setLoading(false);
        toast.error(createMachine.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 400 || error.response.status == 403) {
        // Handle 400 status code (Bad Request) here
        setLoading(false); // Set loading to false
        // setAddMachineModal(false);
      } else {
        // Handle other errors
        toast.error("An error occurred while creating the machine.");
      }
    }
  };
  // Get machines by userId
  const getMachineList = async (userId, limit, page) => {
    console.log("first");
    setLoading(true);
    try {
      const getMachine = await MachineService.getMachines({
        params: {
          vendorId: userId,
          pageSize: limit,
          page,
        },
      });
      if (getMachine.status === 200) {
        setLoading(false);
        if (getMachine?.data?.machines != null) {
          setMachines(getMachine?.data?.machines);
          setPageDetails(getMachine?.data?.pageDetails);
        }
      } else if (getMachine.data.status === false) {
        setLoading(false);
        // toast.error(getMachine.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      toast.error("An error occurred while fetching the machine data.");
    }
  };
  // Get all the machines for admin:
  const getAllMachines = async (limit, page) => {
    setLoading(true);
    const getAllMachines = await MachineService.getAllMachines({
      params: {
        pageSize: limit,
        page,
      },
    });
    if (getAllMachines.data.success === true) {
      setLoading(false);
      setAllMachines(getAllMachines?.data?.machines);
      setPageDetails(getAllMachines.data.pageDetails);
    } else {
      setLoading(false);
      setAllMachines([]);
      setPageDetails([]);
    }
  };
  // update machine details:
  const updateMachineDetails = async (updatedFields, userId, limit, page) => {
    try {
      const isUpdated = await MachineService.updateMachine(
        updatedFields,
        machineId
      );

      if (isUpdated?.data?.success === true) {
        toast.success(isUpdated?.data?.message);
        setUpdateMachineModal(false);
        userId != null && getMachineList(userId, limit, page);
        isRole == "admin" && getAllMachines(limit, page);
      } else {
        toast.error(isUpdated?.data?.message);
        setUpdateMachineModal(false);
      }
    } catch (error) {
      if (error.response && error.response.status == 400 || error.response.status == 403) {
        // Handle 400 status code (Bad Request) here
        setLoading(false); // Set loading to false
      } else {
        // Handle other errors
        toast.error("An error occurred while creating the machine.");
      }
    }
  };
  // creating the order of machine for razorpay:
  const createOrder = async (machineId, values) => {
    try {
      const data = {
        receipt: machineId,
        userEnteredName: values?.name,
        userEnteredPhone: values?.phone,
      };
      const createMachineOrder = await MachineService.createOrder(data);

      if (createMachineOrder.status === 200) {
        localStorage.setItem(
          "_id",
          JSON.stringify(createMachineOrder.data.order.id)
        );
        const options = {
          key: process.env.REACT_APP_RAZORPAY_API_KEY,
          amount: (
            parseFloat(createMachineOrder?.data?.order.amount) * 100
          ).toString(),
          currency: createMachineOrder?.data?.order.currency,
          name: "Massage99",
          order_id: createMachineOrder?.data?.order.id,
          timeout: 120,
          handler: async function (response) {
            if (response.razorpay_payment_id) {
              navigate("/payment-success");
              const paymentSuccess = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              };
              localStorage.setItem(
                "payment_success",
                JSON.stringify(paymentSuccess)
              );
            }
          },
          prefill: {
            name: "Test",
            email: machineId,
            contact: "9999999999",
          },
          notes: {
            address: "Test address",
          },
          theme: {
            color: "#45267e",
          },
        };
        const paymentObject = new window.Razorpay(options);
        if (createMachineOrder.status === 200) {
          paymentObject.open();
          paymentObject.on("payment.failed", function (response) {
            paymentObject.close();
            window.location.href = `payment-failure`;
            const failedPaymentDetails = response.error.metadata;
            localStorage.setItem(
              "payment_failed",
              JSON.stringify(failedPaymentDetails)
            );
          });
        }
      } else {
        // Handle the error as needed
      }
    } catch (error) {
      error.response.data.message.statusCode === 400 &&
        toast.error(
          "An unexpected error occurred:",
          error.response.data.message.error.description
        );
    }
  };
  // creating the order of machine for PhonePe:
  const createOrderPhonePe = async (machineId) => {
    try {
      setLoading(true);
      const data = {
        machineImei: machineId,
      };
      const createPhonePeOrder = await MachineService.createOrderPhonePe(data);

      if (createPhonePeOrder.status === 200) {
        setLoading(false);
        const redirectUrlPhonePe =
          createPhonePeOrder.data.order.data.instrumentResponse.redirectInfo
            .url;
        return redirectUrlPhonePe;
      } else {
        setLoading(false);
        // Handle the error as needed
      }
    } catch (error) {
      setLoading(false);
      error.response.data.message.statusCode === 400 &&
        toast.error(
          "An unexpected error occurred:",
          error.response.data.message.error.description
        );
    }
  };
  // get all the orders of the machine by IMEI:
  const getOrderDetails = async (machineImei, limit, page) => {
    setLoading(true);
    const getOrders = await MachineService.getOrder({
      params: {
        machineImei,
        pageSize: limit,
        page,
      },
    });
    if (getOrders.data.success === true) {
      setLoading(false);
      setMachineOrders(getOrders?.data?.orders);
      setPageDetails(getOrders?.data?.pageDetails);
    }
  };
  // getting all the orders:
  const getAllOrders = async (userId, limit, page, searchKey, searchTerm) => {
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    const userRole = await JSON.parse(localStorage.getItem(role));
    setLoading(true);
    const pageSize = limit;
    const payload = {
      pageSize: limit,
      page,
    };
    if (searchTerm != null && searchTerm != "" && searchKey?.value) {
      payload[searchKey.value] = encodedSearchTerm;
    }
    const orders =
      userRole === "vendor" || userId
        ? await MachineService.getAllOrdersByUserId(
            userId.replace(/"/g, ""),
            pageSize,
            page
          )
        : await MachineService.getAllOrders({
            params: payload,
          });

    if (orders.data.success === true) {
      setLoading(false);
      if (userId) {
        setMachineOrders(orders?.data?.orders);
      }
      setAllOrders(orders?.data?.orders);
      setPageDetails(orders?.data?.pageDetails);
    } else if (orders?.data?.status === false) {
      toast.error(orders?.data?.message);
      setLoading(false);
      setAllOrders([]);
      setPageDetails([]);
    }
  };

  // getting all the orders:
  const getAllOrdersByCollections = async (
    todayStartDate,
    todayEndDate,
    startDateMonth,
    endDateMonth,
    machineStatus,
    todayPaymentStatus,
    monthPaymentStatus,
    limit,
    page,
    searchKey,
    searchTerm
  ) => {
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    // const userRole = await JSON.parse(localStorage.getItem(role));
    setLoading(true);
    const payload = {
      monthStartDate: startDateMonth,
      monthEndDate: endDateMonth,
      todayStartDate,
      todayEndDate,
      machineStatus: machineStatus,
      paymentStatus: todayPaymentStatus
        ? todayPaymentStatus
        : monthPaymentStatus,
      pageSize: limit,
      vendorId: isRole == "vendor" ? userInfo?.id : null,
      page,
    };
    if (searchTerm != null && searchTerm != "" && searchKey?.value) {
      payload[searchKey.value] = encodedSearchTerm;
    }
    const orders = await MachineService.getAllOrdersByCollection({
      params: payload,
    });

    if (orders.data.success === true) {
      setLoading(false);
      // if (userId) {
      //   setMachineOrders(orders?.data?.orders);
      // }
      setAllOrders(orders?.data?.orders);
      setPageDetails(orders?.data?.pageDetails);
    } else if (orders?.data?.status === false) {
      toast.error(orders?.data?.message);
      setLoading(false);
      setAllOrders([]);
      setPageDetails([]);
    }
  };

  // available machines
  const availableMachinesAll = async () => {
    const isAvailable = await MachineService.availableMachines({
      params: {
        machineAvailability: "available",
      },
    });
    if (isAvailable.data.success === true) {
      setAvailableMachines(isAvailable?.data?.machines);
    } else {
      setAvailableMachines([]);
    }
  };
  // allot machines to user:
  const allotMachine = async (userId, machineId) => {
    setAllotMachineLoading(true);
    const isAlloted = await MachineService.allotMachinesUser({
      userId,
      machineId,
    });
    if (isAlloted?.data?.success === true) {
      setAllotMachineLoading(false);
      setAllotMachineModal(false);
      toast.success(isAlloted?.data?.message);
      window.location.reload();
    } else {
      setAllotMachineLoading(false);
      toast.error(isAlloted?.data?.message);
    }
  };
  // reAllot Machines to any other user:
  const reAllotMachine = async () => {
    setAllotMachineLoading(true);
    const params = {
      machineId,
      deallocate: true,
    };
    const reAllot = await MachineService.reAllotMachinesUser(params);
    if (reAllot?.data?.success === true) {
      setAllotMachineLoading(false);
      toast.success(reAllot.data?.message);
      setAllotMachineLoading(false);
      window.location.reload();
    } else {
      setAllotMachineLoading(false);
    }
  };
  // get all orders of vendor:
  const getVendorAndMachineOrders = async (
    userId,
    machineImei,
    limit,
    page
  ) => {
    try {
      // Assuming getOrderByDate returns a promise
      const getVendorAndMachineOrder =
        await MachineService.getVendorAndMachineOrder({
          userId: userId.replace(/"/g, ""),
          machineImei,
          pageSize: limit,
          page,
        });
      if (getVendorAndMachineOrder.data.success == true) {
        setMachineOrders(getVendorAndMachineOrder?.data?.orders);
        setPageDetails(getVendorAndMachineOrder.data.pageDetails);
      } else {
        setMachineOrders([]);
        setPageDetails(null);
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching orders between dates:", error);
    }
  };
  // order count between dates:
  const orderBetweenDates = async (
    isuserId,
    machineImei,
    startDate,
    endDate
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const role = JSON.parse(localStorage.getItem("role"));

    // Check if startDate and endDate are provided
    if (!startDate || !endDate) {
      toast.error("Start and end dates are required");
      return;
    } else if (!startDate) {
      toast.error("Start date is required");
      return;
    } else if (!endDate) {
      toast.error("End date is required");
      return;
    } else if (endDate < startDate) {
      toast.error("End date must be greater than or equal to start date");
      return;
    } else if (startDate > endDate) {
      toast.error("Start date must be less than or equal to end date");
      return;
    }
    // Calculate the difference between start and end dates in days
    const difference = differenceInDays(endDate, startDate);

    // Check if the difference is more than 30 days
    if (difference > 30) {
      toast.error("Please keep the date range within 30 or 31 days");
      return;
    }
    try {
      console.log("🚀 ~ MachineProvider ~ isuserId:", isuserId);
      setOrdersLoading(true);
      const getOrdersDetails = await MachineService.orderDetailsBetweenDates({
        vendorId: role != "vendor" ? isuserId : user?.id,
        machineImei: machineImei,
        startDate: formatDate(startDate, "yyyy-mm-dd"),
        endDate: formatDate(endDate, "yyyy-mm-dd"),
      });
      // : await MachineService.orderDetailsBetweenDates({
      //     vendorId: user.id,
      //     startDate: formatDate(startDate, "yyyy-mm-dd"),
      //     endDate: formatDate(endDate, "yyyy-mm-dd"),
      //   });
      const { data, success } = getOrdersDetails?.data;
      if (success == true) {
        setOrdersLoading(false);
        setOrderDetails(data);
      } else if (success == false) {
        setOrdersLoading(false);
        setOrderDetails(null);
      }
    } catch (error) {
      setOrderDetails(null);
      setOrdersLoading(false);
    }
  };

  return (
    <MachineContext.Provider
      value={{
        location,
        getAllOrders,
        setlocation,
        price,
        setPrice,
        addMachine,
        createOrder,
        setUserId,
        userId,
        setCommission,
        createOrderPhonePe,
        loading,
        setLoading,
        commission,
        getMachineList,
        machines,
        pageDetails,
        updateMachineDetails,
        setMachineId,
        setUpdateMachineModal,
        updateMachineModal,
        addMachineModal,
        setAddMachineModal,
        getOrderDetails,
        allotMachineLoading,
        machineOrders,
        allMachines,
        getAllMachines,
        allOrders,
        setAllotMachineModal,
        allotMachineModal,
        availableMachinesAll,
        availableMachinesList,
        allotMachine,
        reAllotMachine,
        reAllotMachineModal,
        setReAllotMachineModal,
        getVendorAndMachineOrders,
        details,
        setDetails,
        orderBetweenDates,
        orderDetails,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        setOrdersLoading,
        ordersLoading,
        getAllOrdersByCollections,
      }}
    >
      {children}
    </MachineContext.Provider>
  );
};

export const useMachineContext = () => {
  const context = useContext(MachineContext);
  return context;
};
