import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar/Navbar";
import Select from "react-select";
import Sidebar, { SidebarItem } from "../../../shared/Sidebar";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import { CircularProgress, Grid, Tooltip } from "@mui/material";
import DataTable from "react-data-table-component";
import { useToggleContext } from "../../../../context/ToggleContext";
import { useParams } from "react-router-dom";
import { useMachineContext } from "../../../../context/MachineContext";
import {
  UTCtoIST,
  handleClose,
  handleCountClick,
  role,
  userDetails,
  getMonthStartAndEndDates,
  filterMonthsOptions,
  firstDayOfMonth,
  lastDayOfMonth,
  customStylesSelect,
  customStylesViewer,
} from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import Modal from "../../../shared/Modal";
import { Bar } from "react-chartjs-2";

const AllOrdersVendor = () => {
  const navigate = useNavigate();
  const { show } = useToggleContext();
  const { id } = useParams();
  // checking if there is limit in the storage
  const storedLimit = localStorage.getItem("limit");
  // defining the states here for pagination:
  const [limit, setLimit] = useState(storedLimit || 40);
  const [page, setPage] = useState(1);
  const [clickCount, setClickCount] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [statusToast, setStatusToast] = useState(false);
  const [startedTime, setStartedTime] = useState(null);
  const [runningTime, setRunningTime] = useState(null);
  const [endedTime, setEndedTime] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [errorLogs, setErrorLogs] = useState("");

  // using machine context to get the props
  const {
    getAllOrders,
    setLoading,
    allOrders,
    pageDetails,
    loading,
    orderBetweenDates,
    orderDetails,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    ordersLoading,
  } = useMachineContext();

  // getting userId from localStorage:
  const userDetail = JSON.parse(localStorage.getItem(userDetails));

  // getting the user type from localstorage :
  const userRole = JSON.parse(localStorage.getItem(role));

  // all admins list mapping through api :
  const allOrdersData = allOrders && allOrders?.map((item) => item);

  const currentMonth = new Date().toLocaleString("default", { month: "long" });

  // State to hold the selected month
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const defaultMonthIndex = filterMonthsOptions.findIndex(
    (option) => option.label === currentMonth
  );
  // Disable options after the current month
  for (let i = defaultMonthIndex + 1; i < filterMonthsOptions.length; i++) {
    filterMonthsOptions[i].isDisabled = true;
  }

  // setting limit when page refresh
  useEffect(() => {
    localStorage.setItem("limit", 40);
  }, []);

  // calling the all orders api for vendor:
  useEffect(() => {
    setLoading(true);
    userRole === "vendor" &&
      getAllOrders(id, limit, page).finally(() => setLoading(false));
  }, [limit, page]);

  // useEffect to call order between dates with default start date
  useEffect(() => {
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);
    orderBetweenDates(userDetail?.id, null, firstDayOfMonth, lastDayOfMonth);
  }, []);

  // columns for orders listing
  const columns = [
    {
      name: "Order Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.order_id}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.order_id
              ? row?.order_id.length > 10
                ? row?.order_id.substring(0, 10) + "..."
                : row?.order_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Payment Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.payment_id}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={async () => {
              if (row?.status === "failed") {
                setStatusToast(true);
              }
              if (row?.startedDateTime !== null) {
                row?.startedDateTime && setStartedTime(row?.startedDateTime);
              }
              if (row?.runningDateTime !== null) {
                row?.runningDateTime && setRunningTime(row?.runningDateTime);
              }
              if (row?.endedDateTime !== null) {
                row?.endedDateTime && setEndedTime(row?.endedDateTime);
              }
              if (
                row?.startedDateTime !== null ||
                row?.runningDateTime !== null ||
                row?.endedDateTime !== null
              ) {
                handleCountClick(clickCount, setClickCount, setShowDetails);
              }
            }}
          >
            {row?.payment_id
              ? row?.payment_id.length > 7
                ? row?.payment_id.substring(0, 7) + "..."
                : row?.payment_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   name: "IMEI",
    //   selector: (row) => row?.machineImei,
    // },
    {
      name: "Created Date",
      selector: (row) => (row?.createdAt ? UTCtoIST(row?.createdAt) : "-"),
    },
    {
      name: "Amount",
      selector: (row) => row?.amount / 100,
    },
    {
      name: "Status",
      width: "200px",
      selector: (row) => {
        return row?.status !== "failed" ? (
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              if (row.status === "failed") {
                setShowLogsModal(!showLogsModal);
                setErrorLogs(row);
              }
            }}
          >
            {row?.status}
          </p>
        ) : (
          <button
            onClick={() => {
              setShowLogsModal(!showLogsModal);
              setErrorLogs(row);
            }}
            className="border-[#6739b7] border hover:bg-gray-300 transition-all duration-300 ease-in-out hover:text-white hover:font-medium px-4 py-2 rounded-md text-black"
          >
            {row?.status}
          </button>
        );
      },
    },
    {
      name: (
        <div>
          <Tooltip
            placement="bottom"
            title={<span className="text-base">Mode of Payment</span>}
          >
            <p className="text-center">MOP</p>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.method,
    },
  ];

  // bar graph data orders:
  const chartData = {
    labels: orderDetails?.map((entry) => {
      // Extract the day part from the date string
      const day = entry.date.split("-")[0];
      return day;
    }),
    datasets: [
      {
        label: "Orders",
        backgroundColor: "rgba(55,48,163,.3)",
        width: "100px",
        data: orderDetails?.map((entry) => entry.count),
      },
    ],
  };

  // payments status:
  const statuses = ["captured", "failed", "refund", "initiate"];
  const countsByStatus = {};

  // Initialize countsByStatus for each status for each date to 0
  orderDetails?.forEach((order) => {
    const date = order.date;
    countsByStatus[date] = {};
    statuses.forEach((status) => {
      countsByStatus[date][status] = 0;
    });
  });

  // Calculate total amount for each status for each date
  orderDetails?.forEach((order) => {
    const date = order.date;
    order.data.forEach((payment) => {
      countsByStatus[date][payment.status] += payment.totalAmount / 100; // Sum up total amount for each status
    });
  });

  // Bar graph data for order status
  const chartDataStatus = {
    labels: Object.keys(countsByStatus).map((date) => {
      const day = date.split("-")[0]; // Split the date string and extract the day part
      return day; // Return only the day part
    }),
    datasets: statuses.map((status, index) => ({
      label: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize first letter
      backgroundColor: [
        "#00c04b", // Green
        "#FF474C", // Red
        "rgba(54, 162, 235, 0.5)", // Blue
        "#FFE338", // Yellow
      ][index],
      data: Object.values(countsByStatus).map((counts) => counts[status]),
    })),
  };

  // reset button
  const handleReset = () => {
    // today date:
    const today = new Date();
    // getting the seven days before date here
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    // setting the start and end date here
    setStartDate(sevenDaysAgo);
    setEndDate(today);
    setSelectedMonth(filterMonthsOptions[defaultMonthIndex]);
    // calling the api on the basis of dates
    orderBetweenDates(userDetail?.id, null, startDate, endDate);
  };

  return (
    <>
      <Navbar />
      <div className="flex w-full">
        <Sidebar>
          <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} active text="Vendors" />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          <SidebarItem
            icon={<Armchair size={20} />}
            text="Machines"
            id={userDetail?.id}
          />
          <SidebarItem
            icon={<IndianRupee size={20} />}
            active
            text="Orders"
            id={userDetail?.id}
          />
        </Sidebar>
        <div
          className={`dashboard-main w-full h-[calc(100vh-5px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            <div className="top-section flex items-center">
              <div className="!text-[#242426] !text-[30px] flex-col leading-10 font-[600] flex items-start gap-1">
                <h4 className="flex items-start gap-1 !m-0">Orders</h4>
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <div
                        onClick={() => navigate(-1)}
                        className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400"
                      >
                        Orders
                      </div>
                    </li>
                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                          All
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* bar */}
            {userRole != "viewer" && (
              <Grid>
                <div className="flex gap-2 items-start flex-col">
                  <div className="text-[#444] font-semibold">
                    Select Month <span className="text-red-500">*</span>
                  </div>
                  <div className="flex gap-2 w-full items-center">
                    <div className="!w-[200px]">
                      <Select
                        styles={customStylesSelect}
                        className="customSelect"
                        placeholder="Select month"
                        options={filterMonthsOptions}
                        defaultValue={filterMonthsOptions[defaultMonthIndex]}
                        value={selectedMonth}
                        onChange={(selectedOption) => {
                          setSelectedMonth(selectedOption); // Update selected month state
                          const { start, end } = getMonthStartAndEndDates(
                            selectedOption.value,
                            2024
                          );
                          orderBetweenDates(userDetail?.id, null, start, end);
                        }}
                        isOptionDisabled={(option) => option.isDisabled}
                      />
                    </div>
                    {/* <button
                      type="submit"
                      onClick={() => {
                        handleReset();
                      }}
                      tabIndex="0"
                      disabled={!startDate || !endDate || loading}
                      className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-red-500 transition-all duration-300 ease-in-out rounded-md text-base text-white font-bold h-[45px] max-w-[140px] min-w-[140px] ${
                        !startDate || !endDate || loading
                          ? "bg-red-400 border-red-400"
                          : "bg-red-500 rounded-[4px] border border-red-500 shadow-sm hover:!bg-red-400 hover:!border-red-400 transition-all hover:!text-white duration-300 ease-in-out w-full text-white py-[17px]"
                      }`}
                    >
                      Reset
                    </button> */}
                  </div>
                </div>
              </Grid>
            )}
            <div className="flex flex-col lg:!flex-row gap-4 w-full items-center !mt-10">
              <div className="flex flex-col items-start w-full">
                <div className="capitalize font-semibold flex items-center gap-2 text-2xl mb-2">
                  <div>Total Orders</div>
                </div>
                <div className="bg-white !w-full shadow-md p-4 rounded-md border border-[#eee]">
                  {ordersLoading ? ( // Check if loading state is true
                    <div className="h-[350px] flex justify-center items-center">
                      <CircularProgress size={28} className="!text-[#000000]" />
                    </div>
                  ) : orderDetails && orderDetails.length > 0 ? ( // Check if orderDetails is available and not empty
                    <Bar
                      className="!w-full !h-full"
                      data={chartData}
                      options={{
                        indexAxis: "x", // Keep x-axis for controlling bar thickness
                        elements: {
                          bar: {
                            borderWidth: 2, // Adjust border width if needed
                            borderRadius: 0, // Make bars rounded (adjust radius as needed)
                          },
                        },
                        datasets: {
                          bar: {
                            barThickness: 14,
                            borderRadius: 0,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            title: {
                              text: "Date",
                              font: {
                                weight: 600,
                                size: 18, // Adjust the font size as needed
                              },
                              display: true,
                            },
                            stacked: true,
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            title: {
                              text: "No. of Orders",
                              font: {
                                weight: 600,
                                size: 18, // Adjust the font size as needed
                              },
                              display: true,
                            },
                            grid: {
                              display: false,
                            },
                            stacked: true,
                          },
                        },
                      }}
                    />
                  ) : (
                    <div className="flex items-center justify-center min-h-[400px]">
                      No order found
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start w-full">
                <div className="font-semibold text-2xl flex items-center gap-2 capitalize mb-2">
                  <div>Total Payments (₹)</div>{" "}
                </div>
                <div className="bg-white !w-full shadow-md p-4 rounded-md border border-[#eee]">
                  {ordersLoading ? ( // Check if loading state is true
                    <div className="h-[350px] flex justify-center items-center">
                      <CircularProgress size={28} className="!text-[#000000]" />
                    </div>
                  ) : orderDetails && orderDetails.length > 0 ? ( // Check if orderDetails is available and not empty
                    <Bar
                      className="!w-full !h-full"
                      data={chartDataStatus}
                      options={{
                        indexAxis: "x", // Keep x-axis for controlling bar thickness
                        elements: {
                          bar: {
                            borderWidth: 2, // Adjust border width if needed
                            borderRadius: 0, // Make bars rounded (adjust radius as needed)
                          },
                        },
                        datasets: {
                          bar: {
                            barThickness: 14,
                            borderRadius: 0,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            title: {
                              text: "Date",
                              font: {
                                weight: 600,
                                size: 18, // Adjust the font size as needed
                              },
                              display: true,
                            },
                            stacked: true,
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            title: {
                              text: "Amount (₹)",
                              font: {
                                weight: 600,
                                size: 18, // Adjust the font size as needed
                              },
                              display: true,
                            },
                            grid: {
                              display: false,
                            },
                            stacked: true,
                          },
                        },
                      }}
                    />
                  ) : (
                    <div className="flex items-center justify-center min-h-[400px]">
                      No payment found
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="admin-lists orderListing">
              <DataTable
                className="events-lists-table"
                data={allOrdersData}
                columns={columns}
                striped={true}
                pagination
                fixedHeader
                paginationServer
                noDataComponent={<h4>No order found</h4>}
                paginationRowsPerPageOptions={[20, 40, 80, 100]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                onChangeRowsPerPage={(perPage) => {
                  localStorage.setItem("limit", perPage);
                  setLimit(perPage);
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                progressPending={loading}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "#45267e",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
                customStyles={customStylesViewer}
              />
            </div>
          </div>
        </div>
      </div>
      {/* show the logs modal here */}
      {showLogsModal ? (
        <Modal
          showLogsModal={true}
          closeIcon={() => {
            setShowLogsModal(false);
          }}
          title="Payment logs"
          descriptionText={
            <div className="errors_display_div flex flex-col gap-2 items-start">
              {/* Error Source */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-4 w-full">
                <label className="!text-[#333] !text-base w-full text-left !font-normal">
                  Error Source:
                </label>
                <div className="text-left h-auto !text-base capitalize font-medium">
                  {errorLogs?.errorSource ? errorLogs?.errorSource : "-"}
                </div>
              </div>
              {/* Error Description */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-2 w-full">
                <label className="!text-[#333] w-full text-left !text-base !font-normal">
                  Error Description:
                </label>
                <div className="text-left h-auto break-words w-[100%] !text-base font-medium">
                  {errorLogs?.errorDescription
                    ? errorLogs?.errorDescription
                    : "-"}
                </div>
              </div>
              {/* Error Step */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Error Step:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorStep ? errorLogs?.errorStep : "-"}
                </div>
              </div>
              {/* Reason */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Reason:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorReason ? errorLogs?.errorReason : "-"}
                </div>
              </div>
            </div>
          }
        />
      ) : null}
      {/* show machine run time modal  */}
      {showDetails ? (
        <Modal
          title="Machine Timing Details"
          closeIcon={() => {
            handleClose(
              setClickCount,
              setShowDetails,
              setStartedTime,
              setEndedTime,
              setRunningTime
            );
          }}
          descriptionText={
            <div className="orders_details my-4">
              <div className="flex flex-col items-start gap-2">
                <label className="!text-[#333] !text-base !font-normal">
                  Started Time
                </label>
                <input
                  placeholder="Started time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="startedTime"
                  value={startedTime ? UTCtoIST(startedTime) : "-"}
                  disabled
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Running Time
                </label>
                <input
                  placeholder="Running time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="runningTime"
                  disabled
                  value={runningTime ? UTCtoIST(runningTime) : "-"}
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Ended Time
                </label>
                <input
                  placeholder="Ended time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="endedTime"
                  disabled
                  value={endedTime ? UTCtoIST(endedTime) : "-"}
                />
              </div>
            </div>
          }
        />
      ) : null}
      {/* in case of failed payments */}
      {statusToast ? (
        <Modal
          closeIcon={() => {
            setStatusToast(false);
          }}
          descriptionText={
            <div className="text-center flex items-center justify-center">
              <h4 className="text-lg text-black">
                The payment is failed for this machine and machine not started
              </h4>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default AllOrdersVendor;
