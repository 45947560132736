import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMachineContext } from "../../../context/MachineContext";
import { CircularProgress } from "@mui/material";
import Footer from "../../shared/Footer";

const CreateOrderPayment = () => {
  const { loading, createOrderPhonePe, details } = useMachineContext();

  const [phone, setPhone] = useState("");
  const [payUrl, setPayUrl] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const chairId = new URLSearchParams(location.search).get("ChairId");

  useEffect(() => {
    if (chairId) {
      localStorage.setItem("machineId", JSON.stringify(chairId));
    }
  }, [chairId]);

  const machineId = JSON.parse(localStorage.getItem("machineId"));

  useEffect(() => {
    const chairIdParam = new URLSearchParams(location.search).get("ChairId");
    if (chairIdParam) {
      const newSearch = location.search.replace(/(\?|&)ChairId=[^&]*&?/, "$1");
      navigate({ pathname: location.pathname, search: newSearch });
    }
  }, [navigate, location.search, location.pathname]);

  // useEffect(() => {
  //   // Check if payUrl is present in localStorage
  //   const storedPayUrl = localStorage.getItem("payUrl");
  //   if (storedPayUrl) {
  //     setPayUrl(storedPayUrl);
  //   }
  // }, []);

  const handlePayNowClick = async () => {
    if (machineId) {
      const urlResponse = await createOrderPhonePe(machineId, phone);
      localStorage.setItem("payUrl", urlResponse);
      setPayUrl(urlResponse);
    }
  };

  const handlePay = () => {
    window.open(payUrl, "_blank", "noreferrer");
  };

  // const handlePayNow = async () => {
  //   if (machineId) {
  //     const urlResponse = await createOrderPhonePe(machineId, phone);
  //     window.open(urlResponse, "_blank", "noreferrer");
  //   }
  // };

  return (
    <>
      <div className="flex items-center justify-center flex-col h-screen mx-2">
        <div
          className={`p-4 items-center flex flex-col justify-center w-full max-w-[500px] gap-7 ${
            !payUrl ? "min-h-[250px] text-center" : "h-[300px] text-center"
          }`}
        >
          <h4 className="text-2xl md:text-3xl font-bold">
            Welcome to Massage99
          </h4>
          <div className="flex flex-col items-center gap-4">
            {!payUrl && (
              <div className="flex w-full flex-col items-center">
                <div className="flex justify-center mt- items-center gap-4">
                  <button
                    type="submit"
                    onClick={handlePayNowClick}
                    className={`px-6 py-2 w-full min-w-[132px] text-white text-lg flex justify-center items-center gap-2 rounded-md border ${
                      loading
                        ? "bg-green-400 border-green-400"
                        : "bg-green-500 border-green-500"
                    }`}
                  >
                    {!loading ? (
                      "Continue"
                    ) : (
                      <CircularProgress
                        style={{
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size={30}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
            {payUrl && (
              <div className="flex flex-col items-center gap-4">
                <p className="text-lg">Click below to pay</p>
                <button
                  className={`px-6 py-2 w-full text-white text-lg flex justify-center items-center gap-2 rounded-md border ${
                    loading
                      ? "bg-green-400 border-green-400"
                      : "bg-green-500 border-green-500"
                  }`}
                  onClick={handlePay}
                >
                  Pay Now
                </button>
              </div>
            )}
            {/* {machineId !== "868651068493387" && (
            <div className="flex flex-col items-center gap-4">
              <p className="text-lg">Click below to pay</p>
              <button
                className={`px-6 py-2 w-full text-white text-lg flex justify-center items-center gap-2 rounded-md border ${
                  loading
                    ? "bg-green-400 border-green-400"
                    : "bg-green-500 border-green-500"
                }`}
                onClick={handlePayNow}
              >
                Pay Now
              </button>
            </div>
          )} */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateOrderPayment;
