import React, { useEffect, useRef } from "react";
import "./Modal.css";

const Modal = ({
  title,
  descriptionText,
  updateVendorModal,
  button,
  closeIcon,
  deleteVendorModal,
  confirmationModal,
  updatePasswordModal,
  openLogoutModal,
  addMachineModal,
  reAllotMachineModal,
  addVendorModal,
  updateMachineModal,
  allotMachineModal,
  showLogsModal,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeIcon();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        closeIcon();
      }
    };

    const body = document.querySelector("body");
    body.classList.add("modal-open");

    window.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      body.classList.remove("modal-open");
      window.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [modalRef, closeIcon]);

  return (
    <>
      <div className="parentDiv newCustomPopup">
        <div
          ref={modalRef}
          className={`inner_box ${
            openLogoutModal
              ? ""
              : updateVendorModal
              ? "!h-auto !min-h-auto lg:!h-[470px] lg:!min-h-max w-auto min-w-full lg:w-[500px] lg:min-w-[500px] xl:!h-max xl:!min-h-max"
              : updatePasswordModal
              ? "h-auto lg:!max-h-[300px] lg:!h-[300px]"
              : addMachineModal
              ? "!h-[500px] !min-h-auto lg:!h-[470px] overflow-auto lg:overflow-hidden lg:!min-h-max w-auto min-w-full lg:w-[500px] lg:min-w-[500px] xl:!h-max xl:!min-h-max"
              : updateMachineModal
              ? "!h-[500px] overflow-auto lg:overflow-hidden !min-h-auto lg:!h-[470px] lg:!min-h-max w-auto min-w-full lg:w-[500px] lg:min-w-[500px] xl:!h-max xl:!min-h-max"
              : reAllotMachineModal
              ? "!h-[200px] !max-h-[180px] !min-h-[180px] 2xl:!h-[230px] 2xl:min-h-[230px] 2xl:!max-h-[230px] w-full !min-w-[300px] lg:!w-[400px] lg:!min-w-[400px]"
              : allotMachineModal
              ? "!h-[270px] 2xl:!h-[270px] 2xl:!max-h-[270px] 2xl:!min-h-[270px] w-full !min-w-[300px] 2xl:!w-[400px] 2xl:!min-w-[400px]"
              : addVendorModal
              ? "!h-[500px] overflow-auto lg:overflow-hidden !min-h-auto lg:!h-[470px] lg:!min-h-max w-auto min-w-full lg:w-[500px] lg:min-w-[500px] xl:!h-max xl:!min-h-max"
              : confirmationModal
              ? "min-w-[350px] xl:min-w-[490px]"
              : ""
          }`}
        >
          <h4
            className={`title text-center !text-[#242426] text-base 2xl:!text-xl font-semibold mb-0`}
          >
            {title}
          </h4>
          <div className={`description`}>{descriptionText}</div>
          <div className={`buttonbox`}>{button}</div>
          {/* <div
            className={`crossIcon hidden lg:block`}
            onClick={() => closeIcon()}
          ></div> */}
        </div>
      </div>
    </>
  );
};

export default Modal;
