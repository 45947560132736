import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Navbar from "../../../Navbar/Navbar";
import { CircularProgress } from "@mui/material";
import DataTable from "react-data-table-component";
import { useParams, useNavigate } from "react-router-dom";
import { useMachineContext } from "../../../../context/MachineContext";
import {
  UTCtoIST,
  customStylesViewer,
  handleClose,
  handleCountClick,
  imeiMachine,
  machineLocation,
  role,
  userDetails,
  vendorId,
  vendorName,
} from "../../../../utils/utils";
import Sidebar, { SidebarItem } from "../../../shared/Sidebar";
import { useToggleContext } from "../../../../context/ToggleContext";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
import Modal from "../../../shared/Modal";

const Orders = () => {
  const navigate = useNavigate();

  // checking if there is limit in the storage
  const storedLimit = localStorage.getItem("limit");
  // defining the states here for pagination:
  const [limit, setLimit] = useState(storedLimit || 20);
  const [page, setPage] = useState(1);
  const [clickCount, setClickCount] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [startedTime, setStartedTime] = useState(null);
  const [runningTime, setRunningTime] = useState(null);
  const [endedTime, setEndedTime] = useState(null);
  const [statusToast, setStatusToast] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [errorLogs, setErrorLogs] = useState("");

  // getting machine Imei from the params:
  const { machineImei } = useParams();
  const isUserId = localStorage.getItem(vendorId);
  // using machine context to get the props
  const {
    getOrderDetails,
    getAllOrders,
    setLoading,
    machineOrders,
    pageDetails,
    loading,
    getVendorAndMachineOrders,
  } = useMachineContext();
  const { show } = useToggleContext();
  // getting userId from localStorage:
  const userDetail = JSON.parse(localStorage.getItem(userDetails));
  // getting the machine location:
  const location = localStorage.getItem(machineLocation);
  // getting the user type from localstorage :
  const userRole = JSON.parse(localStorage.getItem(role));
  // getting the vendor name:
  const vendor_name = localStorage.getItem(vendorName);
  // all admins list mapping through api :
  const machinesList = machineOrders && machineOrders?.map((item) => item);
  // const vendorsOrdersList = allOrders && allOrders?.map((item) => item);
  const machineImeiId = JSON.parse(localStorage.getItem(imeiMachine));

  // setting limit when page refresh
  useEffect(() => {
    localStorage.setItem("limit", 20);
  }, []);
  // // calling orders api here
  // useEffect(() => {
  //   setLoading(true);
  //   if (isUserId && machineImeiId) {
  //     console.log("first");
  //     getVendorAndMachineOrders(isUserId, machineImeiId, limit, page).finally(
  //       () => setLoading(false)
  //     );
  //   } else if (userRole === "admin" && isUserId && machineImeiId === null) {
  //     console.log('first"');
  //     getAllOrders(isUserId, limit, page).finally(() => setLoading(false));
  //   } else if (
  //     userRole === "admin" ||
  //     (userRole == "viewer" && machineImeiId && isUserId === null)
  //   ) {
  //     console.log('first"');
  //     getOrderDetails(machineImeiId, limit, page).finally(() =>
  //       setLoading(false)
  //     );
  //   } else if (
  //     userRole == "vendor" &&
  //     userDetail?.id !== null &&
  //     machineImeiId
  //   ) {
  //     console.log('first"');
  //     getVendorAndMachineOrders(
  //       userDetail?.id,
  //       machineImeiId,
  //       limit,
  //       page
  //     ).finally(() => setLoading(false));
  //   }
  //   console.log("first", userDetail.id);
  // }, [isUserId, machineImeiId, limit, page]);
  useEffect(() => {
    console.log(machineImeiId, isUserId, "ididididid");
    setLoading(true);

    if (isUserId && machineImeiId) {
      // Case 1: Both user ID and machine IMEI ID are provided
      getVendorAndMachineOrders(isUserId, machineImeiId, limit, page).finally(
        () => setLoading(false)
      );
    } else if (!isUserId && machineImeiId) {
      // Case 2: Machine IMEI ID is provided but user ID is not
      getOrderDetails(machineImeiId, limit, page).finally(() =>
        setLoading(false)
      );
    } else if (
      (userRole === "admin" || userRole === "viewer") &&
      isUserId &&
      !machineImeiId
    ) {
      // Case 3: Admin or viewer with user ID only
      getAllOrders(isUserId, limit, page).finally(() => setLoading(false));
    } else if (
      userRole === "admin" ||
      userRole === "vendor" ||
      (userRole === "viewer" && machineImeiId && !isUserId)
    ) {
      // Case 4: Admin, vendor, or viewer with machine IMEI ID only
      getOrderDetails(machineImeiId, limit, page).finally(() =>
        setLoading(false)
      );
    } else if (userRole === "vendor" && userDetail?.id && machineImeiId) {
      // Case 5: Vendor with user detail ID and machine IMEI ID
      getVendorAndMachineOrders(
        userDetail?.id,
        machineImeiId,
        limit,
        page
      ).finally(() => setLoading(false));
    } else {
      // Fallback: Handle other cases
      setLoading(false);
    }
  }, [limit, page, userRole]);
  console.log("🚀 ~ useEffect ~ isUserId:", isUserId);

  // react data table columns
  const columns = [
    {
      name: "Order Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.order_id}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-base">
            {row?.order_id
              ? row?.order_id.length > 10
                ? row?.order_id.substring(0, 10) + "..."
                : row?.order_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Payment Id",
      selector: (row) => (
        <Tooltip
          title={<p className="text-base">{row?.payment_id}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-base"
            onClick={async () => {
              if (row?.status === "failed") {
                setStatusToast(true);
              }
              if (row?.startedDateTime !== null) {
                row?.startedDateTime && setStartedTime(row?.startedDateTime);
              }
              if (row?.runningDateTime !== null) {
                row?.runningDateTime && setRunningTime(row?.runningDateTime);
              }
              if (row?.endedDateTime !== null) {
                row?.endedDateTime && setEndedTime(row?.endedDateTime);
              }
              if (
                row?.startedDateTime !== null ||
                row?.runningDateTime !== null ||
                row?.endedDateTime !== null
              ) {
                handleCountClick(clickCount, setClickCount, setShowDetails);
              }
            }}
          >
            {row?.payment_id
              ? row?.payment_id.length > 7
                ? row?.payment_id.substring(0, 7) + "..."
                : row?.payment_id
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   name: "Name",
    //   selector: (row, index) => (
    //     <p className="text-xs 2xl:text-base">{row?.userEnteredName}</p>
    //   ),
    // },
    // {
    //   name: "Phone number",
    //   selector: (row, index) => (
    //     <p className="text-xs 2xl:text-base">{row?.userEnteredPhone}</p>
    //   ),
    // },
    // {
    //   name: "Location",
    //   selector: (row, index) => (
    //     <Tooltip
    //       title={<p className="text-base">{row?.machineData.location}</p>}
    //       placement="bottom"
    //     >
    //       <p className="text-xs 2xl:text-base">
    //         {row?.machineData.location
    //           ? row?.machineData.location.length > 10
    //             ? row?.machineData.location.substring(0, 10) + "..."
    //             : row?.machineData.location
    //           : "-"}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    // {
    //   name: "IMEI",
    //   selector: (row) => (
    //     <Tooltip
    //       title={<p className="text-xs 2xl:text-base">{row?.machineImei}</p>}
    //       placement="bottom"
    //     >
    //       <p className="text-xs 2xl:text-base">
    //         {row?.machineImei
    //           ? row?.machineImei.length > 9
    //             ? row?.machineImei.substring(0, 9) + "..."
    //             : row?.machineImei
    //           : "-"}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    {
      name: "Created Date",
      selector: (row) => (
        <Tooltip
          title={
            <p className="text-xs 2xl:text-base">{UTCtoIST(row?.createdAt)}</p>
          }
        >
          <p className="text-xs 2xl:text-base">
            {row?.createdAt
              ? UTCtoIST(row?.createdAt).length > 10
                ? UTCtoIST(row?.createdAt).substring(0, 10) + "..."
                : UTCtoIST(row?.createdAt)
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Amount",
      selector: (row) => (
        <p className="text-xs 2xl:text-base">{"₹ " + row.amount / 100}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => {
        return row?.status !== "failed" ? (
          <p
            className="text-xs 2xl:text-base"
            onClick={() => {
              if (row.status === "failed") {
                setShowLogsModal(!showLogsModal);
                setErrorLogs(row);
              }
            }}
          >
            {row?.status}
          </p>
        ) : (
          <button
            onClick={() => {
              setShowLogsModal(!showLogsModal);
              setErrorLogs(row);
            }}
            className="border-[#6739b7] border hover:bg-gray-300 transition-all duration-300 ease-in-out hover:text-white hover:font-medium px-4 py-2 rounded-md text-black"
          >
            {row?.status}
          </button>
        );
      },
    },
    {
      name: (
        <div>
          <Tooltip
            placement="bottom"
            title={<span className="text-base">Mode of Payment</span>}
          >
            <p className="text-center">MOP</p>
          </Tooltip>
        </div>
      ),
      width: "200px",
      selector: (row) => <p className="text-xs 2xl:text-base">{row?.method}</p>,
    },
  ];
  return (
    <>
      {/* using navbar here */}
      <Navbar />
      <div className="flex w-full">
        {/* using the sidebar here */}
        <Sidebar>
          <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
          {userRole != "vendor" && (
            <SidebarItem
              icon={<Users size={20} />}
              text="Vendors"
              active={isUserId && machineImei}
            />
          )}
          {userRole === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          {userRole != "admin" && (
            <SidebarItem
              icon={<Armchair size={20} />}
              text="Machines"
              id={userDetail?.id}
            />
          )}
          {userRole === "admin" && (
            <SidebarItem
              icon={<Armchair size={20} />}
              text="Machines"
              active={!isUserId}
              id={userDetail?.id}
            />
          )}
          {userRole === "vendor" && (
            <SidebarItem
              icon={<IndianRupee size={20} />}
              text="Orders"
              id={userDetail?.id}
              active
            />
          )}
          {userRole != "vendor" && (
            <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
          )}
        </Sidebar>
        {/* Order main starts here */}
        <div
          className={`dashboard-main overflow-x-hidden w-full h-[calc(100vh-15px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            <h4 className="text-2xl text-center font-bold capitalize">
              {`${vendor_name !== null ? `${vendor_name}'s` : ""}`} all orders{" "}
              {machineImeiId !== null && `for this machine (${machineImeiId})`}
            </h4>
            <div className="top-section flex items-center">
              <div className="!text-[#242426] !text-[30px] flex-col leading-10 font-[600] flex items-start gap-1">
                <h4 className="flex items-start gap-1 !m-0">Orders</h4>
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <div
                        onClick={() => navigate(-1)}
                        className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-700 dark:text-gray-400"
                      >
                        Machine
                      </div>
                    </li>
                    {isUserId !== null && (
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <span
                            onClick={() => {
                              navigate(-1);
                            }}
                            className="ms-1 cursor-pointer text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400"
                          >
                            {userRole === "admin" || userRole === "viewer"
                              ? vendor_name
                              : userDetail?.name}
                          </span>
                        </div>
                      </li>
                    )}
                    {isUserId == null && (
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                            {machineImei !== "undefined" ? machineImei : ""}
                            {location && `(${location})`}
                          </span>
                        </div>
                      </li>
                    )}
                  </ol>
                </nav>
              </div>
            </div>

            <div className="admin-lists orderListing">
              <DataTable
                className="events-lists-table"
                data={machinesList}
                columns={columns}
                striped={true}
                responsive
                fixedHeader
                pagination
                paginationServer
                customStyles={customStylesViewer}
                noDataComponent={<h4>No order found</h4>}
                paginationRowsPerPageOptions={[20, 40, 80, 100, 150]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                onChangeRowsPerPage={(perPage) => {
                  localStorage.setItem("limit", perPage);
                  setLimit(perPage);
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                progressPending={loading}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "#45267e",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* show machine run time modal  */}
      {showDetails ? (
        <Modal
          title="Machine Timing Details"
          closeIcon={() => {
            handleClose(
              setClickCount,
              setShowDetails,
              setStartedTime,
              setEndedTime,
              setRunningTime
            );
          }}
          descriptionText={
            <div className="orders_details my-4">
              <div className="flex flex-col items-start gap-2">
                <label className="!text-[#333] !text-base !font-normal">
                  Started Time
                </label>
                <input
                  placeholder="Started time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="startedTime"
                  value={startedTime ? UTCtoIST(startedTime) : "-"}
                  disabled
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Running Time
                </label>
                <input
                  placeholder="Running time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="runningTime"
                  disabled
                  value={runningTime ? UTCtoIST(runningTime) : "-"}
                />
              </div>
              <div className="flex flex-col items-start gap-2 mt-3">
                <label className="!text-[#333] !text-base !font-normal">
                  Ended Time
                </label>
                <input
                  placeholder="Ended time"
                  className="border border-[#DCDCDC] h-10 pl-3 !rounded-[4px] !shadow-none w-full !filter-none !text-base"
                  name="endedTime"
                  disabled
                  value={endedTime ? UTCtoIST(endedTime) : "-"}
                />
              </div>
            </div>
          }
        />
      ) : null}
      {/* in case of failed payments */}
      {statusToast ? (
        <Modal
          closeIcon={() => {
            setStatusToast(false);
          }}
          descriptionText={
            <div className="text-center flex items-center justify-center">
              <h4 className="text-lg text-black">
                The payment is failed for this machine and machine not started
              </h4>
            </div>
          }
        />
      ) : null}
      {/* show the logs modal here */}
      {showLogsModal ? (
        <Modal
          showLogsModal={true}
          closeIcon={() => {
            setShowLogsModal(false);
          }}
          title="Payment logs"
          descriptionText={
            <div className="errors_display_div flex flex-col gap-2 items-start">
              {/* Error Source */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-4 w-full">
                <label className="!text-[#333] !text-base w-full text-left !font-normal">
                  Error Source:
                </label>
                <div className="text-left h-auto !text-base capitalize font-medium">
                  {errorLogs?.errorSource ? errorLogs?.errorSource : "-"}
                </div>
              </div>
              {/* Error Description */}
              <div className="flex flex-col items-start gap-1 border-b border-gray-300 pb-2 mt-2 w-full">
                <label className="!text-[#333] w-full text-left !text-base !font-normal">
                  Error Description:
                </label>
                <div className="text-left h-auto break-words w-[100%] !text-base font-medium">
                  {errorLogs?.errorDescription
                    ? errorLogs?.errorDescription
                    : "-"}
                </div>
              </div>
              {/* Error Step */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Error Step:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorStep ? errorLogs?.errorStep : "-"}
                </div>
              </div>
              {/* Reason */}
              <div className="flex flex-col items-start border-b border-gray-300 pb-2 gap-1 mt-2 w-full">
                <label className="!text-[#333] !text-base !font-normal">
                  Reason:
                </label>
                <div className="text-left h-auto !text-base font-medium">
                  {errorLogs?.errorReason ? errorLogs?.errorReason : "-"}
                </div>
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default Orders;
