export const initialValuesAddCustomer = {
  name: "",
  phone: "",
};
export const initialValuesVendor = {
  vendorName: "",
  email: "",
  phone: "",
  password: "",
};
export const initialValuesViewer = {
  name: "",
  email: "",
  phone: "",
  password: "",
};
export const initialValuesLogin = {
  email: "",
  password: "",
};

export const initialValuesMachine = {
  location: "",
  price: "",
  commission: 0,
  imeiNumber: "",
  runDuration: "",
  cfTermial_id:"",
  epos:"",
  status: false,
};

export const initialValuesAllotMachine = {
  allotMachine: "",
};

export const updatePasswordValue = {
  password: "",
};
