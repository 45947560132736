import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import {
  Armchair,
  IndianRupee,
  LayoutDashboard,
  Users,
  View,
} from "lucide-react";
import { useToggleContext } from "../../../context/ToggleContext";
import { useAuthContext } from "../../../context/AuthContext";
import { Field, Formik } from "formik";
import { Form, Link } from "react-router-dom";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import Modal from "../../shared/Modal";
import {
  initialValuesViewer,
  updatePasswordValue,
} from "../../../utils/formikInitialValues";
import {
  addViewerSchema,
  updatePasswordSchema,
  updateVendorSchema,
} from "../../../schema/Schema";
import { Icon } from "@iconify/react";
import {
  customStyles,
  customStylesViewer,
  jwtToken,
  role,
} from "../../../utils/utils";
import DataTable from "react-data-table-component";

const Viewer = () => {
  // usestates here
  const storedLimit = localStorage.getItem("limit");
  const [limit, setLimit] = useState(storedLimit || 10);
  const [page, setPage] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [existingData, setExistingData] = useState(null);

  // using toggle context here
  const { show } = useToggleContext();

  // using auth context here
  const {
    addViewerModal,
    setAddViewerModal,
    addVendor,
    loading,
    getViwersList,
    allViewers,
    pageDetails,
    setLoading,
    setVendorId,
    updateVendorDetails,
    updateVendorModal,
    deleteVendorModal,
    setUpdateVendorModal,
    deleteVendor,
    updatePasswordModal,
    setUpdatePasswordModal,
    confirmationModal,
    setConfirmationModal,
  } = useAuthContext();

  // existing data
  // using formik initialvalues for existing data of vendor:
  const initialValues = {
    name: existingData?.name || "",
    email: existingData?.email || "",
    phone: existingData?.phone || "",
  };

  // getting the jwt token from the localstorage:
  const jwt = JSON.parse(localStorage.getItem(jwtToken));

  // getting the user type from localstorage :
  const user = JSON.parse(localStorage.getItem(role));

  // all admins list mapping through api :
  const allViewersList = allViewers && allViewers?.map((item) => item);

  // setting the limit to 10 when page reload
  useEffect(() => {
    localStorage.setItem("limit", 10);
  }, []);

  // calling the fetchData function
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      jwt && user === "admin" && fetchData();
    });
  }, [limit, page, jwt, user]);

  const fetchData = (searchKey = "", searchTerm = "") => {
    getViwersList(limit, page, searchTerm, searchKey).finally(() =>
      setLoading(false)
    );
  };

  // data table columns here
  const columns = [
    {
      name: "Name",
      minWidth: "150px",
      selector: (row, index) => (
        <p className="text-xs 2xl:text-sm">{row?.name}</p>
      ),
    },
    {
      name: "Email",
      minWidth: "180px",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-xs 2xl:text-base">{row?.email}</p>}
          placement="bottom"
        >
          <p className="text-xs 2xl:text-sm">
            {row?.email.length > 25
              ? row?.email.substring(0, 25) + "..."
              : row?.email}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Phone",
      selector: (row, index) =>
        row?.phone ? <p className="text-xs 2xl:text-sm">{row?.phone}</p> : "-",
    },
    {
      name: "Update Viewer",
      selector: (row, index) => (
        <div
          className="bg-[#8E5EF9] text-white hover:bg-[#8E5EF9] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdateVendorModal(true);
            setExistingData(row);
            setVendorId(row?._id);
          }}
        >
          <Tooltip
            placement="left"
            title={<span className="text-base">Edit Viewer</span>}
          >
            <Icon icon="mdi:pencil" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <Tooltip
          title={
            <div className="!text-base">
              {row?.status === false
                ? "Click to activate"
                : "Click to deactivate"}
            </div>
          }
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onClick={async () => {
                    if (user === "admin") {
                      setConfirmationModal(true);
                      setRowData(row);
                    }
                  }}
                  checked={row?.status}
                />
              }
            />
          </FormGroup>
        </Tooltip>
      ),
    },
    {
      name: "Update Password",
      selector: (row) => (
        <div
          className="bg-[#d50000] text-white hover:bg-[#d50000] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdatePasswordModal(true);
            setVendorId(row?._id);
          }}
        >
          <Tooltip
            placement="bottom"
            title={
              <span className="text-base">
                Click here to update {row?.name}'s password
              </span>
            }
          >
            <Icon icon="mdi:password" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar />
      <Sidebar>
        <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
        <SidebarItem icon={<Users size={20} />} text="Vendors" />
        <SidebarItem icon={<View size={20} />} active text="Viewers" />
        <SidebarItem icon={<Armchair size={20} />} text="Machines" />
        <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
      </Sidebar>
      <div
        className={`dashboard-main w-full admin-dashboard-main h-[calc(100vh-5px)] overflow-auto transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
          show
            ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
            : "pl-[25px] pr-[25px] lg:!pl-[130px]"
        }`}
      >
        <div className="top-section flex items-center justify-between w-full">
          <h4 className="font-semibold text-lg">
            <div className="!text-[#242426] text-[30px] leading-10 font-[600] flex items-center gap-1 !m-0 text-xl">
              Viewers
            </div>
          </h4>
          <div className="search-main gap-4 header-right">
            <div className="flex gap-2 items-center justify-center">
              <div
                className="send-images !cursor-pointer rounded-[7px] px-[15px] 2xl:!px-[30px] py-[10px] 2xl:!py-[14px] !bg-[#8E5EF9] !border-[#8E5EF9] hover:!text-white hover:border-[#8E5EF9] text-white hover:!bg-[rgba(142,94,249,.7)] uppercase qr-code flex items-center gap-[10px]"
                onClick={() => {
                  setAddViewerModal(true);
                }}
              >
                <p className="text-sm 2xl:text-base cursor-pointer font-medium">
                  Add Viewer
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* viewers listing */}
        <div className="admin-lists orderListing">
          <DataTable
            className="events-lists-table"
            data={allViewersList}
            columns={columns}
            striped={true}
            pagination
            paginationServer
            fixedHeader
            // highlightOnHover
            responsive
            noDataComponent={<h4>No Viewer found</h4>}
            paginationRowsPerPageOptions={[10, 20, 40, 80, 100]}
            paginationTotalRows={pageDetails?.noOfRecords}
            paginationPerPage={limit}
            // onRowClicked={(row) => {
            //   navigate(`/vendor-details/${row?.id}`);
            //   localStorage.setItem(vendorName, row?.name);
            //   localStorage.setItem(vendorId, JSON.stringify(row?.id));
            // }}
            onChangeRowsPerPage={(perPage) => {
              localStorage.setItem("limit", perPage);
              setLimit(perPage);
            }}
            onChangePage={(page) => {
              setPage(page);
            }}
            progressPending={loading}
            customStyles={customStylesViewer}
            progressComponent={
              <CircularProgress
                size={42}
                style={{
                  color: "rgba(41,0,66,1)",
                  display: "flex",
                  justifyContent: "center",
                  height: "90vh",
                  alignItems: "center",
                }}
              />
            }
          />
        </div>
      </div>
      {/* add viewer modal */}
      {addViewerModal && (
        <Modal
          title="Add Viewer"
          closeIcon={() => {
            setAddViewerModal(false);
          }}
          addVendorModal={addViewerModal}
          descriptionText={
            <Formik
              initialValues={initialValuesViewer}
              validationSchema={addViewerSchema}
              onSubmit={(values) => {
                console.log(values, "vadd");
                addVendor(values, true);
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Name<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Name"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.name && formik?.touched?.name
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        name="name"
                        onBlur={formik?.handleBlur}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\s/.test(inputValue)) {
                            // e.preventDefault();
                            return;
                          }
                          if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                            formik.setFieldValue("name", inputValue);
                          }
                        }}
                      />
                      {formik?.errors?.name && formik?.touched?.name ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.name}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Email Address<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Email Address"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.email && formik?.touched?.email
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        name="email"
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.errors?.email && formik?.touched?.email ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.email}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Phone<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Phone"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.phone && formik?.touched?.phone
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        type="tel" // Use type="tel" for telephone numbers
                        name="phone"
                        onBlur={formik?.handleBlur}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Check if the input consists of digits and has a length of up to 10 characters
                          if (/^\d{0,10}$/.test(inputValue)) {
                            formik.setFieldValue("phone", inputValue);
                          }
                        }}
                      />
                      {formik?.errors?.phone && formik?.touched?.phone ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.phone}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Password<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Password"
                        type={`${showPassword ? "text" : "password"}`}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.password && formik?.touched?.password
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        name="password"
                        onBlur={formik?.handleBlur}
                        onKeyDown={(e) => {
                          // Prevent space input
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div
                        className="absolute right-4 top-[40px] cursor-pointer"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Icon
                          icon={
                            showPassword
                              ? "ic:baseline-visibility"
                              : "ic:baseline-visibility-off"
                          }
                          fontSize={28}
                        />
                      </div>
                      {formik?.errors?.password && formik?.touched?.password ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.password}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center flex-row gap-[10px]">
                    <button
                      type="button"
                      onClick={() => setAddViewerModal(false)}
                      className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={`bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px] 
                      ${loading ? "bg-[rgba(142,94,249,.7)]" : ""}`}
                    >
                      {!loading ? (
                        "Submit"
                      ) : (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={30}
                        />
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          }
        />
      )}
      {/* update vendor modal */}
      {updateVendorModal && (
        <Modal
          updateVendorModal={updateVendorModal}
          title="Update Viewer Details"
          closeIcon={() => {
            setUpdateVendorModal(false);
          }}
          descriptionText={
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={updateVendorSchema}
                onSubmit={(values) => {
                  if (!values || typeof values !== "object") {
                    return;
                  }
                  let changed = {};
                  Object.keys(values).forEach((ind) => {
                    const i = values[ind];
                    if (i !== initialValues?.[ind]) {
                      changed = {
                        ...changed,
                        [ind]: i,
                      };
                    }
                  });
                  console.log(changed, "change viewer");
                  updateVendorDetails(changed, limit, page, true);
                }}
              >
                {(formik) => (
                  <form
                    onSubmit={formik?.handleSubmit}
                    className="mainDiv grid grid-cols-1 lg:grid-cols-1 gap-2 my-10 !mb-0"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Name<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Name"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.name && formik?.touched?.name
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="name"
                          onBlur={formik?.handleBlur}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^\s/.test(inputValue)) {
                              // e.preventDefault();
                              return;
                            }
                            if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                              formik.setFieldValue("name", inputValue);
                            }
                          }}
                        />
                        {formik?.errors?.name && formik?.touched?.name ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Email Address<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Email Address"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.email && formik?.touched?.email
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="email"
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.errors?.email && formik?.touched?.email ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Phone<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Phone"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.phone && formik?.touched?.phone
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          type="tel" // Use type="tel" for telephone numbers
                          name="phone"
                          onBlur={formik?.handleBlur}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if the input consists of digits and has a length of up to 10 characters
                            if (/^\d{0,10}$/.test(inputValue)) {
                              formik.setFieldValue("phone", inputValue);
                            }
                          }}
                        />
                        {formik?.errors?.phone && formik?.touched?.phone ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center flex-row gap-[10px]">
                      <button
                        type="button"
                        onClick={() => setUpdateVendorModal(false)}
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
        />
      )}
      {/* active inactive vendor modal */}
      {deleteVendorModal ? (
        <Modal
          deleteVendorModal={deleteVendorModal}
          descriptionText={
            <div className="flex items-center justify-center h-[300px]">
              <CircularProgress
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size={30}
              />
            </div>
          }
        />
      ) : null}
      {/* Update password modal */}
      {updatePasswordModal ? (
        <Modal
          updatePasswordModal={updatePasswordModal}
          closeIcon={() => setUpdatePasswordModal(false)}
          title="Update Password"
          descriptionText={
            <>
              <Formik
                initialValues={updatePasswordValue}
                validationSchema={updatePasswordSchema}
                onSubmit={(values) => {
                  updateVendorDetails(values, 5, 1);
                }}
              >
                {(formik) => (
                  <form onSubmit={formik?.handleSubmit}>
                    <div className="grid grid-cols-1 gap-2 mt-12">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Password<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Password"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.password &&
                            formik?.touched?.password
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="password"
                          type={showPassword ? "text" : "password"}
                          onKeyDown={(e) => {
                            // Prevent space input
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onBlur={formik?.handleBlur}
                        />
                        <div
                          className="absolute right-4 top-[40px] cursor-pointer"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Icon
                            icon={
                              showPassword
                                ? "ic:baseline-visibility"
                                : "ic:baseline-visibility-off"
                            }
                            fontSize={28}
                          />
                        </div>
                        {formik?.errors?.password &&
                        formik?.touched?.password ? (
                          <p className="text-[#d50000] text-left mt-2 font-medium">
                            {formik?.errors?.password}
                          </p>
                        ) : null}
                      </div>
                      <button
                        tabIndex="0"
                        className={`flex w-full justify-center items-center border border-solid border-[rgba(41,0,66,1)] transition-all duration-300 ease-in-out rounded-md text-base text-white font-medium h-[50px] py-2 px-0 lg:px-[124px] ${
                          loading
                            ? "bg-[rgba(142,94,249,.7)]"
                            : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[17px]"
                        }`}
                        type="submit"
                      >
                        {!loading ? (
                          "Change Password"
                        ) : (
                          <CircularProgress
                            style={{
                              color: "#fff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            size={30}
                          />
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
        />
      ) : null}
      {confirmationModal == true ? (
        <Modal
          confirmationModal={true}
          title={`Are you sure you want to ${
            rowData.status == true ? "Inactive" : "Active"
          } this viewer?`}
          descriptionText={
            <>
              <div className="flex items-center justify-center mt-[30px] lg:flex-row gap-[10px]">
                <button
                  type="button"
                  onClick={() => setConfirmationModal(false)}
                  className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-[120px] text-[#fff] h-[50px]"
                >
                  No
                </button>
                <button
                  type="submit"
                  onClick={async () => {
                    await deleteVendor(rowData?._id, !rowData?.status, true);
                  }}
                  className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-[120px] text-[#fff] h-[50px]"
                >
                  Yes
                </button>
              </div>
            </>
          }
          closeIcon={() => {
            setConfirmationModal(true);
          }}
        />
      ) : null}
    </>
  );
};

export default Viewer;
