import { createContext, useContext, useState } from "react";
import { DashboardService } from "../services/dashboardService";
import { firstDayOfMonth, formatDate, lastDayOfMonth } from "../utils/utils";
import toast from "react-hot-toast";

const VendorContext = createContext();

export const VendorContextProvider = ({ children }) => {
  // loading state
  const [loading, setLoading] = useState(false);
  // monthly payment status count states
  const [monthlyCapturedCount, setMonthlyCapturedCount] = useState(0);
  const [monthlyInitiateCount, setMonthlyInitiateCount] = useState(0);
  const [monthlyFailedCount, setMonthlyFailedCount] = useState(0);
  const [monthlyRefundedCount, setMonthlyRefundedCount] = useState(0);
  const [monthlyFailedPayments, setMonthlyFailedPayments] = useState(0);
  // daily payment status count states
  const [dailyCapturedCount, setDailyCapturedCount] = useState(0);
  const [dailyInitiateCount, setDailyInitiateCount] = useState(0);
  const [dailyFailedCount, setDailyFailedCount] = useState(0);
  const [dailyRefundedCount, setDailyRefundedCount] = useState(0);
  const [dailyFailedPayments, setDailyFailedPayments] = useState(0);
  // daily captured payment total
  const [dailyCapturedPayment, setDailyCapturedPayment] = useState(0);
  const [dailyRefunedPayment, setDailyRefunedPayment] = useState(0);
  // monthly captured payment total
  const [monthlyCapturedPayment, setMonthlyCapturedPayment] = useState(0);
  const [monthlyRefundedPayment, setMonthlyRefundedPayments] = useState(0);
  // machine enable and disable :
  const [totalMachines, setTotalMachines] = useState(0);
  const [enabledMachines, setEnabledMachines] = useState(0);
  const [disabledMachines, setDisabledMachines] = useState(0);
  // machine orders:
  const [monthlyData, setMonthlyData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [todayOrders, setTodayOrders] = useState(0);
  const [monthlyOrders, setMonthlyOrders] = useState(0);
  // dates
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);

  // vendor details:
  const getVendorDetails = async (userId, startedDate, endedDate) => {
    // Check if startDate and endDate are provided
    if (!startDate || !endDate) {
      toast.error("Start and end dates are required");
      return; // Exit function if dates are not provided
    } else if (!startDate) {
      toast.error("Start date is required");
    } else if (!startDate) {
      toast.error("End date is required");
    } else if (endDate < startDate) {
      toast.error("End date must be greater than or equal to start date");
    } else if (startDate > endDate) {
      toast.error("Start date must be less than or equal to end date");
    }

    try {
      setLoading(true);
      const getVendorData = await DashboardService.getVendorDetails({
        userId: userId,
        startDate: formatDate(startedDate, "yyyy-mm-dd"),
        endDate: formatDate(endedDate, "yyyy-mm-dd"),
      });

      const { data } = getVendorData;

      if (data.status === 200 && data?.success !== false) {
        setTodayOrders(data?.todayOrders);
        setLoading(false);
        setTodayData(data?.todayPayment);
        setMonthlyData(data?.monthlyPaymentAndOrderCount);

        // processing monthly payment and order count data
        setMonthlyOrders(data?.monthlyPaymentAndOrderCount?.totalOrders);

        let monthlyCapturedPaymentCount = 0;
        let monthlyFailedPaymentCount = 0;
        let monthlyInitiatePaymentCount = 0;
        let monthlyRefundedPaymentCount = 0;
        let monthlyCapturedPayments = 0;
        let monthlyRefundedPaymentsTotal = 0;
        let monthlyFailedPayment = 0;
        if (
          data?.monthlyPaymentAndOrderCount?.result != null &&
          data?.monthlyPaymentAndOrderCount?.result?.length > 0
        ) {
          data?.monthlyPaymentAndOrderCount?.result?.forEach((payment) => {
            switch (payment.status) {
              case "initiate":
                monthlyInitiatePaymentCount += payment.count;
                break;
              case "captured":
                monthlyCapturedPaymentCount += payment.count;
                monthlyCapturedPayments = payment?.totalAmount / 100;
                console.log(
                  "🚀 ~ data?.monthlyPaymentAndOrderCount?.result?.forEach ~ monthlyCapturedPayments:",
                  payment.totalAmount / 100
                );
                break;
              case "failed":
                monthlyFailedPaymentCount += payment.count;
                monthlyFailedPayment = payment.totalAmount / 100;
                break;
              case "refund":
                monthlyRefundedPaymentCount += payment.count;
                monthlyRefundedPaymentsTotal = payment.totalAmount / 100;
                break;
              default:
                break;
            }
          });
          setMonthlyCapturedCount(monthlyCapturedPaymentCount);
          setMonthlyFailedCount(monthlyFailedPaymentCount);
          setMonthlyFailedPayments(monthlyFailedPayment);
          setMonthlyInitiateCount(monthlyInitiatePaymentCount);
          setMonthlyCapturedPayment(monthlyCapturedPayments);
          setMonthlyRefundedCount(monthlyRefundedPaymentCount);
          setMonthlyRefundedPayments(monthlyRefundedPaymentsTotal);
        } else {
          setMonthlyCapturedCount(0);
          setMonthlyFailedCount(0);
          setMonthlyInitiateCount(0);
          setMonthlyCapturedPayment(0);
          setMonthlyRefundedCount(0);
          setMonthlyFailedPayments(0);
          setMonthlyRefundedPayments(0);
        }

        // processing daily payment data
        let dailyCapturedPaymentCount = 0;
        let dailyFailedPaymentCount = 0;
        let dailyInitiatePaymentCount = 0;
        let dailyCapturedPayments = 0;
        let dailyRefundedPaymentsCount = 0;
        let dailyRefundedPaymentsTotal = 0;
        let dailyFailedPayment = 0;
        if (data?.todayPayment != null && data?.todayPayment.length > 0) {
          data.todayPayment.forEach((payment) => {
            if (payment.status === "captured") {
              dailyCapturedPaymentCount += payment.count;
              dailyCapturedPayments = payment?.totalAmount
                ? payment?.totalAmount / 100
                : 0;
            } else if (payment.status === "initiate") {
              dailyInitiatePaymentCount += payment.count;
            } else if (payment.status === "failed") {
              dailyFailedPaymentCount += payment.count;
              dailyFailedPayment = payment?.totalAmount / 100;
            } else if (payment.status === "refund") {
              dailyRefundedPaymentsCount += payment.count;
              dailyRefundedPaymentsTotal = payment?.totalAmount / 100;
            }
          });
          setDailyCapturedCount(dailyCapturedPaymentCount);
          setDailyFailedPayments(dailyFailedPayment);
          setDailyFailedCount(dailyFailedPaymentCount);
          setDailyInitiateCount(dailyInitiatePaymentCount);
          setDailyCapturedPayment(dailyCapturedPayments);
          setDailyRefundedCount(dailyRefundedPaymentsCount);
          setDailyRefunedPayment(dailyRefundedPaymentsTotal);
        } else {
          setDailyCapturedCount(0);
          setDailyInitiateCount(0);
          setDailyFailedCount(0);
          setDailyCapturedPayment(0);
          setDailyFailedPayments(0);
          setDailyRefundedCount(0);
          setDailyRefunedPayment(0);
        }

        // processing machine data
        let enabledMachine = 0;
        let disabledMachine = 0;
        if (data?.machine) {
          data?.machine.forEach((machine) => {
            const machineStatus = machine.status;

            if (
              machineStatus === "Enable" ||
              machineStatus === true ||
              machineStatus === "Enabled"
            ) {
              enabledMachine += machine.count;
            } else if (
              machineStatus === "Disabled" ||
              machineStatus === false ||
              machineStatus === "false" ||
              machineStatus === null
            ) {
              disabledMachine += machine.count;
            }
          });
          setEnabledMachines(enabledMachine);
          setDisabledMachines(disabledMachine);
          setTotalMachines(enabledMachine + disabledMachine);
        } else {
          setEnabledMachines(0);
          setDisabledMachines(0);
          setTotalMachines(0);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      setLoading(false);
    }
  };

  return (
    <VendorContext.Provider
      value={{
        getVendorDetails,
        loading,
        monthlyCapturedCount,
        monthlyInitiateCount,
        monthlyFailedCount,
        dailyCapturedCount,
        dailyFailedCount,
        dailyInitiateCount,
        dailyCapturedPayment,
        monthlyCapturedPayment,
        totalMachines,
        enabledMachines,
        disabledMachines,
        todayOrders,
        monthlyOrders,
        setStartDate,
        startDate,
        endDate,
        setEndDate,
        dailyRefundedCount,
        monthlyRefundedCount,
        dailyRefunedPayment,
        monthlyRefundedPayment,
        todayData,
        monthlyData,
        dailyFailedPayments,
        monthlyFailedPayments,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export const useVendorContext = () => {
  const context = useContext(VendorContext);
  return context;
};
