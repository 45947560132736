import * as Yup from "yup";
import { PHONE_REGEX } from "../utils/utils";

// add vendor
export const addVendorSchema = Yup.object({
  vendorName: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email must a valid email.")
    .required("Email is required"),
  phone: Yup.string()
    .matches(PHONE_REGEX, "Please enter valid phone number.")
    .max(10, "Phone Number must be 10 digits")
    .required("Phone Number is required"),
  password: Yup.string().required("Password is required"),
});
// add vendor
export const addViewerSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email must a valid email.")
    .required("Email is required"),
  phone: Yup.string()
    .matches(PHONE_REGEX, "Please enter valid phone number.")
    .max(10, "Phone Number must be 10 digits")
    .required("Phone Number is required"),
  password: Yup.string().required("Password is required"),
});
// update vendor
export const updateVendorSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email must a valid email.")
    .required("Email is required"),
  phone: Yup.string()
    .matches(PHONE_REGEX, "Please enter valid phone number.")
    .max(10, "Phone Number must be 10 digits")
    .required("Please enter phone number."),
});
// add machine
export const addMachineSchema = Yup.object({
  location: Yup.string()
    .max(50, "Location must be upto 50 characters")
    .required("Location is required"),
  price: Yup.number()
    .typeError("Please enter digits only")
    .required("Price is required"),
  runDuration: Yup.number()
    .typeError("Please enter digits only")
    .required("Duration is required")
    .min(1, "Duration must be greater than 0"),
  commission: Yup.number()
    .typeError("Please enter digits only")
    .required("Commission is required")
    .test(
      "is-valid-commission",
      "Commission must be less than the price",
      function (value) {
        const price = this.parent.price;
        return value < price;
      }
    ),
  imeiNumber: Yup.string()
    .required("IMEI number is required")
    .test(
      "is-fifteen-digits",
      "IMEI number must be exactly 15 digits",
      (value) => value && value.length === 15
    ),
    epos: Yup.string(),
    cfTermial_id: Yup.string(),
});

// update machine
export const updateMachineSchema = Yup.object({
  location: Yup.string()
    .max(50, "Location must be upto 50 characters")
    .required("Location is required"),
  rateOfMachine: Yup.number()
    .typeError("Please enter digits only")
    .required("Price is required"),
  machineImei: Yup.string()
    .required("IMEI number is required")
    .test(
      "is-fifteen-digits",
      "IMEI number must be exactly 15 digits",
      (value) => value && value.length === 15
    ),
  runDuration: Yup.number()
    .required("Time is required")
    .min(1, "Duration must be greater than 0"),
});

// login user
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});
// customer sign in
export const customerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(PHONE_REGEX, "Please enter valid phone number.")
    .max(10, "Phone Number must be 10 digits")
    .required("Please enter phone number."),
});
// allot machine
export const allotMachineSchema = Yup.object().shape({
  allotMachine: Yup.object().required("Please select an option"),
});
// update password schema
export const updatePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});
