import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthContext } from "../../context/AuthContext";
import "../SuperAdmin/Vendor/AddVendors/Vendor.css";
import { CircularProgress } from "@mui/material";
import { Field, Formik, Form } from "formik";
import { loginSchema } from "../../schema/Schema";
import { initialValuesLogin } from "../../utils/formikInitialValues";
import { Icon } from "@iconify/react";
import Footer from "../shared/Footer";
const Login = () => {
  const { handleLogin, loading } = useAuthContext();
  const [show, setShow] = useState(false);

  return (
    <>
      <section className="sectionLogin bg-[#f6f9ff] register min-h-screen d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <div className="logo select-none d-flex align-items-center w-auto">
                  <span className="text-[#8E5EF9] text-[22px] !font-bold md:text-[36px] md:leading-10">
                    Massage 99
                  </span>
                </div>
              </div>

              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Login</h5>
                    <p className="text-center small">
                      Enter your email &amp; password to login
                    </p>
                  </div>
                  <Formik
                    initialValues={initialValuesLogin}
                    validationSchema={loginSchema}
                    onSubmit={(values) => {
                      handleLogin(values);
                    }}
                  >
                    {(formik) => (
                      <Form
                        className="row g-3 needs-validation"
                        onSubmit={formik?.handleSubmit}
                      >
                        <div className="email form-group mt-3">
                          <label className="!text-[#333] !text-base !font-normal !select-none">
                            Email Address<span>*</span>
                          </label>
                          <Field
                            placeholder="Email Address"
                            name="email"
                            className={`!border !border-[#DCDCDC] !select-none !rounded-[4px] !shadow-none !filter-none !text-base ${
                              formik?.errors?.email && formik?.touched?.email
                                ? "!border-[#d50000] !border"
                                : ""
                            }`}
                            onBlur={formik?.handleBlur}
                            value={formik?.values?.email}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(formik?.values);
                              }
                            }}
                          />
                          {formik?.errors?.email && formik?.touched?.email ? (
                            <p className="text-[#d50000] mt-2 font-medium">
                              {formik?.errors?.email}
                            </p>
                          ) : null}
                        </div>
                        <div className="email form-group mt-2">
                          <label className="!text-[#333] !select-none !text-base !font-normal">
                            Password<span>*</span>
                          </label>
                          <Field
                            name="password"
                            placeholder="Password"
                            type={show ? "text" : "password"}
                            className={`!border !select-none relative !border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                              formik?.errors?.password &&
                              formik?.touched?.password
                                ? "!border-[#d50000] !border"
                                : ""
                            }`}
                            onBlur={formik?.handleBlur}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(formik?.values);
                              }
                            }}
                            value={formik?.values?.password}
                          />
                          <div
                            className="absolute right-4 top-[40px] cursor-pointer"
                            onClick={() => {
                              setShow(!show);
                            }}
                          >
                            <Icon
                              icon={
                                show
                                  ? "ic:baseline-visibility"
                                  : "ic:baseline-visibility-off"
                              }
                              fontSize={28}
                            />
                          </div>
                          {formik?.errors?.password &&
                          formik?.touched?.password ? (
                            <p className="text-[#d50000] mt-2 font-medium">
                              {formik?.errors?.password}
                            </p>
                          ) : null}
                        </div>
                        <button
                          type="submit"
                          tabIndex="0"
                          disabled={loading}
                          className={`flex loginButton gap-1 !select-none justify-center items-center border border-solid border-[#45267e] transition-all duration-300 ease-in-out rounded-md text-base text-white font-bold h-[50px] w-full ${
                            loading
                              ? "bg-[rgba(142,94,249,0.7)]"
                              : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[#fff] hover:!border-[#8E5EF9] transition-all hover:!text-[#8E5EF9] duration-300 ease-in-out w-full text-white py-[17px]"
                          }`}
                        >
                          {!loading ? (
                            <LogoutIcon size={28} className="logoutIcon" />
                          ) : (
                            <CircularProgress
                              className="!text-white loaderIcon"
                              size={28}
                            />
                          )}
                          Login
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[60px]">
          <hr />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Login;
