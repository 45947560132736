import { createContext, useContext, useState } from 'react';
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const openLogoutModal = () => {
    setOpenModal(true);
  };
  return (
    <ModalContext.Provider value={{ openModal, setOpenModal, openLogoutModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  return context;
};
