import axios from "axios";
import httpService from "./httpService";

export const MachineService = {
  createMachine: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("create", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getMachines: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("getMachine", params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getAllMachines: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("getAllMachine", params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  updateMachine: (updatedFields, machineId) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.put("updateMachine", updatedFields, {
          params: {
            _id: machineId,
          },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  deleteMachine: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.delete("getMachine", params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getMachineDetailsById: (machineId) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("usersMachine", {
          params: { machineId },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  createOrder: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("createOrder", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  createOrderPhonePe: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("phonePayOrder", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getOrder: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("machineOrder", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getVendorAndMachineOrder: ({ userId, machineImei, pageSize, page }) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("vendorAndMachineOrder", {
          params: {
            userId,
            machineImei,
            pageSize,
            page,
          },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getAllOrders: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("getAllOrders", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getAllOrdersByCollection: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("collectionDetail", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  getAllOrdersByUserId: (userId, pageSize, page) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get(
          `vendorOrders?userId=${userId}&pageSize=${pageSize}&page=${page}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  availableMachines: (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.get("getAllMachine", data);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  allotMachinesUser: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("allotMachine", null, {
          params,
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  reAllotMachinesUser: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await httpService.post("allotMachine", null, {
          params,
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
  orderDetailsBetweenDates: async (params) => {
    try {
      const response = await httpService.post("orderDetailBetweenDates", null, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getMachineDetailsByImeiNumber: (params) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}getMachineDetailByImei`,
          params
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }),
};
