import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
const PaymentFailure = () => {
  const payment_failed = JSON.parse(localStorage.getItem("payment_failed"));
  const { order_id, payment_id } = payment_failed;
  const navigate = useNavigate();
  const chairId = JSON.parse(localStorage.getItem("chairId"));
  return (
    <div className="flex justify-center items-center h-screen bg-white">
      <div className="thanku-page-inner w-[95%] lg:w-auto flex justify-center z-20 flex-col items-center gap-4 lg;gap-10  shadow-lg p-[40px] lg:p-[100px] rounded-[10px] bg-[#f22c3d]">
        <Icon
          icon="radix-icons:cross-circled"
          className="hidden lg:block"
          fontSize={100}
          color="#fff"
        />
        <Icon
          icon="radix-icons:cross-circled"
          className="block lg:hidden"
          fontSize={80}
          color="#fff"
        />
        <h4 className="text-2xl text-center font-bold text-white">
          Your payment has been failed.
        </h4>
        <div className="failed_details flex flex-col items-center text-white">
          <div className="order_id flex gap-2">
            <h4>Order Id:</h4>
            <p>{order_id}</p>
          </div>
          <div className="payment_id flex gap-2">
            <h4>Payment Id:</h4>
            <p>{payment_id}</p>
          </div>
        </div>
        <button
          tabIndex={0}
          className="try_again bg-white text-black px-7 py-2 mt-4 z-20 rounded-md uppercase"
          onClick={() => {
            navigate(`/chairPayment?ChairId=${chairId}`);
          }}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default PaymentFailure;
