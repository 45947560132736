import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import DataTable from "react-data-table-component";
import Navbar from "../../Navbar/Navbar";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import Modal from "../../shared/Modal";
import { Field, Formik, Form } from "formik";
import {
  addVendorSchema,
  updatePasswordSchema,
  updateVendorSchema,
} from "../../../schema/Schema";
import { _ } from "lodash";
import { Icon } from "@iconify/react";
import {
  initialValuesVendor,
  updatePasswordValue,
} from "../../../utils/formikInitialValues";
import Sidebar, { SidebarItem } from "../../shared/Sidebar";
import { useToggleContext } from "../../../context/ToggleContext";
import {
  customStyles,
  customStylesViewer,
  jwtToken,
  role,
  vendorId,
  vendorName,
} from "../../../utils/utils";
import { Armchair, IndianRupee, LayoutDashboard, Users } from "lucide-react";
const Dashboard = () => {
  const storedLimit = localStorage.getItem("limit");
  const [limit, setLimit] = useState(storedLimit || 40);
  const [page, setPage] = useState(1);
  const [existingData, setExistingData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  // using toggle context to toggle the sidebar:
  const { show } = useToggleContext();
  // use of navigate hook:
  const navigate = useNavigate();
  // using auth context here:
  const {
    getVendorsList,
    vendors,
    loading,
    setLoading,
    deleteVendor,
    setVendorId,
    updateVendorDetails,
    updateVendorModal,
    buttonLoading,
    pageDetails,
    setUpdateVendorModal,
    confirmationModal,
    setConfirmationModal,
    addVendorModal,
    setAddVendorModal,
    updatePasswordModal,
    setUpdatePasswordModal,
    addVendor,
  } = useAuthContext();
  // using formik initialvalues for existing data of vendor:
  const initialValues = {
    name: existingData?.name || "",
    email: existingData?.email || "",
    phone: existingData?.phone || "",
  };
  // getting the jwt token from the localstorage:
  const jwt = JSON.parse(localStorage.getItem(jwtToken));
  // getting the user type from localstorage :
  const user = JSON.parse(localStorage.getItem(role));
  // all admins list mapping through api :
  const vendorsList = vendors && vendors?.map((item) => item);

  useEffect(() => {
    localStorage.setItem("limit", 40);
  }, []);
  useEffect(() => {
    // setLoading(true);
    if (jwt) {
      if (user === "admin" || user === "viewer") {
        fetchData();
      }
    }
  }, [limit, page, jwt, user]); // Include all dependencies

  const fetchData = (searchKey = "", searchTerm = "") => {
    getVendorsList(limit, page, searchTerm, searchKey).finally(() =>
      setLoading(false)
    );
  };

  const columns = [
    {
      name: "Name",
      minWidth: "200px",
      selector: (row, index) => (
        <p
          className="text-xs 2xl:text-sm"
          onClick={() => {
            // if (user === "admin") {
            navigate(`/vendor-details/${row?.id}`);
            localStorage.setItem(vendorName, row?.name);
            localStorage.setItem(vendorId, JSON.stringify(row?.id));
            // }
          }}
        >
          {row?.name}
        </p>
      ),
    },
    {
      name: "Email",
      minWidth: "180px",
      selector: (row, index) => (
        <Tooltip
          title={<p className="text-xs 2xl:text-base">{row?.email}</p>}
          placement="bottom"
        >
          <p
            className="text-xs 2xl:text-sm"
            onClick={() => {
              // if (user === "admin") {
              navigate(`/vendor-details/${row?.id}`);
              localStorage.setItem(vendorName, row?.name);
              localStorage.setItem(vendorId, JSON.stringify(row?.id));
              // }
            }}
          >
            {row?.email.length > 15
              ? row?.email.substring(0, 15) + "..."
              : row?.email}
          </p>
        </Tooltip>
      ),
    },
    {
      name: "Phone",
      selector: (row, index) =>
        row?.phone ? (
          <p
            className="text-xs 2xl:text-sm"
            onClick={() => {
              // if (user === "admin") {
              navigate(`/vendor-details/${row?.id}`);
              localStorage.setItem(vendorName, row?.name);
              localStorage.setItem(vendorId, JSON.stringify(row?.id));
              // }
            }}
          >
            {row?.phone}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "Update Vendor",
      selector: (row, index) => (
        <div
          className="bg-[#8E5EF9] text-white hover:bg-[#8E5EF9] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdateVendorModal(true);
            setExistingData(row);
            setVendorId(row?.id);
          }}
        >
          <Tooltip
            placement="left"
            title={<span className="text-base">Edit Vendor</span>}
          >
            <Icon icon="mdi:pencil" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "View Orders",
      selector: (row, index) => (
        <Link
          to={`/${row?.id}/orders`}
          onClick={() => {
            localStorage.setItem(vendorName, row?.name);
            localStorage.removeItem("imeiMachine");
            localStorage.setItem(vendorId, JSON.stringify(row?.id));
          }}
          className="bg-[#4992FF] text-white hover:bg-[#4992FF] transition-all duration-300 ease-in-out flex justify-center items-center text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
        >
          <Tooltip
            placement="right"
            title={
              <span className="text-base">
                Click here to view {row?.name}'s all orders
              </span>
            }
          >
            <Icon icon="mdi:eye" fontSize={18} />
          </Tooltip>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        user !== "viewer" ? (
          <Tooltip
            title={
              <div className="!text-base">
                {row?.status === false
                  ? "Click to activate"
                  : "Click to deactivate"}
              </div>
            }
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onClick={async () => {
                      if (user === "admin") {
                        setConfirmationModal(true);
                        setRowData(row);
                      }
                    }}
                    checked={row?.status}
                  />
                }
              />
            </FormGroup>
          </Tooltip>
        ) : (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onClick={async () => {
                    if (user === "admin") {
                      setConfirmationModal(true);
                      setRowData(row);
                    }
                  }}
                  disabled={user === "viewer"}
                  checked={row?.status}
                />
              }
            />
          </FormGroup>
        ),
    },
    {
      name: "Update Password",
      selector: (row) => (
        <div
          className="bg-[#d50000] text-white hover:bg-[#d50000] transition-all duration-300 ease-in-out flex justify-center items-center cursor-pointer text-base uppercase rounded-md p-[7px] 2xl:p-[10px]"
          onClick={() => {
            setUpdatePasswordModal(true);
            setVendorId(row?.id);
          }}
        >
          <Tooltip
            placement="left"
            title={
              <span className="text-base">
                Click here to update {row?.name}'s password
              </span>
            }
          >
            <Icon icon="mdi:password" fontSize={18} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const filteredColumns =
    user === "admin"
      ? columns // Include all columns for admin
      : columns.filter(
          (column) =>
            column.name != "Update Vendor" && column.name != "Update Password"
        ); // Exclude 'Update Machine' and 'De Allot' for non-admin

  return (
    <>
      <Navbar />
      <div className="flex w-full">
        <Sidebar>
          <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" />
          <SidebarItem icon={<Users size={20} />} active text="Vendors" />
          {user === "admin" && (
            <SidebarItem icon={<Users size={20} />} text="Viewers" />
          )}
          <SidebarItem icon={<Armchair size={20} />} text="Machines" />
          <SidebarItem icon={<IndianRupee size={20} />} text="Orders" />
        </Sidebar>
        <div
          className={`dashboard-main w-full h-[calc(100vh-5px)] overflow-auto admin-dashboard-main transition-all duration-300 ease-in-out py-[100px] 2xl:py-[100px] px-10 !ml-0 ${
            show
              ? "pl-[25px] pr-[25px] lg:!pl-[310px]"
              : "pl-[25px] pr-[25px] lg:!pl-[130px]"
          }`}
        >
          <div className="dashboard-inner">
            <div className="top-section flex items-center justify-between w-full">
              <h4 className="font-semibold text-lg">
                <div className="!text-[#242426] text-[30px] leading-10 font-[600] flex items-center gap-1 !m-0 text-xl">
                  Vendors
                </div>
              </h4>
              {user == "admin" && (
                <div className="search-main gap-4 header-right">
                  <div className="flex gap-2 items-center justify-center">
                    <div
                      className="send-images !cursor-pointer rounded-[7px] px-[15px] 2xl:!px-[30px] py-[10px] 2xl:!py-[14px] !bg-[#8E5EF9] !border-[#8E5EF9] hover:!text-white hover:border-[#8E5EF9] text-white hover:!bg-[rgba(142,94,249,.7)] uppercase qr-code flex items-center gap-[10px]"
                      onClick={() => {
                        setAddVendorModal(true);
                      }}
                    >
                      {/* <Icon icon="mdi:plus" fontSize={25} /> */}
                      <p className="text-sm 2xl:text-base cursor-pointer font-medium">
                        Add Vendor
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="admin-lists orderListing">
              <DataTable
                noHeader
                className={`events-lists-table ${
                  user === "viewer" && "viewerTable"
                }`}
                data={vendorsList}
                columns={filteredColumns}
                striped={true}
                pagination
                paginationServer
                fixedHeader
                highlightOnHover
                responsive
                noDataComponent={<h4>No Vendor found</h4>}
                paginationRowsPerPageOptions={[10, 20, 40, 80, 100]}
                paginationTotalRows={pageDetails?.noOfRecords}
                paginationPerPage={limit}
                onRowClicked={(row) => {
                  navigate(`/vendor-details/${row?.id}`);
                  localStorage.setItem(vendorName, row?.name);
                  localStorage.setItem(vendorId, JSON.stringify(row?.id));
                }}
                onChangeRowsPerPage={(perPage) => {
                  localStorage.setItem("limit", perPage);
                  setLimit(perPage);
                  window.scroll(0, 0);
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                progressPending={loading}
                customStyles={customStyles}
                progressComponent={
                  <CircularProgress
                    size={42}
                    style={{
                      color: "rgba(41,0,66,1)",
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* add vendor modal */}
      {addVendorModal && (
        <Modal
          title="Add Vendor"
          addVendorModal={addVendorModal}
          closeIcon={() => {
            setAddVendorModal(false);
          }}
          descriptionText={
            <Formik
              initialValues={initialValuesVendor}
              validationSchema={addVendorSchema}
              onSubmit={(values) => {
                addVendor(values, false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik?.handleSubmit}>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Name<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Name"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.vendorName &&
                          formik?.touched?.vendorName
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        name="vendorName"
                        onBlur={formik?.handleBlur}
                        onChange={(e) => {
                          var inputValue = e.target.value;
                          if (/^\s/.test(inputValue)) {
                            return;
                          }
                          if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                            formik.setFieldValue("vendorName", inputValue);
                          }
                        }}
                      />
                      {formik?.errors?.vendorName &&
                      formik?.touched?.vendorName ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.vendorName}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Email Address<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Email Address"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.email && formik?.touched?.email
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        name="email"
                        onBlur={formik?.handleBlur}
                        onChange={(e) => {
                          var inputValue = e.target.value;
                          if (/^\s/.test(inputValue)) {
                          } else {
                            formik.setFieldValue("email", inputValue);
                          }
                        }}
                      />
                      {formik?.errors?.email && formik?.touched?.email ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.email}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Phone<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Phone"
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.phone && formik?.touched?.phone
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        type="tel" // Use type="tel" for telephone numbers
                        name="phone"
                        onBlur={formik?.handleBlur}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Check if the input consists of digits and has a length of up to 10 characters
                          if (/^\d{0,10}$/.test(inputValue)) {
                            formik.setFieldValue("phone", inputValue);
                          }
                        }}
                      />

                      {formik?.errors?.phone && formik?.touched?.phone ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.phone}
                        </p>
                      ) : null}
                    </div>
                    <div className="email form-group">
                      <label className="!text-[#333] !text-base !font-normal">
                        Password<span className="text-[#d50000]">*</span>
                      </label>
                      <Field
                        placeholder="Password"
                        type={`${showPassword ? "text" : "password"}`}
                        className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                          formik?.errors?.password && formik?.touched?.password
                            ? "!border-[#d50000] !border-2"
                            : ""
                        }`}
                        data-ng-pattern="/^\S*$/"
                        name="password"
                        onBlur={formik?.handleBlur}
                        onKeyDown={(e) => {
                          // Prevent space input
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          var inputValue = e.target.value;
                          if (/^\s/.test(inputValue)) {
                          } else {
                            formik.setFieldValue("password", inputValue);
                          }
                        }}
                      />
                      <div
                        className="absolute right-4 top-[40px] cursor-pointer"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Icon
                          icon={
                            showPassword
                              ? "ic:baseline-visibility"
                              : "ic:baseline-visibility-off"
                          }
                          fontSize={28}
                        />
                      </div>
                      {formik?.errors?.password && formik?.touched?.password ? (
                        <p className="text-[#d50000] mt-2 font-medium text-left">
                          {formik?.errors?.password}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center flex-row gap-[10px]">
                    <button
                      type="button"
                      onClick={() => setAddVendorModal(false)}
                      className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={`bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px] 
                      ${loading ? "bg-[rgba(142,94,249,.7)]" : ""}`}
                    >
                      {!loading ? (
                        "Submit"
                      ) : (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={30}
                        />
                      )}
                    </button>
                    {/* <button
                      tabIndex="0"
                      className={`flex w-full justify-center items-center border border-solid border-[rgba(41,0,66,1)] transition-all duration-300 ease-in-out rounded-md text-base text-white font-medium h-[50px] py-2 px-[124px] ${
                        loading
                          ? "bg-[rgba(142,94,249,.7)]"
                          : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[17px]"
                      }`}
                      type="submit"
                    >
                      {!loading ? (
                        "Register"
                      ) : (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size={30}
                        />
                      )}
                    </button> */}
                  </div>
                </Form>
              )}
            </Formik>
          }
        />
      )}
      {/* update vendor modal */}
      {updateVendorModal && (
        <Modal
          updateVendorModal={updateVendorModal}
          title="Update Vendor Details"
          closeIcon={() => {
            setUpdateVendorModal(false);
          }}
          descriptionText={
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={updateVendorSchema}
                onSubmit={(values) => {
                  if (!values || typeof values !== "object") {
                    return;
                  }
                  let changed = {};
                  Object.keys(values).forEach((ind) => {
                    const i = values[ind];
                    if (i !== initialValues?.[ind]) {
                      changed = {
                        ...changed,
                        [ind]: i,
                      };
                    }
                  });
                  updateVendorDetails(changed, limit, page, false);
                }}
              >
                {(formik) => (
                  <form
                    onSubmit={formik?.handleSubmit}
                    className="mainDiv my-10 !mb-0"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Name<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Name"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.vendorName &&
                            formik?.touched?.vendorName
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="name"
                          onBlur={formik?.handleBlur}
                          onChange={(e) => {
                            var inputValue = e.target.value;
                            if (/^\s/.test(inputValue)) {
                              return;
                            }
                            if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                              formik.setFieldValue("name", inputValue);
                            }
                          }}
                        />
                        {formik?.errors?.name && formik?.touched?.name ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Email Address<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Email Address"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.email && formik?.touched?.email
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="email"
                          onBlur={formik?.handleBlur}
                          onChange={(e) => {
                            var inputValue = e.target.value;
                            if (/^\s/.test(inputValue)) {
                            } else {
                              formik.setFieldValue("email", inputValue);
                            }
                          }}
                        />
                        {formik?.errors?.email && formik?.touched?.email ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Phone<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Phone"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.phone && formik?.touched?.phone
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          type="tel" // Use type="tel" for telephone numbers
                          name="phone"
                          onBlur={formik?.handleBlur}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if the input consists of digits and has a length of up to 10 characters
                            if (/^\d{0,10}$/.test(inputValue)) {
                              formik.setFieldValue("phone", inputValue);
                            }
                          }}
                        />

                        {formik?.errors?.phone && formik?.touched?.phone ? (
                          <p className="text-[#d50000] mt-2 font-medium text-left">
                            {formik?.errors?.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center flex-row gap-[10px]">
                      <button
                        type="button"
                        onClick={() => setUpdateVendorModal(false)}
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-full text-[#fff] py-[10px] lg:py-[17px]"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
        />
      )}

      {/* Update password modal */}
      {updatePasswordModal ? (
        <Modal
          updatePasswordModal={updatePasswordModal}
          closeIcon={() => setUpdatePasswordModal(false)}
          title="Update Password"
          descriptionText={
            <>
              <Formik
                initialValues={updatePasswordValue}
                validationSchema={updatePasswordSchema}
                onSubmit={(values) => {
                  updateVendorDetails(values, 10, 1);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik?.handleSubmit}>
                    <div className="grid grid-cols-1 gap-2 mt-12">
                      <div className="email form-group">
                        <label className="!text-[#333] !text-base !font-normal">
                          Password<span className="text-[#d50000]">*</span>
                        </label>
                        <Field
                          placeholder="Password"
                          className={`border border-[#DCDCDC] !rounded-[4px] !shadow-none !filter-none !text-base ${
                            formik?.errors?.password &&
                            formik?.touched?.password
                              ? "!border-[#d50000] !border-2"
                              : ""
                          }`}
                          name="password"
                          type={showPassword ? "text" : "password"}
                          onBlur={formik?.handleBlur}
                          onKeyDown={(e) => {
                            // Prevent space input
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            var inputValue = e.target.value;
                            if (/^\s/.test(inputValue)) {
                            } else {
                              formik.setFieldValue("password", inputValue);
                            }
                          }}
                        />
                        <div
                          className="absolute right-4 top-[40px] cursor-pointer"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Icon
                            icon={
                              showPassword
                                ? "ic:baseline-visibility"
                                : "ic:baseline-visibility-off"
                            }
                            fontSize={28}
                          />
                        </div>
                        {formik?.errors?.password &&
                        formik?.touched?.password ? (
                          <p className="text-[#d50000] text-left mt-2 font-medium">
                            {formik?.errors?.password}
                          </p>
                        ) : null}
                      </div>
                      <button
                        tabIndex="0"
                        className={`flex w-full justify-center items-center border border-solid border-[rgba(41,0,66,1)] transition-all duration-300 ease-in-out rounded-md text-base text-white font-medium h-[50px] py-2 px-0 lg:px-[124px] ${
                          loading
                            ? "bg-[rgba(142,94,249,.7)]"
                            : "bg-[#8E5EF9] rounded-[4px] border border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] transition-all hover:!text-[#fff] duration-300 ease-in-out w-full text-white py-[17px]"
                        }`}
                        type="submit"
                      >
                        {!loading ? (
                          "Change Password"
                        ) : (
                          <CircularProgress
                            style={{
                              color: "#fff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            size={30}
                          />
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          }
        />
      ) : null}
      {confirmationModal == true ? (
        <Modal
          confirmationModal={true}
          title={`Are you sure you want to ${
            rowData.status == true ? "disable" : "enable"
          } this vendor?`}
          descriptionText={
            <>
              <div className="flex items-center justify-center mt-3 lg:flex-row gap-[10px]">
                <button
                  type="button"
                  onClick={() => setConfirmationModal(false)}
                  className="bg-[#8E5EF9] rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-[100px] text-[#fff] py-[10px]"
                >
                  No
                </button>
                <button
                  type="submit"
                  onClick={async () => {
                    await deleteVendor(rowData?.id, !rowData?.status, false);
                  }}
                  className={`rounded-[4px] border !border-[#8E5EF9] shadow-sm hover:!bg-[rgba(142,94,249,0.6)] hover:!border-[#8E5EF9] hover:!text-white transition-all duration-300 ease-in-out w-[100px] text-[#fff] py-[10px] ${
                    buttonLoading == true
                      ? "!bg-[rgba(142,42,249,0.6)] flex items-center justify-center gap-2"
                      : "bg-[#8E5EF9]"
                  }`}
                >
                  {buttonLoading == true && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  )}{" "}
                  Yes
                </button>
              </div>
            </>
          }
          closeIcon={() => {
            setConfirmationModal(true);
          }}
        />
      ) : null}
    </>
  );
};

export default Dashboard;
