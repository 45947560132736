export const PHONE_REGEX = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

// Function to convert UTC timestamp to IST timestamp
export const UTCtoIST = (utcTimestamp) => {
  const date = new Date(utcTimestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  // formatting the date
  const formattedDate = `${day.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}-${month.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}-${year}`;
  // formatting the time
  const formattedTime = `${hours % 12 || 12}:${minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })} ${ampm}`;
  // combining both date and time for further use
  const istTimeString = `${formattedDate} ${formattedTime}`;
  // return the required value
  return istTimeString;
};
// custom styles for react-select
export const customStyles = {
  rows: {
    style: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f5f5fa !important", // Change the background color on hover
      },
      padding: "5px 0", // Add margin bottom to create a gap between rows
    },
  },
  headCells: {
    style: {
      borderRight: "1px solid #dee2e6", // Add right border for each header cell
      "&:last-child": {
        border: "none !important", // Change the background color on hover
      },
    },
  },
  cells: {
    style: {
      borderRight: "1px solid #dee2e6", // Add right border for each cell
      "&:last-child": {
        border: "none !important", // Change the background color on hover
      },
    },
  },
};

export const customStylesViewer = {
  rows: {
    style: {
      cursor: "default",
      "&:hover": {
        backgroundColor: "transparent !important", // Change the background color on hover
      },
      padding: "5px 0",
    },
  },
  headCells: {
    style: {
      borderRight: "1px solid #dee2e6", // Add right border for each header cell
      "&:last-child": {
        border: "none !important", // Change the background color on hover
      },
    },
  },
  cells: {
    style: {
      borderRight: "1px solid #dee2e6", // Add right border for each cell
      "&:last-child": {
        border: "none !important", // Change the background color on hover
      },
    },
  },
};
// doughnout chart data
export const data = {
  labels: ["Payment Initiate", "Payment Success", "Payment Failure"],
  datasets: [
    {
      data: [12, 1, 20],
      backgroundColor: ["gold", "green", "red"],
      borderColor: ["gold", "green", "red"],
      borderWidth: 1,
    },
  ],
};
// doughnout chart options
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};
// bar chart labels:
const labels = ["January", "February", "March", "April", "May", "June", "July"];
// bar chart data:
export const barData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      data: [1, 2, 2, 3],
    },
    {
      label: "Dataset 2",
      data: [1, 2, 2, 3],
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
// here we defining the constants for localStorage:
export const vendorId = "vendorId";
export const vendorName = "vendor_name";
export const machineLocation = "machineLocation";
export const role = "role";
export const userDetails = "user";
export const jwtToken = "jwt";
export const customWidth = "130px";
export const imeiMachine = "imeiMachine";
export const permission = "permissions";
// custom styles for react-select
export const customStylesSelect = {
  control: (provided, state) => ({
    ...provided,
    height: "50px !important",
    maxHeight: "62px",
    cursor: "pointer",
    boxShadow: "none",
    outline: "none",
    border: "1px solid #D3D3D3",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#8e5ef9 !important"
      : state.isDisabled
      ? "white"
      : "transparent",
    color: state.isSelected
      ? "#fff !important"
      : state.isDisabled
      ? "#cccccc"
      : "black",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isDisabled
        ? "transparent"
        : "#8e5bf9b3 !important", // Change background color on hover
      cursor: state.isDisabled
        ? "not-allowed !important"
        : "pointer !important",
      color: state.isDisabled ? "#cccccc" : "white !important",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#8e5bf9b3 !important", // Change background color on hover
      color: "white !important",
      cursor: "pointer !important",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "14px",
      textAlign: "left",
    };
  },
};
// search terms for vendors
export const searchTermVendors = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone",
    value: "phone",
  },
];
// search terms for orders
export const searchTermOrders = [
  {
    label: "Order Id",
    value: "order_id",
  },
  {
    label: "Payment Id",
    value: "payment_id",
  },
  {
    label: "IMEI",
    value: "machineImei",
  },
  {
    label: "Status",
    value: "status",
  },
];
// function to show the modal on the basis of count of click
export const handleCountClick = (count, setCount, setShow) => {
  setShow(true);
};
// function to close the modal
export const handleClose = (
  setCount,
  setShow,
  setStartedTime,
  setEndedTime,
  setRunningTime
) => {
  setCount(0);
  setShow(false);
  setStartedTime(null);
  setEndedTime(null);
  setRunningTime(null);
};
// Function to calculate the start and end dates for the current month
export const getCurrentMonthDates = () => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Format dates as "YYYY-MM-DD"
  const startDate = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];

  const endDate = new Date(
    endOfMonth.getTime() - endOfMonth.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];

  return { startDate, endDate };
};

const startDate = new Date();
startDate.setDate(1); // Set day to 1st of the month

// Get the last day of the month for the current month
let endDate;
const month = startDate.getMonth();
if (month === 1) {
  // February
  const year = startDate.getFullYear();
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    // Leap year, February has 29 days
    endDate = new Date(year, month, 29);
  } else {
    // Non-leap year, February has 28 days
    endDate = new Date(year, month, 28);
  }
} else if ([0, 2, 4, 6, 7, 9, 11].includes(month)) {
  // Months with 31 days
  endDate = new Date(startDate.getFullYear(), month, 31);
} else {
  // Months with 30 days
  endDate = new Date(startDate.getFullYear(), month, 30);
}

export const formattedStartDate = `${startDate.getFullYear()}-${(
  startDate.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;

// // Format endDate as YYYY-MM-DD
// export const formattedEndDate = `${endDate.getFullYear()}-${(
//   endDate.getMonth() + 1
// )
//   .toString()
//   .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;

// Create a new Date object for today's date
const today = new Date();

// Format today's date as YYYY-MM-DD
export const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1)
  .toString()
  .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

export const formatDate = (date) => {
  console.log(date, "ddd");
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const validateEmailDomain = (email) => {
  // Regular expressions for matching the domain parts of email addresses
  const gmailRegex = /@gmail\.com$/;
  const outlookRegex = /@(outlook|hotmail)\.com$/;
  const yopmailRegex = /@yopmail\.com$/;

  // Check if the email matches any of the specified domain patterns
  if (gmailRegex.test(email)) {
    return "gmail";
  } else if (outlookRegex.test(email)) {
    return "outlook";
  } else if (yopmailRegex.test(email)) {
    return "yopmail";
  } else {
    return "other"; // For domains other than Gmail, Outlook, or Yopmail
  }
};

export const firstDayOfMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  1
);
export const lastDayOfMonth = new Date();

export const filterMonthsOptions = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export function getMonthStartAndEndDates(monthName, year) {
  // Convert month name to its numerical representation (0 for January, 1 for February, etc.)
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthIndex = monthNames.indexOf(monthName);

  // If month name is not valid or year is not provided, return null
  if (monthIndex === -1 || !year) {
    return null;
  }

  // Create a new Date object for the first day of the month
  const startDate = new Date(year, monthIndex, 1);

  // Calculate the last day of the month by setting the date to 0 (last day of previous month)
  const endDate = new Date(year, monthIndex + 1, 0);

  return {
    start: startDate,
    end: endDate,
  };
}
