import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import "./Home.css";
import Loader from "../Loader/Loader";
import { role, userDetails } from "../../utils/utils";

const Home = () => {
  const { loading } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem(userDetails));
    const userRole = JSON.parse(localStorage.getItem(role));

    if (!isLoggedIn) {
      navigate("/login");
    } else {
      if (userRole === "admin" || userRole === "viewer") {
        navigate("/dashboard");
      } else if (userRole === "vendor") {
        navigate(`/dashboard`);
      }
    }
  }, [navigate]);

  return <>{loading ? <Loader /> : null}</>;
};

export default Home;
