import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/authService";
import { toast } from "react-hot-toast";
import { jwtToken, role, userDetails } from "../utils/utils";
// creating an AuthContext using createContext:
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // creating different useStates as per requirements:
  const [data, setData] = useState("");
  const [pageDetails, setPageDetails] = useState([]);
  const [permission, setPermission] = useState("read");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [vendors, setVendors] = useState([]);
  const [user, setUser] = useState("");
  // vendor modal
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [updateVendorModal, setUpdateVendorModal] = useState(false);
  const [deleteVendorModal, setDeleteVendorModal] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  // viewer modal
  const [addViewerModal, setAddViewerModal] = useState(false);
  const [allViewers, setAllViewers] = useState([]);
  // use of useNavigate hook:
  const navigate = useNavigate();
  // getting the userDetails from the localStorage:
  const localUser = JSON.parse(localStorage.getItem("user"));
  // handling the login process here:
  const handleLogin = async (values) => {
    // getting the values of the login from the props (email, password):
    const loginData = { email: values?.email, password: values?.password };
    // when someone clicks login button setting the loading state true
    setLoading(true);
    // using the try catch block to handle the errors properly:
    try {
      // using the created AuthService for the login and sending the payload to that:
      const loginApi = await AuthService.login(loginData);
      // if login api return true or 200 then handling the further process:
      if (loginApi.data.success === true) {
        // setting the loading state to default or false:
        setLoading(false);
        // displaying the toast message as per the response
        toast.success(loginApi.data.message);
        // navigating the end user to the dashboard
        if (loginApi.data.user.roles[0] === "admin") {
          navigate("/dashboard");
        } else if (loginApi.data.user.roles[0] === "vendor") {
          navigate("/dashboard");
          // navigate(`/machine-list/${loginApi?.data?.user?.id}`);
        } else {
          navigate("/dashboard");
        }
        // storing the response data in the state
        setData(loginApi.data.user);
        // storing the end user data from the api to the localStorage for usage:
        // token
        localStorage.setItem(jwtToken, JSON.stringify(loginApi?.data?.token));
        // user details
        localStorage.setItem(userDetails, JSON.stringify(loginApi?.data?.user));
        // role
        localStorage.setItem(
          "role",
          JSON.stringify(loginApi.data.user.roles[0])
        );
      }
      // if login api return false or 400 then handling that:
      else if (loginApi.data.status === false) {
        // setting the loading state to default or false:
        setLoading(false);
        toast.error(loginApi.data.message);
      }
    } catch (error) {
      // if some other errors then setting the loading state to false
      setLoading(false);
    }
  };
  // handling the logout process here:
  const handleLogout = (e) => {
    // preventing the page to stop refreshing itself
    e.preventDefault();
    // removing the all of the data stored in the localStorage:
    localStorage.removeItem(role);
    localStorage.removeItem(userDetails);
    localStorage.removeItem(jwtToken);
    // navigating the user to the login page after logout
    navigator("/login");
  };
  // handling the add Vendor api process here:
  const addVendor = async (values, isViewer) => {
    try {
      // storing the vendor name, email, password, phone, role, permissions in an object (data)
      const data = {
        name: isViewer ? values?.name : values?.vendorName,
        email: values?.email,
        password: values?.password,
        phone: values?.phone,
        roles: isViewer ? ["3"] : ["2"],
      };
      // calling the AuthService for creating a new vendor and passing the data object as payload to it:
      const vendors = await AuthService.createVendor(data);
      // handling if add Vendor api return true or 200 then doing further process:
      if (vendors.data.success === true) {
        // displaying the toast message coming from the api response
        toast.success(vendors.data.message);
        setAddVendorModal(false);
        // refreshing the current page
        window.location.reload();
      } else {
        // If the API response is not successful, handle it here
        // You can display an error message or take other actions as needed
        // toast.error(vendors.data.message);
      }
    } catch (error) {
      // Handle errors here
      if (error.response && error.response.status === 400) {
        // Handle HTTP 400 errors (Bad Request)
        // You can display a specific error message for this case
        // toast.error(error.response.message);
      } else {
        // Handle other types of errors
        // You can display a generic error message or take other actions as needed
        console.error("An error occurred:", error.message);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  // handling the get Vendor list api process here:
  const getVendorsList = async (limit, page) => {
    // calling the AuthService for getting the vendor list:
    const getVendorList = await AuthService.getVendors({
      params: {
        pageSize: limit,
        page: page,
      },
    });
    // if response is there then doing further process:
    if (getVendorList.data.success === true) {
      setLoading(false);
      setDeleteVendorModal(false);
      // setting the data to the state coming from the api response
      setVendors(getVendorList?.data?.data);
      setPageDetails(getVendorList?.data?.pageDetails);
    } else {
      setLoading(false);
      setDeleteVendorModal(false);
      // displaying the error message in the toast coming from the api response
      toast.error(getVendorList?.data?.message);
      // setting the vendor list to [] if there is not data
      setVendors([]);
    }
  };
  // handling the get Vendor list api process here:
  const getViwersList = async (limit, page) => {
    // calling the AuthService for getting the vendor list:
    const getViwerList = await AuthService.getViewers({
      params: {
        pageSize: limit,
        page: page,
      },
    });
    // if response is there then doing further process:
    if (getViwerList.data.success === true) {
      setLoading(false);
      setDeleteVendorModal(false);
      // setting the data to the state coming from the api response
      setAllViewers(getViwerList?.data?.data);
      setPageDetails(getViwerList?.data?.pageDetails);
    } else {
      setLoading(false);
      setDeleteVendorModal(false);
      // displaying the error message in the toast coming from the api response
      toast.error(getViwerList?.data?.message);
      // setting the vendor list to [] if there is not data
      setAllViewers([]);
    }
  };
  // handling the update Vendor details api process here:
  const updateVendorDetails = async (updatedFields, limit, page, isViewer) => {
    setLoading(true);
    // calling the AuthService for update the vendor details and sending the required values and vendorId to the payload:
    const updateDetails = await AuthService.updateVendor(
      updatedFields,
      vendorId
    );
    console.log(updatedFields, "pass");
    // if the api return true or 200 then doing the further process:
    if (updateDetails?.data.success === true) {
      // closing the update vendor modal
      setLoading(false);
      setUpdateVendorModal(false);
      setUpdatePasswordModal(false);
      // displaying the response message in the toast
      toast.success(updateDetails?.data?.message);
      // refreshing the current page
      isViewer ? getViwersList(limit, page) : getVendorsList(limit, page);
    } else {
      setLoading(false);
      // toast.error(updateDetails?.data?.message);
    }
  };
  // delete the vendor:
  const deleteVendor = async (userId, status, isViewer) => {
    // setDeleteVendorModal(true);
    setButtonLoading(true);
    try {
      const isDelete = await AuthService.deleteVendor(userId, status);

      if (isDelete.data.success === true) {
        // setDeleteVendorModal(false);
        setButtonLoading(false);
        setConfirmationModal(false);
        toast.success(isDelete.data.message);
        isViewer ? getViwersList(10, 1) : getVendorsList(10, 1);
      } else if (isDelete.data.success === false) {
        // setDeleteVendorModal(false);
        setConfirmationModal(false);
        setButtonLoading(false);
        toast.error(isDelete?.data?.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setButtonLoading(false);
        setConfirmationModal(false);
        // setDeleteVendorModal(false);
        // Handle the 400 error specifically
      } else {
        // setDeleteVendorModal(false);
        setButtonLoading(false);
        setConfirmationModal(false);
        // Handle other errors
        toast.error("An error occurred while deleting the vendor");
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        handleLogout,
        getVendorsList,
        addVendor,
        loading,
        setLoading,
        handleLogin,
        user,
        data,
        setUser,
        updateVendorDetails,
        localUser,
        deleteVendorModal,
        setDeleteVendorModal,
        permission,
        setPermission,
        vendors,
        vendorId,
        setVendorId,
        updateVendorModal,
        setUpdateVendorModal,
        pageDetails,
        deleteVendor,
        addVendorModal,
        setAddVendorModal,
        updatePasswordModal,
        setUpdatePasswordModal,
        addViewerModal,
        setAddViewerModal,
        getViwersList,
        allViewers,
        confirmationModal,
        setConfirmationModal,
        buttonLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  return context;
};
