import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";
import { MachineProvider } from "./context/MachineContext";
import { ToggleContextProvider } from "./context/ToggleContext";
import { DashboardContextProvider } from "./context/DashboardContext";
import { Toaster } from "react-hot-toast";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Dashboard from "./components/SuperAdmin/Dashboard/Dashboard";
import Home from "./components/Home/Home";
import CommonDashboard from "./components/shared/CommonDashboard";
import MachineList from "./components/SuperAdmin/Machine/MachineList";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import PaymentFailure from "./components/PaymentFailure/PaymentFailure";
import Orders from "./components/SuperAdmin/Machine/Orders/Orders";
import AllMachines from "./components/SuperAdmin/Machine/AllMachines";
import AllOrders from "./components/SuperAdmin/Machine/Orders/AllOrders";
import AllOrdersVendor from "./components/SuperAdmin/Machine/Orders/AllOrdersVendor";
import VendorDetails from "./components/SuperAdmin/Vendor/VendorDetails";
import MachineDetails from "./components/SuperAdmin/Machine/MachineDetails";
import CreateOrderPayment from "./components/SuperAdmin/Machine/CreateOrderPayment";
import { VendorContextProvider } from "./context/VendorDetailsContext";
import { MachineContextProvider } from "./context/MachineDetailsContext";
import Viewer from "./components/SuperAdmin/Viewer/Viewer";
import OrdersByCollection from "./components/SuperAdmin/Machine/Orders/OrdersByCollection";
function App() {
  return (
    <Router>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1500,
          style: {
            maxWidth: "500px",
            minWidth: "auto",
          },
        }}
      />
      <AuthProvider>
        <MachineProvider>
          <ModalProvider>
            <ToggleContextProvider>
              <DashboardContextProvider>
                <VendorContextProvider>
                  <MachineContextProvider>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/login" element={<Login />} />
                      <Route element={<ProtectedRoute />}>
                        <Route
                          path="/dashboard"
                          element={<CommonDashboard />}
                        />
                        <Route path="/viewers" element={<Viewer />} />
                        <Route
                          path="/vendor-details/:id"
                          element={<VendorDetails />}
                        />
                        <Route
                          path="/machine-details/:id"
                          element={<MachineDetails />}
                        />
                        <Route path="/vendor-list" element={<Dashboard />} />
                        <Route
                          path="/machine-list/:id"
                          element={<MachineList />}
                        />
                        <Route
                          path="/machine-list/all"
                          element={<AllMachines />}
                        />
                        <Route
                          path="/:machineImei/orders"
                          element={<Orders />}
                        />
                        <Route path="/:userId/orders" element={<Orders />} />
                        <Route path="/orders/all" element={<AllOrders />} />
                        <Route
                          path="/orders-by-collection"
                          element={<OrdersByCollection />}
                        />
                        <Route
                          path="/orders/all/:id"
                          element={<AllOrdersVendor />}
                        />
                      </Route>
                      <Route
                        path="/chairPayment"
                        element={<CreateOrderPayment />}
                      />
                      {/* <Route
                    path="/phonePePayment"
                    element={<CreateOrderPhonePe />}
                  /> */}
                      <Route
                        path="/payment-success"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/payment-failure"
                        element={<PaymentFailure />}
                      />
                    </Routes>
                  </MachineContextProvider>
                </VendorContextProvider>
              </DashboardContextProvider>
            </ToggleContextProvider>
          </ModalProvider>
        </MachineProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
