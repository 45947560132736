import { CircularProgress } from "@material-ui/core";
import React from "react";

function Loader() {
  return (
    <div className="circularLoading h-screen flex justify-center items-center relative">
      <div className="overlay absolute left-0 top-0 w-full h-screen bg-[#023047]"></div>
      <CircularProgress
        className="flex justify-center items-center h-screen w-full text-white"
        size={42}
      />
    </div>
  );
}

export default Loader;
