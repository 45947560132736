import { createContext, useContext, useState } from "react";
import { DashboardService } from "../services/dashboardService";
import toast from "react-hot-toast";
import {
  firstDayOfMonth,
  formatDate,
  lastDayOfMonth,
  role,
} from "../utils/utils";

const DashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState([]);
  const initialCount = {
    initiate: 0,
    captured: 0,
    failed: 0,
    refund: 0,
  };
  const initialPayCount = {
    todayPayment: "",
    monthlyPayment: "",
  };
  const [payment, setPayment] = useState(initialPayCount);
  const [count, setCount] = useState(initialCount);
  const [todayPayments, setTodayPayments] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);
  const [initiatePayCount, setInitateCount] = useState(0);
  const [capturedPayCount, setCapturedCount] = useState(0);
  const [failedPayCount, setFailedCount] = useState(0);
  const [refundedCount, setRefundedCount] = useState(0);
  const [todayFailedPay, setTodayFailedPay] = useState(0);
  const [dailyRefundedPayments, setDailyRefundedPayments] = useState(0);
  const [monthlyRefundedPayments, setMonthlyRefundedPayments] = useState(0);
  const [monthlyFailedPayments, setMonthlyFailedPayments] = useState(0);
  const [machineCount, setMachineCount] = useState({
    enabledMachine: 0,
    disabledMachine: 0,
    totalMachine: 0,
  });
  const [orders, setOrders] = useState({
    todayOrders: "",
    allOrders: "",
  });
  // dashboard data
  const getDashboardData = async (userId, startDate, endDate) => {
    // Check if startDate and endDate are provided
    if (!startDate || !endDate) {
      toast.error("Start and end dates are required");
      return; // Exit function if dates are not provided
    } else if (!startDate) {
      toast.error("Start date is required");
      return; // Exit function if dates are not provided
    } else if (!startDate) {
      toast.error("End date is required");
      return; // Exit function if dates are not provided
    } else if (endDate < startDate) {
      toast.error("End date must be greater than or equal to start date");
      return; // Exit function if dates are not provided
    } else if (startDate > endDate) {
      toast.error("Start date must be less than or equal to end date");
      return; // Exit function if dates are not provided
    }

    try {
      setLoading(true);
      const userRole = await JSON.parse(localStorage.getItem(role));

      const dashboardData =
        userRole === "vendor"
          ? await DashboardService.getDashboardData({
              userId: userId,
              startDate: formatDate(startDate, "yyyy-mm-dd"),
              endDate: formatDate(endDate, "yyyy-mm-dd"),
            })
          : await DashboardService.getDashboardData({
              userId: null,
              startDate: formatDate(startDate, "yyyy-mm-dd"),
              endDate: formatDate(endDate, "yyyy-mm-dd"),
              todayStartDate: formatDate(new Date(), "yyyy-mm-dd"),
              todayEndDate: formatDate(new Date(), "yyyy-mm-dd"),
            });

      const { data } = dashboardData;

      if (dashboardData.status === 200 && data?.success !== false) {
        setLoading(false);
        setDashboardData(data);

        let initialCount = 0;
        // let successCount = 0;
        let enabledMachinesCount = 0;
        let disabledMachinesCount = 0;
        let todayPaymentTotal = 0;
        let todayRefundedPayments = 0;
        let todayFailedPayments = 0;
        let totalAmount = 0;
        // let failedCount = 0;
        const paymentObj = {};
        const todayPay = {};
        // today's payment total count
        if (data?.todayPayment != null && data?.todayPayment.length > 0) {
          data?.todayPayment?.forEach((payment) => {
            switch (payment.status) {
              case "initiate":
                paymentObj["initiate"] = payment.count;
                break;
              case "captured":
                paymentObj["captured"] = payment.count;
                todayPay["todayPayment"] = payment?.totalAmount / 100;
                break;
              case "failed":
                paymentObj["failed"] = payment.count;
                todayFailedPayments = payment?.totalAmount / 100;
                break;
              case "refund":
                paymentObj["refund"] = payment.count;
                todayRefundedPayments = payment.totalAmount / 100;
                break;
              default:
                break;
            }
          });
          setCount(paymentObj);
          setPayment(todayPay);
          setTodayFailedPay(todayFailedPayments);
          setDailyRefundedPayments(todayRefundedPayments);
        } else {
          setCount({}); // Reset count to empty object if no payments
          setPayment({
            todayPayment: 0,
            monthlyPayment: 0,
          });
          setDailyRefundedPayments(0);
        }

        // monthly payment total count
        if (
          data?.monthlyPaymentAndOrderCount?.result != null &&
          data?.monthlyPaymentAndOrderCount?.result.length > 0
        ) {
          let initiatedCount = 0;
          let captureCount = 0;
          let failCount = 0;
          let failPayments = 0;
          let totalMonthlyPay = 0;
          let refundCount = 0;
          let monthRefunedPayments = 0;
          data?.monthlyPaymentAndOrderCount?.result?.forEach((item) => {
            switch (item.status) {
              case "initiate":
                initiatedCount += item.count;
                break;
              case "captured":
                captureCount += item.count;
                totalMonthlyPay = item?.totalAmount / 100;
                break;
              case "failed":
                failCount += item.count;
                failPayments = item?.totalAmount / 100;
                break;
              case "refund":
                refundCount += item.count;
                monthRefunedPayments = item?.totalAmount / 100;
                break;
              default:
                break;
            }
          });
          setInitateCount(initiatedCount);
          setCapturedCount(captureCount);
          setFailedCount(failCount);
          setMonthlyFailedPayments(failPayments);
          setRefundedCount(refundCount);
          setMonthlyRefundedPayments(monthRefunedPayments);
          // setPayment(monthlyPaymentObj);
        } else {
          setPayment(initialCount);
          setMonthlyFailedPayments(0);
          setMonthlyRefundedPayments(0);
        }

        data?.machine.forEach((machine) => {
          const machineStatus = machine.status;

          if (
            machineStatus === "Enable" ||
            machineStatus === true ||
            machineStatus === "Enabled"
          ) {
            enabledMachinesCount += machine.count;
          } else if (
            machineStatus === "Disabled" ||
            machineStatus === false ||
            machineStatus === "false" ||
            machineStatus === null
          ) {
            disabledMachinesCount += machine.count;
          }
        });

        setMachineCount({
          enabledMachine: enabledMachinesCount,
          disabledMachine: disabledMachinesCount,
          totalMachine: enabledMachinesCount + disabledMachinesCount,
        });
        setTodayPayments(todayPaymentTotal);
      } else {
        setLoading(false);
        setDashboardData([]);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setDashboardData([]);
      setLoading(false);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        getDashboardData,
        machineCount,
        count,
        payment,
        loading,
        todayPayments,
        orders,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        initiatePayCount,
        capturedPayCount,
        failedPayCount,
        refundedCount,
        dailyRefundedPayments,
        monthlyRefundedPayments,
        todayFailedPay,
        monthlyFailedPayments,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  return context;
};