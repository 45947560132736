import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="globalFooter px-4 py-5">
      <div className="footer-inner grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* left area */}
        <div className="left-area flex-col flex items-start gap-4">
          <h2 className="text-3xl font-bold">About Massage99</h2>
          <p className="text-[#333]">
            At Massage99, we are dedicated to revolutionizing your relaxation
            and well-being through cutting-edge massage chair technology. Our
            mission is simple yet powerful: to provide you with the ultimate in
            comfort, rejuvenation, and therapeutic relief, right in the comfort
            of your own home.
          </p>
          <p>
            Company GST:- <span className="font-medium">07AAQCM7911B1ZG</span>
          </p>
          <p>
            Company Name:-{" "}
            <span className="font-medium">
              MASAZO HEALTH TECH PRIVATE LIMITED
            </span>
          </p>
        </div>
        {/* right area */}
        <div className="right-area flex flex-col lg:flex-row gap-4">
          {/* First subsection */}
          <div className="links flex-1">
            <h4 className="text-black mb-4 font-bold">Visit Links</h4>
            <div className="right-area-inner flex flex-col items-start gap-2">
              <Link
                to="https://massage99.in/index.php/privacy-policy/"
                target="_blank"
                className="linksSingle text-base"
              >
                Privacy Policy
              </Link>
              <Link
                to="https://massage99.in/index.php/return_returns/"
                target="_blank"
                className="linksSingle text-base"
              >
                Return Policy
              </Link>
              <Link
                to="https://massage99.in/index.php/terms-conditions/"
                target="_blank"
                className="linksSingle text-base"
              >
                Terms & Conditons
              </Link>
            </div>
          </div>
          {/* Second subsection */}
          <div className="links flex-1">
            <h4 className="text-black mb-4 font-bold">Company</h4>
            <div className="right-area-inner flex flex-col items-start gap-2">
              <Link
                to="https://massage99.in/index.php/contact/"
                target="_blank"
                className="text-base"
              >
                Contact Us
              </Link>
              <Link
                to="https://massage99.in/index.php/about-us/"
                target="_blank"
                className="text-base"
              >
                About Us
              </Link>
              <Link
                to="https://massage99.in/index.php/shipping-and-delivery/"
                target="_blank"
                className="text-base"
              >
                Shipping and Delivery
              </Link>
            </div>
          </div>
          {/* Third subsection */}
          <div className="links flex-1">
            <h4 className="text-black mb-4 font-bold">Contact</h4>
            <div className="right-area-inner flex flex-col items-start gap-2">
              <a href="tel:8787862828" className="text-[#8e5ef9] text-base">
                +91-8787862828
              </a>
              <a
                href="mailto:info@massage99.in"
                className="text-aqua text-base"
              >
                info@massage99.in
              </a>
            </div>
          </div>
          {/* Fourth subsection */}
          <div className="links flex-1">
            <h4 className="text-black mb-4 font-bold">Address</h4>
            <div className="right-area-inner flex flex-col items-start gap-2">
              <div className="text-[#8e5ef9] text-base">
                Golf Course Road, Sector 58, Gurugram
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
