import React, { useEffect } from "react";
import { Icon } from "@iconify/react";

const PaymentSuccess = () => {
  useEffect(() => {
    const handlePopstate = () => {
      // Move the user forward in the history
      window.history.go(1);
    };
    // Add the event listener when the component mounts
    window.addEventListener("popstate", handlePopstate);
  }, []);

  // const payment_success = JSON.parse(localStorage.getItem("payment_success"));
  // const { razorpay_payment_id, razorpay_order_id } = payment_success;

  return (
    <div className="flex justify-center items-center h-screen bg-white">
      <div className="thanku-page-inner w-[95%] lg:w-auto flex justify-center z-20 flex-col items-center gap-4 lg;gap-10  shadow-lg p-[40px] lg:p-[100px] rounded-[10px] bg-[#4BB543]">
        <Icon
          icon="clarity:success-standard-line"
          className="hidden lg:block"
          fontSize={100}
          color="#fff"
        />
        <Icon
          icon="clarity:success-standard-line"
          className="block lg:hidden"
          fontSize={80}
          color="#fff"
        />
        <h4 className="text-2xl text-center font-bold text-white">
          Your payment has been successfully done.
        </h4>
        {/* <div className="flex flex-col">
          <div className="payment_id flex gap-2 text-white items-center">
            <h4>Payment Id:</h4>
            <p className="font-bold">{razorpay_payment_id}</p>
          </div>
          <div className="payment_id flex gap-2 text-white items-center">
            <h4>Order Id:</h4>
            <p className="font-bold">{razorpay_order_id}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentSuccess;
